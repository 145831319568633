var SignCode = {
    TYPE: {
        NORMAL: "1",
        KAKAO: "2",
        GOOGLE: "3",
        SECO: "4",
        APPLE: "11",
    },
    ENCRYPT: {
        SIMPLE: "Y",
        SERVER_TIME_CHECK: "YC"
    },
    ERROR: {
        NO_PROBLEM: '0000',
        PASSWORD_ERROR: '1000',
        ACCOUNT_LOCKED: '1002',
        TWO_FACTOR_FOR_LOCKED: '1003',
        NOT_ALLOWED_IP: '1005',
        ID_ERROR: '3002',
        NOT_EXISTS_ID: '2000',
        NOT_EXISTS_DOMAIN: '5001',
        WAIT_JOIN: '4001',
        REJECT_JOIN: '4006',
        BIZPLAY_PASSWORD_ERROR: '5510'
    }
}

var ParticipantCode = {
    MANAGER: "M",
    INNER: "I",
    OUTER: "O",
    _ID: ["RQST_USER_ID", "ATTENDENCE_ID", "WORKER_ID"],
    _NAME: ["RQST_USER_NM", "ATTENDENCE_NM", "WORKER_NM"],
}

var FilterFoldSet = ['END_DT_VIEW_YN', 'PRIORITY_VIEW_YN', 'START_DT_VIEW_YN', 'STTS_VIEW_YN']

var TaskCode = {
    OPTION: {
        _TEXT: [
            common.taskNumber,
            dictionary.status,
            common.priority,
            common.taskName,
            common.progressAsNoun,
            dictionary.manager,
            dictionary.poster,
            dictionary.from,
            dictionary.to,
            common.registeredDate,
            common.editDate
        ],
        _ITEM: ["Nomal", "Status", "Priority", "Title", "Progress", "Worker", "Nomal", "Date", "Date", "Nomal", "Nomal"],
        _REPLACE: ["TASK_NUM", "", "", "TASK_NM", "", "", "RGSR_NM", "START_DT", "END_DT", "RGSN_DT", "EDTR_DT"],
        _SORT: ["TASK_NUM", "STTS", "PRIORITY", "TASK_NM", "PROGRESS", "WORKER_NM", "RGSR_NM", "START_DT", "END_DT", "RGSN_DT", "EDTR_DT"],
        _COLUMN_REC: [],
        _GANTT_COLUMN_REC: [],
        _GB: ["request", "progress", "completion", "hold", "feedback"],
        _GB_NM: [dictionary.request, dictionary.progress, dictionary.complete, dictionary.hold, dictionary.feedback],
        _SORT_CODE: '',
        _GANTT_SORT_CODE: ''
    },
}

var TaskEditCode = {
    TASK_NM: "TASK_NM",
    STTS: "STTS",
    PRIORITY: "PRIORITY",
    PROGRESS: "PROGRESS",
    WORKER_NM: "WORKER_NM",
    START_DT: "START_DT",
    END_DT: "END_DT",
}

var ScheduleCode = {
    HOLIDAY: 'H',
    GENERAL: 'G',
    TASK: 'T',
    ROUTINE: 'R',
    DUMMY : 'dummy',
    STATUS: {
        _GB: ["participate", "absence", "undetermined"],
        _TEXT: [dictionary.attend, dictionary.notAttend, dictionary.maybeAttend]
    },
}

var DetailCode = {
    WRITE1: "0", //글작성1.0
    WRITE: "1", //글작성2.0
    TODO: "2",
    SCHEDULE: "3",
    TASK: "4",
    VOTE: "6",
    EWS_SCHEDULE: "7",
    EDITOR_WRITE: "91",
    EDITOR_TASK: "92",
    EDITOR_SCHEDULE: "93",
    POST: {
        _NUMBER: [0, 1, 2, 3, 4, 5, 6, 7],
        _GB: {
            "0": "write",
            "1": "write2",
            "2": "todo",
            "3": "schedule",
            "4": "task",
            "5": "vote",
            "6": "vote",
            "7": "ews-schedule",
            "9": "survey-answer",
            "80": "itsm",
            "91": "write2",
            "92": "task",
            "93": "schedule",
        },
        _TEXT: {
            "0": dictionary.article,
            "1": dictionary.article,
            "2": dictionary.todo,
            "3": dictionary.schedule,
            "4": dictionary.task,
            "5": dictionary.vote,
            "6": dictionary.vote,
            "7": dictionary.schedule,
            "9": "응답지",
            "91": dictionary.article,
            "92": dictionary.task,
            "93": dictionary.schedule,
        }
    },
    STATUS: {
        _NUMBER: [0, 1, 2, 3, 4],
        _GB: ["request", "progress", "completion", "hold", "feedback"],
        _TEXT: [dictionary.request, dictionary.progress, dictionary.complete, dictionary.hold, dictionary.feedback]
    },
    PRIORITY: {
        _NUMBER: [0, 1, 2, 3],
        _GB: ["low", "normal", "high", "argent"],
        _TEXT: [dictionary.low, dictionary.normal, dictionary.high, dictionary.argent],
        _ALL_TEXT: [dictionary.low, dictionary.normal, dictionary.high, dictionary.argent, dictionary.none],
        _CLASS: ["lv1", "lv2", "lv3", "lv4"]
    },
    EMOJI: {
        _NUMBER: [0, 1, 2, 3, 4],
        _GB: ["like", "please", "sad", "great", "thank"],
        _TEXT: [dictionary.like, dictionary.please, dictionary.oops, dictionary.awesome, dictionary.thanks]
    },
    SHARE_POST: {
        CODE: "SHARE_POST",
        TEXT: common.selectProject
    },
    WRITE_ANYWHERE: {
        CODE: "WRITE_ANYWHERE",
        TEXT: "게시물 작성 중 프로젝트 선택",
    },
    DASHBOARD: {
        CODE: "DASHBOARD",
        TEXT: common.selectProject
    },
    FORWARD: {
        CODE: "FORWARD",
        TEXT: "전달하기"
    },
    ROUTINE: {
        CODE: "ROUTINE",
        TEXT: "게시글 루틴"
    },
};

var taskStatusCode = {
    REQUEST: 0,
    PROGRESS: 1,
    COMPLETION: 2,
    HOLD: 3,
    FEEDBACK: 4,
}

var SocketAPI = {
    COMM: {
        LOGOUT: "LOGOUT0001",
        TEMP_LOGOUT: "LOGOUT0002",
        OVERLAP_LOGOUT: "OVERLAPLOGOUT0001",
        MOUSE_MOVE: "MOUSEMOVE0001",
        KEY_DOWN: "KEYDOWN0001",
        POPUP_CLOSE: "POPUP0001",
        LOGIN_SECURITY_CHECK: "LOGIN_SECURITY_CHECK",
        UPDATE_SESSION: "USER0023",
    },
    MAIN: {
        FOCUS_IN: "FOCUS_IN",
        FOCUS_OUT: "FOCUS_OUT",
        BADGE_UPDATE: "USER0000",
        CHAT_UPDATE: "USER0001",
        ROOM_NAME_UPDATE: "USER0002",
        ALARM_N_PIN_UPDATE: "USER0003",
        ROOM_INVITE: "ROOM_INVITE",
        ROOM_LEAVE: "USER0004",
        READ_ALL_MESSAGE_BY_ROOM: "USER0005",
        ROOM_FOCUS: "USER0006",
        DELETE_MESSAGE_ALL_VIEW: "USER0007",
        DELETE_MESSAGE_MY_VIEW: "USER0008",
        LEAVE_MULTI_USER: "USER0009",
        CHANGE_STATE: "USE_INTT_ID_0001",
        FLOW_DRIVE_FILE_IMPORT: "USER0019",
        BADGE_SELF_UPDATE: "BADGE_SELF",
        NOTDISTURB: "USER0012",
        PAYMENT_COMPLETE : "PAYMENT_COMPLETE",
        EDIT_COLLABO_NOTI: "USER0017",
        UPDATE_ONLY_MANAGER_YN: "USER0021",
        ROOM_LEAVE_OR_EXPORT: "USER0022",
        CALENDAR_AUTHORIZE : "USER0023",
        SERVICE_FAVORITE_CREATE: "USER0027",
        SERVICE_FAVORITE_DELETE: "USER0028",
        REMINDER_CLEAR: "USER0024",
        REMINDER_UPDATE: "USER0025",
        REMINDER_CHATBOT_RESPONSE: "USER0026",
        SCHEDULE_REMINDER: "SCHD0001",
        SERVICE_OPEN_IN_IFRAME: "OPEN_IFRAME",
        SERVICE_UPGRADE: "USER0029",
        DELETE_PROJECT_LEAVE : "PROJECT0001",
        UNREAD_MAIL_CNT:"EMAILR0001"
    },
    MESSENGER: {
        CONNECT: "INNER_CONNECT",
        MESSAGE_UPDATE: "CHAT0001",
        READ_UPDATE: "CHAT0002",
        JOIN_N_LEAVE: "CHAT0003",
        ROOM_NAME_UPDATE: "CHAT0004",
        ROOM_NAME_UPDATE_SELF: "CHAT0013",
        ALARM_N_PIN_UPDATE: "CHAT0005",
        DELETE_MESSAGE_ALL_VIEW: "CHAT0007",
        DELETE_MESSAGE_MY_VIEW: "CHAT0008",
        PREVIEW: "CHAT0009",
        NOTICE: "CHAT0010",
        NOTICE_STATUS_UPDATE: "CHAT0011",
        UPDATE_MANAGER: "CHAT0021",
        PREV_CHAT_UPDATE: "CHAT0015",
        FORWARD_CONTENTS: "CHAT0016",
        EMOJI_UPDATE: "CHAT0022",
        CHILDREN_CLOSE: "CHILDREN_CLOSE",
    },
    CONNECT: {
        MESSENGER: {
            FILE: "MESSENGER_FILE",
            CREATE: "MESSENGER_CREATE", //대화하기, 대화상대초대
        },
        INVITE: {
            FAVORITE: "INVITE_FAVORITE"
        },
        SYNC: {
            GOOGLE: "CALENDAR",
            DRIVE : "DRIVE",
            GOOGLEMEET : "GOOGLEMEET",
            GOOGLEMAIL : "GOOGLEMAIL",
            GOOGLEDRIVE : 'GOOGLEDRIVE',
            GOOGLE_PEOPLE_DIRECTORY : 'GOOGLE_PEOPLE_DIRECTORY',
            GOOGLE_SHEETS : "GOOGLE_SHEETS",
            GOOGLE_DASHBOARD_ALL : "GOOGLE_DASHBOARD_ALL",
        },
        REFUSED : 'CONNECT_REFUSED'
    },
    LETTER : {
        UPDATE: "USER0010",
        READ: "USER0011",
        RETRIEVE: "LETTER0002",
        CLOSE_WINDOW: "LETTER0003",
        RETRIEVED_LETTER_DELETE: "LETTER0004",
        DELETE: "LETTER0005",
        DELETE_ALL: "LETTER0006",
        BOOKMARK: "LETTER0007",
        READ_CONFIRM: "LETTER0008",
        TEMPORARY_DELETE: "LETTER0009",
    },
}


var RoomType = {
    PROJECT: '3',
    MULTI: '2',
    ONE_TO_ONE: '1',
    SELF: '0',
}

var InviteType = {
    SENDIENCE: 'sendience',
    ALLSENDIENCE: 'all-sendience',
    TEAM: 'team',
    NEWCHAT: 'NEW_CHAT',
    INVITECHAT: 'INVITE_CHAT',
    FAVORITE: 'favorite',
    CONTACT: 'contact',
    EWS: 'ews',
    USERGROUP : 'userGroup'
}
var RemarkOrderType = {
    SEARCH : "C"
}
var MiniFavoriteData = {
    DefaultGroup: '3'
}

var OftenCode = {
    PREV: "P",
    SEARCH : "S",
    NEXT: "N",
    EQUAL: "E", // 아래의 EDIT과 동일
    MORE: "M",
    CREATE: "C",
    EDIT: "E",
    DELETE: "D",
    REFRESH: "R",
}

var BuyCode = {
    PAID_COMPANY: "Y",
    FREELANCER: "P",
    FREE: "N",
}

var LogoutCode = {
    STOP: "S", //사용자 이용중지
    LOCK: "", //잠금모드로 로그아웃
}

var StatusCode = {
    NO_LIMIT: "S",
    BFLOW: {
        END_ADMIN: "E2", //비플로우 1개월 무료체험 끝난 관리자 고객
        END_USER: "E3", //비플로우 1개월 무료체험 끝난 팀원 고객
        REMAIN_WEEK: "P1", //비플로우 1개월 무료체험 끝나기 7일 남은 고객
        NO_LIMIT: "S", //비플로우 유료 | 비플로우 1개월 무료체험 끝나기 8~30일 남아 제한없는 고객
    },
    ENTER: "S", //엔터 고객
    UN_BFLOW: { //UTLZ | 프리랜서
        MULTI: { //무료고객중 1명 초과한 고객.
            REMAIN_USER: "P", // 1개월 무료체험 0~30일 남아있는 고객
            END_USER: "R", // 1개월 무료체험 끝난 남아있는 고객
        },
        GUEST: "F", //무료
        NO_LIMIT: "S", // 언비플로우 유료
    },
    BEFORE_TRIAL : "SP_E0",
    STRIPE: {
        FREE_TRIAL_END : {
            ADMIN : "SP_E7",
            USER: "SP_E8",
        },
        SEAT: {
            EXCEED_GRACEDAY_ADMIN: "SP_E1",
            EXCEED_GRACEDAY: "SP_E2",
            EXCEED: "SP_E3",
        },
        GRACE_DAY_END: {
            ADMIN: "SP_E4",
            USER: "SP_E5"
        },
        GRACE_DAY: {
            ADMIN :"SP_E6"
        },
        AUTO_DOWNGRADE_TO_BASIC:{
          ADMIN : "SP_E9"
        }
    }
}

var BLOCK_TYPE = {
    SERVICE_ADMIN: {
        title: main.alert.serviceUserTitle,
        main: main.alert.serviceAdminMain,
        submit: main.alert.experienceSubmit,
    },
    SERVICE_USER: {
        title: main.alert.serviceUserTitle,
        main: common.alertAskAdmin,
        submit: dictionary.logout,
    },
    USE: {
        title: main.alert.serviceUserTitle,
        main: common.alertAskAdmin
    },
    EXPERIENCE: {
        title: main.alert.experienceTitle,
        main: main.alert.experienceMain,
        submit: main.alert.experienceSubmit,
    },
    PROJECT: {
        title: Often.isGlobal() ? main.plan.projectMakeTitle : main.alert.projcetMakeTitle,
        main: main.alert.projectMakeMain,
        submit: common.upgradeService,
    },
    INVITE: {
        title: main.InviteProject.inviteCompanyMember,
        main: main.alert.inviteMain,
        submit: common.upgradeService,
    },
    SEARCH: {
        title: common.chatSearch,
        main: main.alert.chatSearchMain,
        submit: common.upgradeService,
    },
    SECRET: {
        title: common.chatSecret,
        main: main.alert.chatSecretMain,
        submit: common.upgradeService,
    },
    VIDEO: {
        title: main.alert.videoConferenceTitle,
        main: main.alert.videoConferenceMain,
        submit: common.upgradeService,
    },
    CALENDAR: {
        title: main.alert.videoConferenceTitle,
        main: main.alert.chatSecretMain,
        submit: common.upgradeService
    },
    GOOGLECALENDAR: {
        title: main.alert.googleCalendarTitle,
        main: main.alert.googleCalendarMain,
        submit: common.upgradeService
    },
    AUTHENTICATION: {
        title: common.videoConference,
        main: common.noAuth,
        submit: dictionary.confirm,
    },
    COLLECT: {
        title: dictionary.content,
        main: main.alert.listViewMain,
        submit: common.upgradeService,
    },
    SUBTASK: {
        title: common.writeSubTasks,
        main: main.alert.subtaskMain,
        submit: common.upgradeService,
    },
    FLOWDRIVE: {
        title: main.alert.flowDriveTitle,
        main: main.alert.flowFileMain,
        submit: common.upgradeService,
    },
    DATA500: {
        title: main.alert.storageFullTitle,
        main: main.alert.storageFullMain,
        submit: common.upgradeService,
    },
    POST: {
        title: main.postAccessLimitTitle,
        main: main.postAccessLimitMain,
        submit: common.upgradeService,
    },
    TIMELINE: {
        title: dictionary.ganttChart,
        main: main.alert.timeLineMain,
        submit: common.upgradeService,
    },
    ZOOM_CONNECT: {
        title: main.alert.videoConferenceTitle,
        main: main.alert.syncWithZoom,
        submit: dictionary.connect,
        link_text: main.alert.howToZoom,
    },
    VIDEO_CONNECT: {
        title: main.alert.videoConferenceTitle,
        main: main.alert.syncWithVideoConference,
        submit: dictionary.connect,
        link_text: main.alert.howToVideoConference,
    },
    UPDATA10: {
        title: main.alert.uploadDataLimit,
        main: main.alert.data10Main,
        submit: common.upgradeService,
    },
    UPDATA50: {
        title: main.alert.uploadDataLimit,
        main: main.alert.data10Main,
        submit: common.upgradeService,
    },
    UPDATA500: {
        title: main.alert.uploadDataLimit500,
        main: main.alert.data10Main,
        submit: common.upgradeService,
    },
    UPDATA1000: {
        title: main.alert.uploadDataLimit1000,
        main: main.alert.data10Main,
        submit: common.upgradeService,
    },
    UPDATA30000: {
        title: main.alert.uploadDataLimit30000,
        main: main.alert.data10Main,
        submit: common.upgradeService,
    },
    SECURE: {
        title: admin.companySecurity.title,
        main: admin.companySecurity.desc,
        submit: common.upgradeService,
    },
    "SECURE-SECU": {
        title: admin.companySecurity.title,
        main: admin.companySecurity.desc,
        submit: common.upgradeService,
    },
    STOP_FLOWNEW: {
        title: 'PC 앱 정식 버전 출시',
        main: '테스트 버전 지원 종료로 \n' +
            '정식 버전 이용 부탁드립니다.',
        submit: common.downloadApp,
    },
    OKR: {
        title: 'OKR',
        main: '비즈니스 프로 계정으로 업그레이드 하면\nOKR을 사용할 수 있어요!',
        submit: common.upgradeService,
    },
    DASHBOARD: {
        title: '홈 대시보드',
        main: '비즈니스 프로 계정으로 업그레이드 하면\n홈 대시보드를 사용할 수 있어요!',
        submit: common.upgradeService,
    },
    DESKTOP_UPDATE: {
        title: electron.update,
        main: electron.versionReleased +' \n' +
            electron.updateRequire,
        submit: common.downloadApp,
        link_text: electron.trouble,
    },
    DESKTOP_SELECT_UPDATE: {
        title: electron.update,
        submit: common.downloadApp,
        link_text: electron.trouble,
    },
    DESKTOP_FORCE_UPDATE: {
        title: electron.update,
        submit: common.downloadApp,
        link_text: electron.trouble,
    },
    KYWON_DESKTOP_UPDATE: {
        title: '',
        main: electron.versionReleased+'\n' +
            electron.updateRequire,
        submit: common.downloadApp,
        link_text: '',
    },
    ORGANIZATION: {
        title: dictionary.organization,
        main: Often.isAct("admin")? admin.alert.orgChartMain : main.alert.orgChartMain,
        submit: common.upgradeService,
    },
    PROJECTTEMPLATE: {
        title: dictionary.projectTemplate,
        main: main.alert.projectTemplateUpgrade,
        submit: common.upgradeService,
    },
    PUBLICPROJECT : {
        title: common.publicProject,
        main: common.alert.publicProject,
        submit: common.upgradeService,
    },
    STATISTICS : {
        title: admin.statisticsReport,
        main: admin.alert.statistics,
        submit: common.upgradeService,
    },
    BEFORE_TRIAL : {
        title : main.onboard.assignManager,
        main : main.onboard.managerWillHelp,
        submit : dictionary.confirm,
    },
    STRIPE: {
        FREE_TRIAL_END: {
            ADMIN: {
                title : main.payment.endTrial,
                main : main.payment.afterTrial,
                submit: common.selectPlan,
                bottom_text: main.payment.needTrialHelp,
            },
            USER: {
                title : common.requestAdmin,
                holder : common.writeMessage,
                main : main.payment.requestAdmin,
                submit: common.requestAdmin,
            },
            AUTO_DOWNGRADE_TO_BASIC: {
                title : common.requestAdmin,
                holder : common.writeMessage,
                submit: common.requestAdmin,
            }
        },
        AUTO_DOWNGRADE_TO_BASIC: {
            title : main.autoBasic.title,
            main : main.autoBasic.content,
            submit: main.autoBasic.button,
            bottom_text: main.autoBasic.upgrade,
        },
        CONSULT: {
            title: common.leaveMessage,
            holder: common.writeMessage,
            main: main.alert.afterConsult,
        },
        SEAT: {
            EXCEED_GRACEDAY_ADMIN: {
                title : main.alert.serviceUserTitle,
                main : main.stripe.updateSeats,
                submit: main.stripe.upgradePlan,
            },
            EXCEED_GRACEDAY: {
                title : main.alert.serviceUserTitle,
                main : main.stripe.updateSeatsEmployee,
                submit: main.alert.askAdminDictionaryForm,
            },
            EXCEED: {
                title : main.stripe.updatePlanTtl,
                main : main.stripe.updatePlanGrace,
                submit: main.stripe.upgradePlan,
            }
        },
        GRACE_DAY_END: {
            ADMIN: {
                title : main.stripe.updatePlanTtl,
                main : main.stripe.paymentFail,
                submit: main.payment.methodUpdate,
                bottom_text: main.payment.needTrialHelp,
            },
            USER: {
                title : main.alert.serviceUserTitle,
                main : main.stripe.paymentFailEmployee,
                submit: common.requestAdmin,
            },
            AUTO_DOWNGRADE_TO_BASIC: {
                title : main.autoBasic.title,
                main : main.stripe.paymentFailEmployee,
                submit: common.requestAdmin,
            }
        },
        GRACE_DAY : {
            ADMIN: {
                title : main.payment.update,
                main : main.stripe.paymentFail,
                submit: main.payment.methodUpdate,
            },
        },
    },
    POST_REPLICATION: {
        title: common.registerRoutine,
        main: main.alert.registerRoutine,
        submit: common.upgradeService,
    },
    AI_MONTHLY_USAGE_EXCEEDED: {
        // title: main.aiLimit.title,
        // main: main.aiLimit.descForPro,
        // submit: main.aiLimit.formBtn,
        title: main.aiLimit.title,
        main: main.aiLimit.descForPro,
        submit: main.aiLimit.formBtn,
    },
    AI_MONTHLY_USAGE_EXCEEDED_MM: {
        title: main.aiLimit.titlemm,
        main: main.aiLimit.descForPromm,
        submit: main.aiLimit.formBtnmm,
    },
    ECOMMERCE: {
        title: main.connect.shareProduct,
        main: main.connect.productDesc1,
        submit: common.upgradeService,
    },
}

//Note. 필터카테고리
var searchMode = {
    TOTAL: "total", //전체 카테고리
    PROJECT: "project", //프로젝트 카테고리
    POST: "post", //글 카테고리
    FILE: "file", //파일 카테고리
    TASK: "task", //업무 카테고리
    SCHD: "schedule", //일정 카테고리
    CHAT: "chat", //채팅 카테고리
    POST_TRASH_CAN: "postTrashCan", //게시물 휴지통
}

//Note. 필터영역
var searchAreaCode = {
    IN_TONG: "IN_TONG", //통합
    IN_PROJECT: "IN_PROJECT", //프로젝트내
    IN_TASK: "IN_TASK", //업무모아보기
    IN_FILE: "IN_FILE", //파일모아보기
    IN_POSTS: "IN_POSTS", //북마크,멘션,내가쓴글
}

var MessageType = {
    INFO: 'A',
    INVITE: 'A_INVITE',
    NOTIFICATION: 'U',
    NEW: 'D',
    DELETE: 'X',
    CHANGED: 'C',
    IMAGE: 'I',
    FILE: 'F',
    GOOGLE_FILE: "W",
    EMOTI: 'E',
    REPLY: 'R',
    IMAGE_GROUP: 'G',
    GENERAL: '',
    GROUPCALL_CALL :'J',
    GROUPCALL_CANCEL :'K',
    PREV_CHAT: 'P',
    VOICE_MESSAGE: 'S',
    REMINDER_BOT_REPLY: 'B',
}

var SendienceGb = {
    INNER: "1",
    OUTER: "2",
    MANAGER: "3",
}

var loadMode = {
    UP_SCROLL: 'P',
    DOWN_SCROLL: 'N',
    FIRST_LOAD: 'I',
    EQUAL_LOAD: 'E',
    FIRST_OVER_LOAD: 'FIRST_OVER_LOAD',
    SEARCH_LOAD: 'SP',
    REPLY_LOAD: 'R',
}

var JoinStatus = {
    APPLY_JOIN: 1,
    REJECT_JOIN: 2,
    REQUEST_JOIN: 3,
    DELETE_JOIN_LOG: 4,
    APPLY_JOIN_2: 5,
    REJECT_JOIN_2: 6,
    CANCEL_REQUEST_JOIN: 7,
}

//채팅공지
var noticeCode = {
    DEFAULT: 'D', //Default (2줄 나와있는 상태)
    FOLD: 'F', //다 펼처져 있는상태
    MINI: 'M', //접혀져서 아이콘만 있는상태
    NONE: 'N',
}

var ELECTRON_OPEN = {
    CHAT: 'C',
    PROJECT: 'P',
    FILE: 'F',
    INVITE: 'I',
    MEMO: 'MEMO',
    LETTER : 'LETTER',
}

var UpdateElementsTarget = {
    POPUP: {
        ID: "#postPopup #post-",
        TITLE_CLASS: "js-post-title post-title ",
        STATUS_CLASS: "js-task-state state-button-group clearfix ",
    },
    CARD: {
        ID: "#detailUl #post-",
        TITLE_CLASS: "js-post-title post-title ",
        STATUS_CLASS: "js-task-state state-button-group clearfix ",
    },
    LIST: {
        ID: "#detailUl #post-",
        TITLE_CLASS: "js-post-title fixed-text ",
        STATUS_CLASS: "js-task-state state ",
    },
    PIN: {
        ID: "#pinPostUl #pin-",
        TITLE_CLASS: "js-post-title fixed-text ",
        STATUS_CLASS: "js-task-state js-todo-state state  ",
    },
    TASK: {
        ID: ".js-all-task-ul:visible #allTask-",
        TITLE_CLASS: "",
        STATUS_CLASS: "js-task-state task-state ",
    },
    ALLPOSTS: {
        ID: "#myPostContentUl:visible #allPosts-",
        TITLE_CLASS: "",
        STATUS_CLASS: "js-task-state state ",
    },
    SUBTASK: {
        ID: "#subtask-",
        STATUS_CLASS: "js-subtask-status-button js-task-state subtask-button subtask-status ",
    },

    FEED_SUBTASK: {
        ID: "#detailLayer #subtask-",
        STATUS_CLASS: "js-subtask-status-button js-task-state subtask-button subtask-status ",
    }
}

var ProfileState = {
    ONLINE: 'online',
    OUT: 'out',
    BUSINESS: 'business',
    OFFLINE: 'offline',
    BLOCKTIME: 'blocktime',
    TELECOMMUTING: 'telecommuting',
    CALL : 'call',
}

var MorningMateServiceHelper = {
    ZOOM: 'help:help.zoom',
    MS_TEAMS: 'help:help.msTeams',
    BIZPLAY_GROUP: "https://support.flow.team/ko/flow/4409803901209",
    SENDIENCE: 'help:help.projectSendience',
    PUSH_SETTING: "https://support.flow.team/ko/flow/216566367",
    ALARM_SETTING: "https://support.flow.team/ko/flow/360021715712",
    NOTIFICATION: "help:help.notification",
    CREATE_PROJECT: "https://support.flow.team/ko/flow/4403611187085",
    CREATE_PROJECT_OPTION: "https://support.flow.team/ko/flow/4404585678861",
    GOOGLE_CALENDAR: 'help:help.googleCalendar',
    GOOGLE_CONTACTS: "https://support.morningmate.com/en/articles/10225214-coming-soon-a-guide-to-inviting-members-through-google-contacts",
    GOOGLE_MEET: "https://support.morningmate.com/en/articles/10374622-coming-soon-a-guide-to-google-meet-integration-and-management",
    GOOGLE_DRIVE: 'help:help.gdrive',
    COLLECT: 'help:help.chatDetail',
    COLLECT_MINI: 'help:help.overall',
    SEARCH: "https://support.flow.team/ko/flow/4403668441997",
    SECRET: 'help:help.secretMessage',
    FLOWDRIVE: 'help:help.flowDrive',
    TIMELINE: 'help:help.gantt',
    SUBTASK: 'help:help.subtask',
    ORGANIZATION: 'help:help.organization',
    PROJECT_TEMPLATE: 'help:help.projectTemplate',
    GUEST: "https://morningmate.zendesk.com/hc/en-us/articles/13579889501721",
    PLAN_PRICE: "https://morningmate.com/uk/price",
    OKR: 'https://drive.google.com/file/d/13_N8rPV2ApclOcXrEGKzaCxg4U1rqwqS/view',
    STATISTIC: 'help:help.statistics',
    PUBLIC_PROJECT : 'help:help.publicProject',
    ZAPIER: 'help:help.zapier',
    GMAIL: 'help:help.gmail'
}

var FlowServiceHelper = {
    ZOOM: "https://support.flow.team/ko/flow/4404603293197",
    MS_TEAMS: "https://support.flow.team/ko/flow/9778870094873",
    BIZPLAY_GROUP: "https://support.flow.team/ko/flow/4409803901209",
    SENDIENCE: "https://support.flow.team/ko/flow/4403686292877",
    PUSH_SETTING: "https://support.flow.team/ko/flow/216566367",
    ALARM_SETTING: "https://support.flow.team/ko/flow/360021715712",
    NOTIFICATION: "https://support.flow.team/ko/flow/4404185955981",
    CREATE_PROJECT: "https://support.flow.team/ko/flow/4403611187085",
    CREATE_PROJECT_OPTION: "https://support.flow.team/ko/flow/4404585678861",
    GOOGLE_CALENDAR: "https://support.flow.team/ko/flow/6646255198105",
    GOOGLE_DRIVE: "https://support.flow.team/ko/flow/4403687471885",
    GOOGLE_MEET: "",
    GOOGLE_CONTACTS: "",
    COLLECT: "https://support.flow.team/ko/flow/4403656215565",
    COLLECT_MINI: "https://support.flow.team/ko/flow/4406865263885",
    SEARCH: "https://support.flow.team/ko/flow/4403668441997",
    SECRET: "https://support.flow.team/ko/flow/4403686296205",
    FLOWDRIVE: "https://support.flow.team/ko/flow/4406865269901",
    TIMELINE: "https://support.flow.team/ko/flow/11626457319961",
    SUBTASK: "https://support.flow.team/ko/flow/4403997608845",
    ORGANIZATION: "https://support.flow.team/ko/flow/4727122046489",
    PROJECT_TEMPLATE: "https://support.flow.team/ko/flow/4823770697241",
    GUEST: "https://support.flow.team/ko/flow/234707747",
    PLAN_PRICE: "https://flow.team/kr/price",
    OKR: 'https://drive.google.com/file/d/13_N8rPV2ApclOcXrEGKzaCxg4U1rqwqS/view',
    DASHBOARD: '',
    ZAPIER: 'https://support.flow.team/ko/flow/24689468978329',
    GMAIL: 'https://support.flow.team/ko/flow/28517418285721',
    ROUTINE: 'https://support.flow.team/ko/flow/28798251658009',
    INSIGHT: 'https://support.flow.team/ko/flow/31096868112281',
    SELLMATE : 'https://support.flow.team/ko/flow/ecommerce/1',
    CAFE24 : 'https://support.flow.team/ko/flow/ecommerce/2'
}

var INVITEGB = {
    PERSON: "U",
    DVSN: "ED",
}

var TINY_URL_MOVE = {
    POST: -9
}

var postMode = {
    ADD: "ADD",
    EDIT: "EDIT",
    SHARE_POST: "SHARE_POST",
    WRITE_ANYWHERE: "WRITE_ANYWHERE",
    VIEW: "VIEW",
}

// 0 - 일반, 1 - 상태, 2 - 마감일, 3 - 프로젝트, 4 - 하위업무
var TaskViewMode = {
    NONE: "0",
    STATUS: "1",
    DUEDATE: "2",
    PROJECT: "3",
}

const BundleGubun = {
    TOP_UP_OPENED: "TOP_UP",
    TOP_UP_FOLD: "TOP_UP_FOLD",
    TOP_UP_CLOSED: "TOP_UP_CLOSED",
    TOP_SUB_ONLY: "TOP_SUB",
    DOWN_SUB: "DOWN_SUB",
    SOLO: "NO_SUBTASK",
}

const ThirdPartyErrCode = {
    SUCCESS: "0000",
    DUPLICATE_COMPANY_ERROR: "1418",
    ETC_ERROR: "4444"
}

const CountryCode = {
    US : "+1", //미국
    GB : "+44", //영국
    JP : "+81", //일본
    KR : "+82", //한국
    VN : "+84", //베트남
    CN : "+86", //중국
    HK : "+852", //홍콩
    KH : "+855" //캄보디아
}

const HOME_TAB_TYPE = {
    FEED : 'FEED'
    , TASK : 'TASK'
    , GANTT : 'GANTT'
    , FILE : 'FILE'
    , CALENDAR:  'CALENDAR',
}

const POST_LINK_PREFIX = "/l/";

const ALARM_FILTER = {
    MENTION: "MENTION",
    WORKER: "WORKER",
    REGISTRANT: "REGISTRANT"
}

const ALARM_MODE = {
    ALL: "ALL",
    UNREAD: "UNREAD"
}
const PROJECT_STATUS = {
    PAUSE : "S",
    DELETE : "D"
}

const POPUP_MODE = {
    EDIT : "EDIT" ,
    ADD : "ADD" ,
    VIEW : "VIEW",
}

const TRANSLATE_SUPPORT_LANGUAGE = {
    af: "Afrikaans",
    id: "Bahasa Indonesia",
    ms: "Bahasa Malaysia",
    bs: "Bosanski",
    ca: "Català",
    cs: "Čeština",
    cy: "Cymraeg",
    da: "Dansk",
    de: "Deutsch",
    et: "Eesti keel",
    en: "English",
    es: "Español",
    fr: "Français",
    hr: "Hrvatski",
    it: "Italiano",
    sw: "Kiswahili",
    lv: "Latviešu valoda",
    lt: "Lietuvių kalba",
    hu: "Magyar",
    mt: "Malti",
    nl: "Nederlands",
    no: "Norsk",
    pl: "Polski",
    pt: "Português",
    ro: "Română",
    sk: "Slovenčina",
    sl: "Slovenščina",
    sr: "Srpski",
    "sr-Cyrl": "Cрпски",
    fi: "Suomi",
    sv: "Svenska",
    tl: "Tagalog",
    vi: "Tiếng việt",
    tr: "Türkçe",
    "zh-CN": "简体中文",
    "zh-TW": "繁體中文",
    ja: "日本語",
    ko: "한국어",
    ar: "العربية",
    fa: "فارسى",
    he: "עברית",
    ur: "اردو",
    hi: "हिन्दी",
    th: "ภาษาไทย",
    el: "ελληνικά",
    bg: "български",
    ru: "Русский",
    uk: "Українська"
};

const PORTAL_APP_TYPE = {
    APP : "APP",
    EXTERNAL_SERVICE : "SERVICE"
}
