var SocketConnArr = [];

var SocketControl = (function () {

    const NetworkChecker = requireModule('NetworkChecker', {on: ['main', 'messenger']});
    const DashboardPro = requireModule('DashboardPro', {on: ['main']});
    const MessengerNetworkManager = requireModule('MessengerNetworkManager', {on: ['messenger']});
    const MainPortal = requireModule('MainPortal', {on: ['main']});
    const isChatS2sOn = Often.isFunc('CHAT_S2S')
    const GoogleWatch = requireModule('GoogleWatch', {on: ['main']});

    var chatSocket = {
        disconnected: true,
        emit: () => {
        }
    };
    var statusSocket;
    var iptSocket;
    var strSocketUserIds;
    var isCompleteMatch = false;
    var roomConnectId;
    const timeJson = {connect: {start: new Date().getTime()}, matching: {}};

    let callbackQueueWaitingForConnection = [];

    return {
        refreshListenerEvent: refreshListenerEvent,
        setCallbackQueue: setCallbackQueue,
        getSocket: getSocket,
        getSocketIdList: getSocketIdList,
        getRoomConnectId: getRoomConnectId,
        getStrSocketUserIds: getStrSocketUserIds,
        setStrSocketUserIds: setStrSocketUserIds,
        isDisconnectAndConnect: isDisconnectAndConnect,
        connectSocket: connectSocket,
        connectOnlySocket: connectOnlySocket,
        sendMessage: sendMessage,
        sendMessageByUserIds: sendMessageByUserIds,
        sendAlarmAndPinUpdate: sendAlarmAndPinUpdate,

        //Letter socket
        sendLetterByUserIds: sendLetterByUserIds,
        sendProjectLeaveSocket: sendProjectLeaveSocket,
        readMyLetter: readMyLetter,
        retrievedLetter: retrievedLetter,
        closeMessageReplyWindow: closeMessageReplyWindow,
        deleteLetter: deleteLetter,
        bookmarkLetter: bookmarkLetter,

        logout: logout,
        sendLoginSecurityCheck: sendLoginSecurityCheck,
        closeExpiredWindows: closeExpiredWindows,
        sendFocusStatus: sendFocusStatus,
        // IPT socket
        initIPTSocket: initIPTSocket,
        getIptSocket: getIptSocket,
        clickToCall: clickToCall,
        // status socket
        initStatusSocket: initStatusSocket,
        getStatusSocket: getStatusSocket,
        sendStatusUpdate: sendStatusUpdate,
    }

    function setCallbackQueue(data) {
        callbackQueueWaitingForConnection.push(data);
    }

    function getRoomConnectId() {
        return roomConnectId;
    }

    function getSocket() {
        return chatSocket;
    }

    function getIptSocket() {
        return iptSocket;
    }

    function getStatusSocket() {
        return statusSocket;
    }

    function getStrSocketUserIds() {
        return strSocketUserIds;
    }

    function setStrSocketUserIds(str) {
        strSocketUserIds = str;
    }

    function isDisconnectAndConnect() {
        return chatSocket.disconnected;
    }

    function connectOnlySocket() {
        const _ServerChecker = window.ServerChecker || {
            isReal: Often.isServerModeByHost("REAL"),
            isEnter: false
        };

        const isMorningmateUrl = window.location.href.includes('morningmate.info') ? "https://chat.morningmate.info:7820" : "https://chat.morningmate.com:7820";

        let socketUrl;
        if ((Mutil.isServerOn("REAL") || Mutil.isServerOn("REAL_TEST"))
            && Mutil.isFunc("SECURE_CONNECT")) {
            socketUrl = "https://secure.flow.team:7820";
        } else if (typeof _CHAT_URL === "undefined" || Often.null2Void(_CHAT_URL) === "") {
            if (Often.isGlobal()) {
                socketUrl = isMorningmateUrl
            } else if (_ServerChecker.isReal && !_ServerChecker.isEnter) {
                socketUrl = "https://flowchat.appplay.co.kr:7820";
            } else {
                socketUrl = "https://flowtest.info:8082";
            }
        } else {
            if (Often.isGlobal()) {
                socketUrl = isMorningmateUrl
            } else {
                socketUrl = _CHAT_URL;
            }
        }

        chatSocket = io.connect(socketUrl, {
            rememberUpgrade: true,
            query: {
                "token": window._JSESSION_ID
            }
        });
        return chatSocket;
    }

    function connectSocket(callback) {
        roomConnectId = Often.getUUID();
        if (Often.isFunc("NETWORK_CHECKER")) {
            setTimeout(() => {
                connectOnlySocket();
                addDefaultEvent(callback);
                addReceiveEvent();
            }, NetworkChecker()?.TIMEOUT_CONFIG.CONNECT * 1000);
        } else {
            MessengerNetworkManager()?.allowSendMessage()
            $("#messengerUl").removeClass('wait-matching');
            connectOnlySocket();
            addDefaultEvent(callback);
            addReceiveEvent();
        }
    }

    function refreshListenerEvent() {
        const connectListenerCount = chatSocket.listeners('connect').length;
        if (connectListenerCount === 0) {
            setTimeout(() => {
                NetworkChecker()?.drawSendSocketLog(`refreshListenerEvent - connect (${connectListenerCount})`)
            }, 0);
            setTimeout(() => addDefaultEvent(), 300);
        }

        const receiveListenerCount = chatSocket.listeners('receiveMessage').length;
        if (receiveListenerCount === 0) {
            setTimeout(() => {
                NetworkChecker()?.drawSendSocketLog(`refreshListenerEvent - receiveMessage (${receiveListenerCount})`)
            }, 600);
            setTimeout(() => addReceiveEvent(), 900);
        }
    }

    function initSocket() {

        if (Often.isAct("messenger")) {
            if (Often.isFunc(Func.CLOUD.NEW_REQ_CHAT)) {
                chatSocket.emit("requestRandomChat", {
                    SOCKET_TYPE: "CHAT",
                    ROOM_SRNO: _ROOM_SRNO,
                    USE_INTT_ID: _USE_INTT_ID,
                    USER_ID: _USER_ID
                });
            } else {
                chatSocket.emit("requestRandomChat", {ROOM_SRNO: _USER_ID});
                chatSocket.emit("requestRandomChat", {ROOM_SRNO: _ROOM_SRNO});
            }

            chatSocket.emit("requestRandomChat", {ROOM_SRNO: roomConnectId});
        } else if (Often.isAct("miniMain")) {
            chatSocket.emit("requestRandomChat", {ROOM_SRNO: _USER_ID});
            chatSocket.emit("requestRandomChat", {ROOM_SRNO: roomConnectId});
            chatSocket.emit('electronSession', {
                USER_ID: _USER_ID,
                RGSN_DTTM: _RGSN_DTTM,
                USE_INTT_ID: _USE_INTT_ID,
                // EMAIL: _EMAIL,
                WEB_USER_ID: _USER_ID,
                IS_ELECTRON_LOGIN: true,
            });
        } else {
            chatSocket.emit("requestRandomChat", {ROOM_SRNO: _USER_ID});
            chatSocket.emit("requestRandomChat", {ROOM_SRNO: roomConnectId});
        }

        callbackQueueWaitingForConnection.forEach(({json, cb}) => {
            Mutil.mlog(
                'callbackQueueWaitingForConnection execute ::: ',
                {code: json.CHAT_CODE, room: json.ROOM_SRNO, data: json}
            );
            cb();
        });
        callbackQueueWaitingForConnection = [];
    }

    function initIPTSocket() {
        if (!Often.isAct("miniMain")) return;
        if (!Often.isFunc('IPT_FUNC_KYOWON') && !Often.isFunc('IPT_FUNC')) return;
        iptSocket = Ipt.initSocket();
    }

    function clickToCall(callee) {
        if (!Often.isAct("miniMain")) return;
        if (!Often.isFunc('IPT_FUNC')) return;
        Ipt.clickToCall(callee);
    }

    function initStatusSocket() {
        if (!ElectronApi.Comm.isElectron()) return;
        if (!(Often.isAct("miniMain") || Often.isAct("messenger")) && ServerChecker.isKyowon) return;
        if (!Often.isFunc('USE_STATUS_SERVER') && !Often.isFunc('USE_STATUS_SERVER_V2') && !Often.isFunc(Func.ENTER.STATUS_FUNC)) return;

        // enter 용 상태서버 nginx를 통해서 연결
        if (ServerChecker.isEnter) {
            statusSocket = io.connect(_STATUS_URL, {path: '/status/socket.io', rememberUpgrade: true});
        } else {
            statusSocket = io.connect(_STATUS_URL, {rememberUpgrade: true});
        }

        if (ServerChecker.isKyowon) {
            if (Often.isAct("miniMain")) {
                statusSocket.emit("connect-status", {
                    CALL: 'MINI_MAIN',
                    ROOM: _USE_INTT_ID.slice(0, _USE_INTT_ID.indexOf('_')),
                    STATUS: MiniState.getStatusNumber(ProfileState.ONLINE),
                    USER_ID: _USER_ID
                });
            }
            statusSocket.on("updateStatus", receiveEventOnMiniView);
            if (Often.isAct("messenger")) {
                Chatting.getRoomInfo({
                    "isExecute": true, "roomSrno": _ROOM_SRNO, "callback": function (dat) {
                        Chatting.connectStatusSocket(dat);
                    }
                });
            }
        } else if (ServerChecker.isSoil) {
            statusSocket.emit('join', {
                USER_ID: _USER_ID,
                ROOM: _USE_INTT_ID
            });
            statusSocket.on('reconnect', function (data) {
                MiniState.setProfileStatus(Often.null2Void(data.PHONE_STATUS, "0") !== "0" ? ProfileState.CALL : SoilIpt.switchingStatus(data.STATUS), data.USER_ID);
            });
            statusSocket.on('updateStatus', function (data) {
                if (!data || !data.USER_ID || !data.STATUS) return;
                MiniState.setProfileStatus(Often.null2Void(data.PHONE_STATUS, "0") !== "0" ? ProfileState.CALL : SoilIpt.switchingStatus(data.STATUS), data.USER_ID);
            });

            statusSocket.on("reconnect", () => {
                statusSocket.emit("connect-status", {
                    CALL: 'MINI_MAIN',
                    ROOM: _USE_INTT_ID.slice(0, _USE_INTT_ID.indexOf('_')),
                    STATUS: MiniState.getStatusNumber(ProfileState.ONLINE),
                    USER_ID: _USER_ID
                });
            });
        }
    }


    /**
     *
     * @param messageJson
     * @param sendCallback - 유일하게 쓰는 곳이 OpenUtil.openMessengerByInvite
     */

    function sendMessage(messageJson, sendCallback) {
        messageJson = Time.tzrApi(messageJson)
        var emitJson;
        if (Often.isFunc("CHAT_ENCRYPT")) {
            emitJson = $.extend({
                WEB_USER_ID: _USER_ID,
                MC_USER_ID: _USER_ID,
                ENCRYPT_YN: "Y",
                ROOM_SRNO: chatLike(messageJson.ROOM_SRNO, _USER_ID),
                GUBUN: (ElectronApi.Comm.isElectron() ? "DESKTOP" : "WEB"),
                CHAT_S2S: isChatS2sOn ?? false
            }, messageJson)
        } else {
            emitJson = $.extend({WEB_USER_ID: _USER_ID, TAB_ID: sessionStorage.tabID}, messageJson);
        }

        if (isDisconnectAndConnect()) {
            setCallbackQueue({json: emitJson, cb: socketMessageEmit});
            return;
        }

        socketMessageEmit();

        function socketMessageEmit() {
            if (Often.isFunc('NETWORK_CHECKER')) {
                if (emitJson.CHAT_CODE === 'CHAT0001') {
                    NetworkChecker()?.renderStatusBar($("#messengerBottomArea"));
                }
            } else {
                refreshListenerEvent();
            }
            // 보낼때 소켓이 없으면 로깅
            writeChatLostLog('sendMessage')
            chatSocket.emit('sendMessage', emitJson);

            (typeof sendCallback === "function") && sendCallback();
        }

        function writeChatLostLog(hailFrom) {
            if (!Often.isFunc('CHAT_LOST_LOG') || !emitJson?.MSG_REC) return;
            if (!chatSocket || chatSocket.disconnected) {
                const detail = {
                    conn_arr: SocketConnArr ?? '',
                    MSG_REC: emitJson?.MSG_REC,
                    networkInfo: {
                        effectiveType: navigator.connection?.effectiveType ?? 'unknown'
                    },
                    CHAT_CODE: emitJson?.CHAT_CODE ?? '',
                    emitJson,
                    hailFrom
                }
                Ajax.executeApi("ACT_FEATURE_LOG_C001", {
                    FEATURE_ID: "CHAT_LOST_LOG",
                    DETAIL: JSON.stringify(detail),
                });
            }
        }
    }

    //쪽지일 경우 사용 명칭이 헷갈려서 Letter 로 사용
    function sendLetterByUserIds(messageJson) {

        sendLetterUserIds(messageJson.RCVR_USER_ID_REC);
        sendLetterUserIds(messageJson.REFER_USER_ID_REC);

        function sendLetterUserIds(sendUsers) {
            sendUsers.forEach(function (sendData) {
                messageJson.ROOM_SRNO = sendData.USER_ID;
                sendMessage(messageJson);
            });
        }
    }

    function receiveEventOnCalendarView(data) {
        data = Time.tzApi(data)
        const apiCode = Often.null2Void(data.CHAT_CODE);
        const deviceId = LocalUtil.getDeviceId();
        const isShowCalendarLayer = $("#calendarArea").is(":visible");
        const isElectron = ElectronApi.Comm.isElectron();

        if (SocketAPI.COMM.LOGOUT === apiCode && data.DUID === deviceId) {
            Often.toast("success", i18next.t(main.alert.terminateDevice))
            Often.logoutDirect(false, true);
            return;
        }

        if (SocketAPI.COMM.TEMP_LOGOUT === apiCode) {
            if (data.LOGOUT_CODE === LogoutCode.STOP) {
                const logOutPopupSubmit = () => {
                    var logOutConfirm = confirm(i18next.t(main.alert.redirectLogin));
                    if (logOutConfirm) {
                        Often.logoutDirect();
                    } else {
                        Company.drawServiceBlock(BLOCK_TYPE.SERVICE_USER, Often.logoutDirect, logOutPopupSubmit);
                    }
                }
                Company.drawServiceBlock(BLOCK_TYPE.SERVICE_USER, Often.logoutDirect, logOutPopupSubmit);
                return;
            }
        }

    }

    function readMyLetter(messageJson) {
        messageJson.ROOM_SRNO = _USER_ID;
        sendMessage(messageJson);
    }

    function retrievedLetter(messageJson) {
        messageJson.ROOM_SRNO = _USER_ID;
        sendMessage(messageJson);
    }

    function closeMessageReplyWindow(messageJson) {
        messageJson.ROOM_SRNO = _USER_ID;
        sendMessage(messageJson);
    }

    function deleteLetter(messageJson) {
        messageJson.ROOM_SRNO = _USER_ID;
        sendMessage(messageJson);
    }

    function bookmarkLetter(messageJson) {
        messageJson.ROOM_SRNO = _USER_ID;
        sendMessage(messageJson);
    }

    function sendMessageByUserIds(messageJson, successCallback) {
        if (BotUtil.isBotRoom()) return;
        if (!messageJson.CHATTING_ROOM_SRNO) messageJson.CHATTING_ROOM_SRNO = _ROOM_SRNO;
        if ("" !== Often.null2Void(strSocketUserIds)) {
            sendUserIds(messageJson, successCallback);
            return;
        }
        getSocketIdList(() => sendUserIds(messageJson), messageJson.CHATTING_ROOM_SRNO);
    }


    function sendUserIds(messageJson, successCallback) {
        let isRoomLeaveOrExport = messageJson.CHAT_CODE === SocketAPI.MAIN.ROOM_LEAVE_OR_EXPORT;

        // 스스로 나가는 유저면 소켓 전송 X
        if (!(isRoomLeaveOrExport && messageJson.GUBUN === "LEAVE")) {
            // 나를 처음 처리
            messageJson.ROOM_SRNO = _USER_ID;
            sendMessage(messageJson);
        }
        strSocketUserIds.split(",").forEach(function (sendId) {
            var isPass = ("" === Often.null2Void(sendId) || _USER_ID === sendId);
            if (isPass) return;
            messageJson.ROOM_SRNO = sendId;
            sendMessage(messageJson);
        });

        if (typeof successCallback === "function") successCallback();
    }

    function getSocketIdList(callback, roomSrno) {
        Ajax.executeApi(RestApi.GET.COLABO2_CHAT_SOCKET_ID_LIST_R001, {ROOM_SRNO: roomSrno}, function (dat) {
            strSocketUserIds = dat.SOCKET_ID_LIST;
            (typeof callback === "function") && callback();
        })
    }

    function addDefaultEvent(callback) {

        function completeMatchEvent() {
            if (isCompleteMatch) return;
            isCompleteMatch = true;
            if (Often.isAct("messenger")) {
                $("#messengerUl").removeClass('wait-matching');

                try {
                    MessengerLost.lostLastMessage();
                } catch (e) {
                    Mutil.mlog("completeMatchEvent -> lostLastMessage Error");
                }

                const {isTryRead, roomSrno, sessionUserId} = window.globalValue || {};
                if (isTryRead) {
                    Mutil.mlog('isTryRead');
                    SocketControl.sendMessage({
                        CHAT_CODE: SocketAPI.MAIN.READ_ALL_MESSAGE_BY_ROOM,
                        ROOM_SRNO: sessionUserId,
                        CHATTING_ROOM_SRNO: roomSrno,
                    });
                }
            }
        }

        const socketLog = () => {
            if (typeof window.globalValue?.socketConnSec === 'undefined') return
            if (window.globalValue.socketConnSec === 0) return;
            Mutil.mlog('SOCKET_CONN_TIME :: newSocket', new Date().getTime() - window.globalValue.socketConnSec);
            window.globalValue.socketConnSec = 0;
        }

        if (!chatSocket) {
            NetworkChecker()?.drawSendSocketLog(`socket where ar u?`);
            return;
        }

        if (Often.isFunc("NETWORK_CHECKER")) {
            NetworkChecker()?.drawSendSocketLog(`Listen [connect,disconnect,completeMatch]`);

            chatSocket.on("connect", () => {
                timeJson.connect.gap = new Date().getTime() - timeJson.connect.start;
                NetworkChecker()?.drawSendSocketLog(`connect >> complete ${timeJson.connect.gap}ms`);
                MessengerNetworkManager()?.allowSendMessage()
                setTimeout(() => {
                    initSocket();
                }, NetworkChecker()?.TIMEOUT_CONFIG.MATCHING * 1000);
                (typeof callback === "function") && callback();
            });

            chatSocket.on("disconnect", () => {
                isCompleteMatch = false;
                NetworkChecker()?.drawSendSocketLog('disconnect');
                MessengerNetworkManager()?.blockSendMessage({isSilentSocketBlock: true})
            });

            chatSocket.on("completeMatch", (props = {}) => {
                SocketConnArr.push(props)
                completeMatchEvent();
                timeJson.matching.gap = new Date().getTime() - timeJson.matching.start;
                NetworkChecker()?.drawSendSocketLog(`completeMatch >> complete ${timeJson.matching.gap}ms`);
                NetworkChecker()?.renderStatusBar($("#messengerBottomArea"));
                socketLog();
            });
            return;
        }

        chatSocket.on("connect", () => {
            if (Often.isAct("messenger")) {
                SocketConnArr.push({type: 'connect', time: new Date()});
            }
            initSocket();
            (typeof callback === "function") && callback();
        });

        chatSocket.on("disconnect", () => {
            if (Often.isAct("messenger")) {
                SocketConnArr.push({type: 'disconnect', time: new Date()});
            }
            isCompleteMatch = false;
        });

        let lastReconnectTime = null;
        chatSocket.on("reconnect", () => {
            if (Often.isAct("messenger")) {
                SocketConnArr.push({type: 'reconnect', time: new Date()});
            }

            if (Often.isFunc("MESSENGER_NETWORK_CHECK")) {
                completeMatchEvent();
            }

            if (Often.isFunc("CHAT_LIST_REFRESH")) {
                const currentTime = new Date();
                // 이전에 reconnect 된 적 없거나, 5분 이상 지났을 경우에만 갱신
                if (lastReconnectTime && ((currentTime - lastReconnectTime) <= 5 * 60 * 1000)) return;
                if ($("#chattingLayer").is(":visible") || $("#miniChatting").is(":visible")) {
                    Chatting.drawChattingItems({isInit: true});
                    lastReconnectTime = currentTime;
                }
            }
        });

        chatSocket.on("completeMatch", () => {
            completeMatchEvent();
            socketLog();
        });

        chatSocket.on("force-logout", () => {
            Often.logoutDirect();
        });
    }

    function sendLoginSecurityCheck(userId) {
        try {
            SocketControl.sendMessage({
                CHAT_CODE: SocketAPI.COMM.LOGIN_SECURITY_CHECK ?? "LOGIN_SECURITY_CHECK",
                USE_INTT_ID: _USE_INTT_ID,
                ROOM_SRNO: userId,
            });
        } catch (e) {
            console.error("LOGIN_SECURITY_CHECK ERROR", e)
        }
    }

    function logout(userId) {
        try {
            chatSocket.emit('sendMessage', {
                CHAT_CODE: SocketAPI.COMM.LOGOUT,
                ROOM_SRNO: userId,
                WEB_USER_ID: userId,
                DUID: LocalUtil.getDeviceId(),
            });
        } catch (e) {
        }
    }

    function closeExpiredWindows(isElectron) {
        try {
            chatSocket.emit('sendMessage', {
                CHAT_CODE: SocketAPI.COMM.POPUP_CLOSE,
                ROOM_SRNO: _USER_ID,
                WEB_USER_ID: _USER_ID,
                isELECTRON: isElectron,
            });
        } catch (e) {
        }
    }

    function addReceiveEvent() {
        if (Often.isAct("subscreen")) return;

        const isInviteEmplView = Often.isAct("newSignup") || Often.isAct("inviteEmpl");
        NetworkChecker()?.drawSendSocketLog(`Listen [receiveMessage]`);
        chatSocket.on("receiveMessage", (data) => {
            if (Often.isAct("messenger")) {
                receiveEventOnMessengerView(data);
            } else if (Often.isAct("miniMain") || Often.isAct("minimain")) {
                receiveEventOnMiniView(data);
            } else if (Often.isAct("main") || Often.isAct("/l/") || Often.isAct("portal") || isInviteEmplView) {
                receiveEventOnMainView(data);
            } else if (Often.isAct("letter")) {
                Often.isFunc(Func.ENTER.MESSAGE_POPUP) && receiveEventOnLetterView(data);
            } else if (Often.isAct("calendar")) {
                receiveEventOnCalendarView(data);
            } else {
                NetworkChecker()?.drawSendSocketLog(`No Listen [receiveMessage] ${location.pathname}`);
            }

            if (Often.isFunc("NETWORK_CHECKER")) {
                if (data.CHAT_CODE === 'NETWORK_CHECK') {
                    setTimeout(() => {
                        const gap = new Date().getTime() - data.sendTime;
                        const contents = `${NetworkChecker()?.getContentsHeader(data.ROOM_SRNO)} receive ${gap}ms`;
                        NetworkChecker()?.sendContents(contents, data.sendTime, data.pathname);
                    }, NetworkChecker()?.TIMEOUT_CONFIG.AFTER_RECEIVE * 1000);
                } else if (data.CHAT_CODE === 'SOCKET_CHECK') {
                    NetworkChecker()?.updateStatusMatch('conn', true);
                    NetworkChecker()?.updateStatusMatch('listen', true);
                    NetworkChecker()?.updateStatusMatch('match', true);
                }
            }
        });
    }

    function sendFocusStatus(status) {
        if (!chatSocket || chatSocket.disconnected) {
            status && MessengerLost.createALogUnderTheNameOfChatLost({
                hailFrom: 'SocketControl.js sendFocusStatus',
                roomSrno: _ROOM_SRNO
            })
            return;
        }
        try {
            chatSocket.emit("focusStatus", {
                ROOM_SRNO: _ROOM_SRNO,
                USE_INTT_ID: _USE_INTT_ID,
                USER_ID: _USER_ID,
                STATUS: status ? "true" : "false"
            });
        } catch (e) {
            console.error("sendFocusStatus == ", e);
        }
    }

    function receiveEventOnMainView(data) {
        data = Time.tzApi(data)
        var apiCode = Often.null2Void(data.CHAT_CODE);
        var isShowChattingLayer = $("#chattingLayer").is(":visible");
        var roomSrno = Often.null2Void(data.CHATTING_ROOM_SRNO);
        var deviceId = LocalUtil.getDeviceId();
        var isElectron = ElectronApi.Comm.isElectron();
        var isMiniModeElectron = isElectron && !ElectronApi.Comm.getConfigs().b_fullMode;
        const isWriteMyself = (data.MSG_REC?.[0]?.RGSR_ID || '') === window._USER_ID;

        if (apiCode !== SocketAPI.COMM.MOUSE_MOVE &&
            apiCode !== SocketAPI.COMM.KEY_DOWN &&
            apiCode !== SocketAPI.MAIN.FOCUS_IN &&
            apiCode !== SocketAPI.MAIN.FOCUS_OUT &&
            apiCode !== SocketAPI.COMM.OVERLAP_LOGOUT
        ) {
            Often.clog('Main socket', apiCode, data);
        }

        if (SocketAPI.MAIN.BADGE_UPDATE === apiCode) {
            handlerBadgeUpdate('full-main', data);
            chatBadgeTracking(data);
            return;
        }

        if (SocketAPI.CONNECT.MESSENGER.CREATE === apiCode) {
            useDashboard('chat');
            useMainPortal('chat');
            Chatting.updateChattingLayer(data)
            return;
        }

        if (SocketAPI.MAIN.READ_ALL_MESSAGE_BY_ROOM === apiCode) {
            // 채팅 모두읽음 시, 채팅방 개수만큼 실행됨 -> 모바일과 협의하여 발신부에서 소켓 한 번만 쏘도록 개선 필요
            Chatting.drawAsyncTopNotReadCount().then(() => {
                ListHelper.setCount($('#chatting-' + roomSrno).find('.not-read-count'), 0);
                ElectronApi.Comm.isElectron() && ElectronApi.Notification.hideNoti(roomSrno);
            });
            useDashboard('chat');
            useMainPortal('chat');
            return;
        }

        if (SocketAPI.MAIN.CHAT_UPDATE === apiCode) {
            addLogChatUpdate(data);

            useDashboard('chat');
            useMainPortal('chat');
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            if (!isWriteMyself) {
                Chatting.drawTopNotReadCount();
            }

            // [한국증권금융] 일렉트론 로그인되어있으면 웹에 쪽지, 채팅 푸시 금지
            if (Often.isFunc("SEPERATE_WEB_ELECTRON_CHAT_ALARM") && data.IS_ELECTRON_LOGIN) return;

            !isMiniModeElectron && BrowserNoti.showChattingNotification(data);
            return;
        }

        if (SocketAPI.MAIN.DELETE_MESSAGE_ALL_VIEW === apiCode) {
            useDashboard('chat');
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            !isMiniModeElectron && BrowserNoti.showChattingNotification(data);
            return;
        }

        if (SocketAPI.MAIN.ROOM_NAME_UPDATE === apiCode ||
            SocketAPI.MAIN.ALARM_N_PIN_UPDATE === apiCode ||
            SocketAPI.MAIN.DELETE_MESSAGE_MY_VIEW === apiCode
        ) {
            useDashboard('chat');
            useMainPortal('chat');
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            return;
        }

        if (SocketAPI.MAIN.ROOM_INVITE === apiCode) {
            useDashboard('chat');
            useMainPortal('chat');
            isShowChattingLayer && Chatting.changeChattingItemByJoin(data.CHATTING_ROOM_SRNO);
            return;
        }


        if (SocketAPI.MAIN.ROOM_LEAVE === apiCode) {
            useDashboard('chat', data);
            useMainPortal('chat', data);
            isShowChattingLayer && Chatting.removeChattingLayer(data);
            return;
        }

        if (SocketAPI.MAIN.ROOM_LEAVE_OR_EXPORT === apiCode) {
            isShowChattingLayer && Chatting.changeChattingItemByLeave(data.CHATTING_ROOM_SRNO);
            return;
        }

        if (SocketAPI.MAIN.LEAVE_MULTI_USER === apiCode) {
            const deleteUserArray = data.DEL_USER_REC;
            const isDeleteUser = deleteUserArray.findIndex((v) => (v.IN_RCVR_USER_ID === _USER_ID)) > -1;
            useDashboard('chat', data);
            useMainPortal('chat', data);
            (isDeleteUser && isShowChattingLayer) && Chatting.removeChattingLayer(data);
            (!isDeleteUser && isShowChattingLayer) && Chatting.changeChattingItemByLeave(data.CHATTING_ROOM_SRNO);
            return;
        }

        if (SocketAPI.MAIN.DELETE_PROJECT_LEAVE === apiCode && data.COLABO_SRNO === window.ViewChanger?.getProjectSrno()) {
            ViewChanger.loadPageJson("main");
            Mutil.toast("error", i18next.t(main.alert.deleted, {val: $t(dictionary.project)}));
            return;
        }

        if (SocketAPI.COMM.LOGIN_SECURITY_CHECK === apiCode && data.USE_INTT_ID === _USE_INTT_ID) {
            loginSecurityCheck();
            return;
        }

        if (SocketAPI.MAIN.UPDATE_ONLY_MANAGER_YN === apiCode) {
            isShowChattingLayer && Chatting.updateOnlyManagerYn(data);
            return;
        }

        if (SocketAPI.COMM.LOGOUT === apiCode && data.DUID === deviceId) {
            Often.toast("success", i18next.t(main.alert.terminateDevice))
            Often.logoutDirect(false, true);
            return;
        }

        if (SocketAPI.COMM.UPDATE_SESSION === apiCode && data.ROOM_SRNO === window._USER_ID) {
            Ajax.executeApi("ACT_UPDATE_SESSION_U001");
            return;
        }

        if (SocketAPI.MAIN.SERVICE_UPGRADE === apiCode && data.ROOM_SRNO === window._USER_ID) {
            if (ElectronApi.Comm.isElectron()) {
                Payment.openPaymentLayer("business");
                return;
            }
        }

        if (SocketAPI.COMM.TEMP_LOGOUT === apiCode) {
            if (data.LOGOUT_CODE === LogoutCode.STOP) {
                const logOutPopupSubmit = () => {
                    var logOutConfirm = confirm(i18next.t(main.alert.redirectLogin));
                    if (logOutConfirm) {
                        Often.logoutDirect();
                    } else {
                        Company.drawServiceBlock(BLOCK_TYPE.SERVICE_USER, Often.logoutDirect, logOutPopupSubmit);
                    }
                }
                Company.drawServiceBlock(BLOCK_TYPE.SERVICE_USER, Often.logoutDirect, logOutPopupSubmit);
                return;
            }
            if (data.DUID !== deviceId) return;

            var minutes = Often.null2Void(data.MINS, 0);
            var timeText = Time.minToHour(minutes);
            Often.logoutDirect(true);

            //Note. 모든 이벤트 제거
            $("*").off();
            $(document).on("click", function () {
                Often.toast("error", i18next.t(main.alert.wrongApproach));
            })

            PopupDraw.drawBlock({
                class: 'service',
                closeButtonYn: 'Y',
                contents: {
                    title: i18next.t(common.autoLogout),
                    main: i18next.t(main.alert.autoLogout, {time: timeText}),
                    submit: i18next.t(dictionary.login),
                },
                callback: {
                    submit: goLogin,
                    close: goLogin,
                    final: function ($pop) {
                        $pop.css("z-index", 99);
                    }
                }
            });

            function goLogin() {
                location.replace('/signin.act');
            }

            return;
        }

        if (SocketAPI.COMM.OVERLAP_LOGOUT === apiCode) {
            if (data.DUID !== LocalUtil.getDeviceId()) return;
            Often.toast("success", i18next.t(main.alert.terminateDevice))
            Often.logoutDirect();
            return;
        }

        if (SocketAPI.COMM.MOUSE_MOVE === apiCode) {
            if (data.TAB_ID === _TAB_ID) return;
            if (data.DUID !== LocalUtil.getDeviceId()) return;
            LockControl.startLockModeAfterMinutes();
            return;
        }

        if (SocketAPI.COMM.KEY_DOWN === apiCode) {
            if (data.TAB_ID === _TAB_ID) return;
            if (data.DUID !== LocalUtil.getDeviceId()) return;
            LockControl.startLockModeAfterMinutes();
            return;
        }

        if (SocketAPI.MAIN.FOCUS_IN === apiCode) {
            $('body').attr('data-focus-room-srno', data.FOCUS_ROOM_SRNO);
            return;
        }

        if (SocketAPI.MAIN.FOCUS_OUT === apiCode) {
            $('body').attr('data-focus-room-srno', '');
            return;
        }

        if (SocketAPI.MAIN.FLOW_DRIVE_FILE_IMPORT === apiCode) {
            var $postPopup = PostPopup.replacePostArea($("#postPopup:visible"));
            $.each(data.FILE_REC, function (i, file) {
                PostAppend.appendFileOrImg($postPopup, file);
            })
        }

        if (SocketAPI.MAIN.BADGE_SELF_UPDATE === apiCode) {
            if (!Often.isFunc(Func.CLOUD.ALARM_COUNT_SOCKET)) return;
            AlarmUpdate.updateUnReadElementBySelf(data.ALARM_DATA);
            useDashboard('main', data);
            useMainPortal('main', data);
            return;
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLE === apiCode) {
            if (data.ACTIVE_YN === 'Y') {
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleCalendar', 'Y');
                OutService.activateGoogleCalendarButton();
                Often.toast('success', i18next.t(common.hasSync, {val: $t(dictionary.googleCalendar)}));
                GoogleWatch()?.subscribeToGoogleWatch("GoogleCalendar");
                useDashboard('gcalendar', {reRender: true})
            } else {
                OutService.deactivateButton($('#googleCalendarConnectBtn'), 'calendar-data')
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleCalendar', 'N')
                Ajax.executeApi("ACT_DASHBOARD_GOOGLE_CALENDAR_LIST_D001", {"USER_ID": _USER_ID});
                GoogleWatch()?.unsubscribeToGoogleWatch("GoogleCalendar");
                useDashboard('gcalendar');
            }
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLEDRIVE === apiCode) {
            const userGWS = Mutil.isFunc("GOOGLE_WORKSPACE_LINKTOFILE");

            if (data.ACTIVE_YN === 'Y') {
                userGWS && LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleDriveFeatures', 'Y');
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleDrive', 'Y');
                OutService.activateButton($('#gdriveConnectBtn'), 'gdrive-data');
                OutService.hideButton($('#gdriveUpdateBnt'))
                GoogleWatch()?.subscribeToGoogleWatch("GoogleDrive");
                if (data.INFO != null) {
                    return FlowEditor.commands("RENDER_GOOGLE_FILE", data.INFO.url)
                }

                if (!userGWS || data.EXTRA_INFO === 'picker') {
                    if ($("#mySettingPopup").is(":visible")) return;
                    if (window._WINDOW_UUID === data.WIN_UUID) {
                        GoogleDrive.OpenPickerAfterSign(data.TOKEN);
                    }
                }
            } else {
                OutService.deactivateButton($('#gdriveConnectBtn'), 'gdrive-data');
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleDrive', 'N')
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleDriveFeatures', 'N');
                GoogleWatch()?.unsubscribeToGoogleWatch("GoogleDrive");
            }

            if (!userGWS) return;
            if (ViewChanger.isPage('dashboard')) {
                useDashboard('gdrive');
            } else if (ViewChanger.isPage('detail') && $('.js-side-google-layer').is(':visible')) {
                window.GoogleKeywordList?.getCurrentComponent()?.renderContents();
            }
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLEMEET === apiCode) {
            if (data.ACTIVE_YN === 'Y') {
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleMeet', 'Y');
                OutService.activateButton($('#googleMeetConnectBtn'), 'google-meet-data');
            } else {
                OutService.deactivateButton($('#googleMeetConnectBtn'), 'google-meet-data');
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleMeet', 'N')
            }
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLEMAIL === apiCode) {
            if (data.ACTIVE_YN === 'Y') {
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleMail', 'Y');
                OutService.activateButton($('#gmailConnectBtn'), 'gmail-data');
                GoogleWatch()?.subscribeToGoogleWatch("GoogleMail", '', data.GOOGLE_USER_EMAIL);
            } else {
                OutService.deactivateButton($('#gmailConnectBtn'), 'gmail-data');
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleMail', 'N')
                GoogleWatch()?.unsubscribeToGoogleWatch("GoogleMail");
            }

            if (ViewChanger.isPage('dashboard')) {
                useDashboard('gmail');
            } else if (ViewChanger.isPage('detail') && $('.js-side-google-layer').is(':visible')) {
                window.GoogleKeywordList?.getCurrentComponent()?.renderContents();
            }
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLE_PEOPLE_DIRECTORY === apiCode) {
            if (data.ACTIVE_YN === 'Y') {
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GooglePeopleDirectory', 'Y');
                if ($("#mySettingPopup").is(":visible")) {
                    // 환경설정에서 연동 시, 연동버튼 active + user List draw X
                    OutService.activateButton($('#googleContactsConnectBtn'), 'gcontacts-data');
                    return;
                }
                InviteLayer.setIntegrationStatus(true);
                InviteLayer.drawGoogleUserList();
            } else {
                LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GooglePeopleDirectory', 'N')
                OutService.deactivateButton($('#googleContactsConnectBtn'), 'gcontacts-data');
            }
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLE_SHEETS === apiCode) {
            const isTaskTab = ViewChanger.isPage("task")
            if (data.ACTIVE_YN === 'Y' && isTaskTab) {
                const GoogleSheetSync = requireModule('GoogleSheetSync', {on: ['main']})();
                GoogleSheetSync?.syncSelectPopupRender();
                return
            }
        }

        if (SocketAPI.CONNECT.SYNC.GOOGLE_DASHBOARD_ALL === apiCode) {
            // 해당 Socket은 켜는 로직만 존재함
            // 연동 Key On 설정
            LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleDriveFeatures', 'Y');
            LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleMail', 'Y');
            LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GoogleCalendar', 'Y')

            drawDashboardInOrder().then(r => {
                // 3개의 Google Dashboard 기능 업데이트
                useDashboard('gdrive');
                useDashboard('gmail');
                useDashboard('gcalendar');
            })
        }


        if (SocketAPI.MAIN.UNREAD_MAIL_CNT === apiCode &&  window._USER_ID === data.ROOM_SRNO) {
            const $mailCountItem = $(".js-mail-alarm");
            if($mailCountItem.length  === 0) {
                return ;
            }
            const existsMailCount = Number(Mutil.n2v(data.NOT_READ_CNT , '0')) > 0;
            if(!existsMailCount) {
                $mailCountItem.css("display" , "none");
                return ;
            }
            $mailCountItem.css("display" , "inline-block");
            $mailCountItem.text(Mutil.n2v(data.NOT_READ_CNT , '0'));
            return;
        }

        if (SocketAPI.MAIN.NOTDISTURB === apiCode) {
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_ALAM', data.SETTING_DATA.NOTDISTURB_ALAM);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_STIME', data.SETTING_DATA.NOTDISTURB_STIME);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_ETIME', data.SETTING_DATA.NOTDISTURB_ETIME);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'COMMT_ALAM', data.SETTING_DATA.COMMT_ALAM);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'FLOW_ALAM_YN', data.SETTING_DATA.FLOW_ALAM_YN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'CHAT_ALAM_YN', data.SETTING_DATA.CHAT_ALAM_YN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'LOCK_MODE_YN', data.SETTING_DATA.LOCK_MODE_YN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'LOCK_MODE_MIN', data.SETTING_DATA.LOCK_MODE_MIN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_DAY', data.SETTING_DATA.NOTDISTURB_DAY);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'MESSAGE_ALAM_YN', data.SETTING_DATA.MESSAGE_ALAM_YN);
            Preferences.updateNotificationTabBySocket(data)
        }

        // 게시글 공동 수정
        if (SocketAPI.MAIN.EDIT_COLLABO_NOTI === apiCode) {
            PostEdit.drawEditingNoti(data);
            return;
        }

        //쪽지
        const isMessageFunc = Often.isFunc(Func.ENTER.MESSAGE_POPUP);
        if (isMessageFunc && SocketAPI.LETTER.UPDATE === apiCode) {
            Letter.drawCount();
            if (Often.isFunc("SEPERATE_WEB_ELECTRON_LETTER_ALARM") && data.IS_ELECTRON_LOGIN) return;
            Letter.showNoti(data, true, isMiniModeElectron);
            return;
        }

        if (isMessageFunc && [SocketAPI.LETTER.READ, SocketAPI.LETTER.RETRIEVED_LETTER_DELETE, SocketAPI.LETTER.DELETE, SocketAPI.LETTER.DELETE_ALL].includes(apiCode)) {
            Letter.loadTopNotReadCount();
            return;
        }

        if (isMessageFunc && SocketAPI.LETTER.READ_CONFIRM === apiCode) {
            if (Often.isFunc("SEPERATE_WEB_ELECTRON_LETTER_ALARM") && data.IS_ELECTRON_LOGIN) return;
            Letter.updateReadConfirm(data, true, isMiniModeElectron);
        }

        if (SocketAPI.MAIN.SERVICE_FAVORITE_CREATE === apiCode) {
            MajorService.addFavoriteItemInList(data);
            return;
        }

        if (SocketAPI.MAIN.SERVICE_FAVORITE_DELETE === apiCode) {
            MajorService.removeFavoriteItemInList(data);
            return;
        }

        if (SocketAPI.MAIN.REMINDER_CLEAR === apiCode) {
            ItemReminder.clearReminder(data);
            return;
        }

        if (SocketAPI.MAIN.REMINDER_UPDATE === apiCode) {
            ItemReminder.updateReminderByChatBot(data);
            return;
        }

        if (SocketAPI.MAIN.REMINDER_CHATBOT_RESPONSE === apiCode) {
            Chatting.drawTopNotReadCount();
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            return;
        }

        if (SocketAPI.MAIN.SCHEDULE_REMINDER === apiCode) {
            BrowserNoti.showScheduleReminderNotification(data);
            return;
        }

        if (SocketAPI.MAIN.SERVICE_OPEN_IN_IFRAME === apiCode) {
            Often.isFunc('OPEN_IFRAME') && Top.showIframe(data.URL);
            return;
        }

        if (SocketAPI.CONNECT.REFUSED === apiCode) {
            Mutil.toast("error", i18next.t(main.thirdParty.refuse));
            return;
        }

    }

    async function drawDashboardInOrder() {
        await drawDashboard('gcalendar');
        await drawDashboard('gdrive');
        await drawDashboard('gmail');
    }

    function receiveEventOnLetterView(data) {
        data = Time.tzApi(data)
        var apiCode = Often.null2Void(data.CHAT_CODE);
        var isShowLetterMainLayer = $("#messageMain").is(":visible");
        var isShowLetterDetailLayer = $("#detailMessage").is(":visible");
        var deviceId = LocalUtil.getDeviceId();
        var isElectron = ElectronApi.Comm.isElectronApp();

        if (SocketAPI.LETTER.UPDATE === apiCode) {
            isShowLetterMainLayer && Letter.updateLetterMainLayer();
            return;
        }

        if (SocketAPI.LETTER.READ === apiCode) {
            isShowLetterMainLayer && Letter.readLetter(data);
            return;
        }

        if (SocketAPI.LETTER.RETRIEVE === apiCode) {
            isShowLetterMainLayer && Letter.updateRetrievedLetter(data);
            return;
        }

        if (SocketAPI.LETTER.RETRIEVED_LETTER_DELETE === apiCode) {
            isShowLetterMainLayer && Letter.deleteRetrievedLetter(data);
            return;
        }

        if (SocketAPI.LETTER.CLOSE_WINDOW === apiCode) {
            isShowLetterDetailLayer && Letter.closeWindow(data);

        }

        if (SocketAPI.LETTER.DELETE === apiCode) {
            if (data.DELETE_YN === 'Y') {
                Letter.deleteLetter(data);
            }
            return;
        }

        if (SocketAPI.LETTER.DELETE_ALL === apiCode) {
            Letter.deleteAllLetter(data);
            return;
        }

        if (SocketAPI.MAIN.CALENDAR_AUTHORIZE === apiCode) {
            !isElectron && BrowserNoti.showCalendarNotification(data);
            return;
        }

        if (SocketAPI.LETTER.BOOKMARK === apiCode) {
            Letter.bookmarkLetter(data);
            return;
        }

        if (SocketAPI.LETTER.READ_CONFIRM === apiCode) {
            if (!isElectron && Often.isFunc("SEPERATE_WEB_ELECTRON_LETTER_ALARM") && data.IS_ELECTRON_LOGIN) return;
            Letter.updateReadConfirm(data);
            return;
        }

        if (SocketAPI.LETTER.TEMPORARY_DELETE === apiCode) {
            Letter.deleteTemporaryLetter(data);
            return;
        }

    }

    function receiveEventOnMessengerView(data) {
        data = Time.tzApi(data)
        if (data.CHAT_CODE === 'CHAT0001_S2S' || data.CHAT_S2S_MSG_YN === 'Y') {
            const message = data.MSG_REC?.[0] ?? null
            if (!message) {
                return Often.toast("error", "Message data error");
            }

            const parseJsonArray = (array) => {
                return array.map(item => {
                    if (typeof item === 'string') {
                        const escapedLetterRemovedItem = item.replace(/^"+|"+$/g, '')
                            .replace(/\\\"/g, '"')
                            .replace(/\\\\/g, '\\');
                        return JSON.parse(escapedLetterRemovedItem);
                    }
                    return item;
                });
            }

            const fieldsToParse = ['IMG_CLOUD_REC', 'IMG_REC', 'REPLY_REC', 'FILE_CLOUD_REC'];
            fieldsToParse.forEach(field => {
                message[field] = parseJsonArray(message[field] ?? []);
            });
        }
        var apiCode = Often.null2Void(data.CHAT_CODE);
        var deviceId = LocalUtil.getDeviceId();
        var isElectron = ElectronApi.Comm.isElectron();
        var isDifferentDevice = data.DUID !== deviceId;

        if (apiCode !== SocketAPI.COMM.MOUSE_MOVE &&
            apiCode !== SocketAPI.MAIN.FOCUS_IN &&
            apiCode !== SocketAPI.MAIN.FOCUS_OUT) {
            Often.clog('채팅 소켓', apiCode, data);
        }

        if (MessengerApi.isReceiveAndAction(apiCode, data)) return; //메시지, 읽음, 알람, 상단고정, 스크랩 갱신
        if (MessengerMenu.isReceiveAndAction(apiCode, data)) return; //메시지, 읽음, 알람, 상단고정, 스크랩 갱신
        if (MessengerDelete.isReceiveAndAction(apiCode, data)) return; //메시지, 읽음, 알람, 상단고정, 스크랩 갱신
        if (MessengerNotice.isReceiveAndAction(apiCode, data)) return; //메시지, 읽음, 알람, 상단고정, 스크랩 갱신
        if (SocketAPI.MESSENGER.JOIN_N_LEAVE === apiCode) return MessengerSendience.joinNLeaveRoom(data);
        if (SocketAPI.MESSENGER.UPDATE_MANAGER === apiCode) return MessengerSendience.updateManagerSendience(data);
        if (SocketAPI.COMM.POPUP_CLOSE === apiCode) {
            if (data.isELECTRON === ElectronApi.Comm.isElectron() && _USE_INTT_ID.indexOf('KOGAS') === -1) return Often.toast("error", "POPUP_CLOSE");
        }

        if (SocketAPI.MESSENGER.PREV_CHAT_UPDATE === apiCode) {
            let prevChatYn = data.PREVCHAT_YN;
            let prevManagerYn = data.PREV_MANAGER_YN;

            updatePrevChat(prevChatYn, prevManagerYn);

            function updatePrevChat(prevChatYn, prevManagerYn) {
                roomInfo.PREVCHAT_YN = prevChatYn;
                roomInfo.PREV_MANAGER_YN = prevManagerYn;
                MessengerMenu.prevChatStts(prevChatYn);
                MessengerApi.drawPrevChatState(prevChatYn);
            }

            if (data.DUID !== deviceId) {
                PopupDraw.drawConfirmOnly({
                    contents: {main: "새로고침 해주세요"},
                    callback: {
                        submit: () => {
                            location.reload(true);
                        }
                    }
                });
            } else {
                location.reload(true);
            }
            return true;
        }

        //전달하기
        if (SocketAPI.MESSENGER.FORWARD_CONTENTS === apiCode && data.DUID === deviceId) {
            const toast = (type, msg, selectInfo) => MessengerToast.forwardToast({type, msg, selectInfo});
            if (data.TYPE === "CHAT") {
                toast('success', data.ROOM_NM, data);
            } else if (data.TYPE === "PROJECT") {
                toast('success', data.PROJECT_NM, data);
            }
            $('#messengerUl').children('li.message-item').last().attr('self-read-yn', 'Y');
            return;
        }

        if (SocketAPI.MESSENGER.CHILDREN_CLOSE === apiCode) {
            if (_USER_ID !== data.WEB_USER_ID) return;
            self.close();
            return;
        }

        if (SocketAPI.COMM.TEMP_LOGOUT === apiCode) {
            if (data.LOGOUT_CODE !== LogoutCode.STOP && isDifferentDevice) return;
            window.close();
            return;
        }

        if (SocketAPI.COMM.MOUSE_MOVE === apiCode) {
            if (data.TAB_ID === _TAB_ID) return;
            if (isDifferentDevice) return;
            LockControl.startLockModeAfterMinutes();
            return;
        }

        if (SocketAPI.COMM.TEMP_LOGOUT === apiCode) {
            if (isDifferentDevice) return;
            if (isElectron) window.open('', '_self', '');
            window.close();
            self.close();
            return;
        }

        if (SocketAPI.CONNECT.MESSENGER.FILE === apiCode) {
            data.FILE_REC.forEach(MessengerSend.sendFile)
            return;
        }

        if (SocketAPI.CONNECT.MESSENGER.CREATE === apiCode) {
            if (roomConnectId !== data.ROOM_SRNO) return;
            self.close();
        }

        if (SocketAPI.MAIN.FOCUS_IN === apiCode) {
            chatSocket.emit("focusStatus", {
                ROOM_SRNO: _ROOM_SRNO,
                USE_INTT_ID: _USE_INTT_ID,
                USER_ID: _USER_ID,
                STATUS: 'true'
            });
        }

        if (SocketAPI.MAIN.FOCUS_OUT === apiCode) {
            chatSocket.emit("focusStatus", {
                ROOM_SRNO: _ROOM_SRNO,
                USE_INTT_ID: _USE_INTT_ID,
                USER_ID: _USER_ID,
                STATUS: 'false'
            });
        }


        if (SocketAPI.COMM.UPDATE_SESSION === apiCode && data.ROOM_SRNO === window._USER_ID) {
            Ajax.executeApi("ACT_UPDATE_SESSION_U001", {}, (dat) => {
                initGlobalVar(dat);
            });
            return;
        }

        if (Often.isFunc(Func.ENTER.SECO_VIDEO_CONFERENCE)) {
            if (Mutil.n2v(data.VC_CREATE, "") !== "" && Mutil.n2v(data.VC_CREATE.ID, "0") !== "0") {
                // 화상회의 콜백
                if (Mutil.n2v(data.FLOW_DUID, "") === Often.getCookie("FLOW_DUID")) {
                    VideoConference.chat4nbPopupCallback(data.VC_CREATE);
                }
            }
        }
    }

    function initGlobalVar(dat) {
        if (Mutil.empty(dat.USER_ID)) {
            return;
        }
        if (Mutil.n2v(dat.FLNM) !== window?._USER_NM) {
            window._USER_NM = Mutil.n2v(dat.FLNM)
        }

        if (Mutil.n2v(dat.DVSN_NM) !== window?._DVSN_NM) {
            window._DVSN_NM = Mutil.n2v(dat.DVSN_NM);
        }
    }

    function sendAlarmAndPinUpdate(isAlarm, ynValue, roomSrno) {
        var key = isAlarm ? "PUSH_ALAM_YN" : "PIN_YN";
        var mainApiJson = {
            CHAT_CODE: SocketAPI.MAIN.ALARM_N_PIN_UPDATE,
            ROOM_SRNO: roomSrno,
            IN_RCVR_USER_ID: _USER_ID,
            CHATTING_ROOM_SRNO: roomSrno,
        }
        mainApiJson[key] = ynValue;
        var messengerApiJson = {
            CHAT_CODE: SocketAPI.MAIN.ALARM_N_PIN_UPDATE,
            ROOM_SRNO: _USER_ID,
            CHATTING_ROOM_SRNO: roomSrno,
        }
        var messengerApiJsonSelf = {
            CHAT_CODE: SocketAPI.MESSENGER.ALARM_N_PIN_UPDATE,
            ROOM_SRNO: roomSrno,
            CHATTING_ROOM_SRNO: roomSrno,
            IN_RCVR_USER_ID: _USER_ID,
        }
        messengerApiJsonSelf[key] = ynValue;
        messengerApiJson[key] = ynValue;
        sendMessage(mainApiJson);
        sendMessage(messengerApiJson);
        sendMessage(messengerApiJsonSelf);
    }

    function receiveEventOnMiniView(data) {
        // 셀프삭제인경우 본인여부 확인
        const isSelfDelete = SocketAPI.MESSENGER.DELETE_MESSAGE_MY_VIEW === data.CHAT_CODE ||
            SocketAPI.MAIN.DELETE_MESSAGE_MY_VIEW === data.CHAT_CODE;
        if (isSelfDelete && data.WEB_USER_ID !== data.ROOM_SRNO) {
            return;
        }
        data = Time.tzApi(data)
        const apiCode = Often.null2Void(data.CHAT_CODE);
        const isShowChattingLayer = $("#miniChatting").is(":visible");
        const isShowProjectLayer = $("#miniProject").is(":visible");
        const roomSrno = Often.null2Void(data.CHATTING_ROOM_SRNO);
        const projectSrno = Often.null2Void(data.COLABO_SRNO, "-1");
        const isElectron = !!window.ElectronProcessApi;
        const deviceId = LocalUtil.getDeviceId();

        if (apiCode !== SocketAPI.COMM.MOUSE_MOVE &&
            apiCode !== SocketAPI.MAIN.FOCUS_IN &&
            apiCode !== SocketAPI.MAIN.FOCUS_OUT) {
            Often.clog('미니 소켓', apiCode, data);
        }

        if (SocketAPI.COMM.TEMP_LOGOUT === apiCode) {
            if (data.LOGOUT_CODE === LogoutCode.STOP) {
                Company.drawServiceBlock(BLOCK_TYPE.SERVICE_USER, Often.logoutDirect, function () {
                    var logOutConfirm = confirm(i18next.t(main.alert.redirectLogin));
                    if (logOutConfirm) Often.logoutDirect();
                });
                return;
            }
            if (data.DUID !== deviceId || isElectron) return;

            var minutes = Often.null2Void(data.MINS, 0);
            var timeText = Time.minToHour(minutes);
            Often.logoutDirect(true);
            PopupDraw.drawBlock({
                mini: true,
                class: 'service',
                closeButtonYn: 'Y',
                contents: {
                    title: i18next.t(common.autoLogout),
                    main: i18next.t(main.alert.autoLogout, {time: timeText}),
                    submit: i18next.t(dictionary.login),
                },
                callback: {
                    submit: goLogin,
                    close: goLogin,
                }
            });

            function goLogin() {
                location.href = "miniSignin.act";
            }

            return;
        }

        if (SocketAPI.COMM.OVERLAP_LOGOUT === apiCode || SocketAPI.COMM.LOGOUT === apiCode) {
            if (data.DUID !== LocalUtil.getDeviceId()) return;
            if (ServerChecker.isKyowon && Often.isFunc('USE_STATUS_SERVER') && Often.isAct("miniMain")) {
                SocketControl.getStatusSocket().emit('overlap-logout-check', {
                    CALL: "MINI_MAIN",
                    OVERLAP_LOGOUT: "Y",
                    ROOM: _USE_INTT_ID.slice(0, _USE_INTT_ID.indexOf('_')),
                    USER_ID: _USER_ID,
                    STATUS: 0
                });
            }
            Often.toast("success", i18next.t(main.alert.terminateDevice))
            Often.logoutDirect();
            return;
        }

        if (SocketAPI.COMM.UPDATE_SESSION === apiCode && data.ROOM_SRNO === window._USER_ID) {
            Ajax.executeApi("ACT_UPDATE_SESSION_U001");
            return;
        }
        if (SocketAPI.COMM.LOGIN_SECURITY_CHECK === apiCode && data.USE_INTT_ID === _USE_INTT_ID) {
            loginSecurityCheck();
            return;
        }

        if (SocketAPI.CONNECT.MESSENGER.CREATE === apiCode) {
            Chatting.updateChattingLayer(data)
            return;
        }

        if (SocketAPI.MAIN.ROOM_LEAVE === apiCode) {
            isShowChattingLayer && MiniChatting.removeMessengerList(roomSrno);
            return;
        }

        if (SocketAPI.MAIN.LEAVE_MULTI_USER === apiCode) {
            const deleteUserArray = data.DEL_USER_REC;
            const isDeleteUser = deleteUserArray.findIndex((v) => (v.IN_RCVR_USER_ID === _USER_ID)) > -1;
            (isDeleteUser && isShowChattingLayer) && MiniChatting.removeMessengerList(roomSrno);
            return;
        }

        if (SocketAPI.MAIN.ROOM_LEAVE_OR_EXPORT === apiCode) {
            isShowChattingLayer && Chatting.changeChattingItemByLeave(data.CHATTING_ROOM_SRNO);
            return;
        }

        if (SocketAPI.MAIN.LEAVE_MULTI_USER === apiCode) {
            const deleteUserArray = data.DEL_USER_REC;
            const isDeleteUser = deleteUserArray.findIndex((v) => (v.IN_RCVR_USER_ID === _USER_ID)) > -1;
            (isDeleteUser && isShowChattingLayer) && MiniChatting.removeMessengerList(roomSrno);
            (!isDeleteUser && isShowChattingLayer) && Chatting.changeChattingItemByLeave(data.CHATTING_ROOM_SRNO);
            return;
        }

        if (SocketAPI.MAIN.CHANGE_STATE === apiCode) {
            MiniState.setProfileStatus(data.STATUS, data.USER_ID);
            return;
        }

        if (SocketAPI.CONNECT.INVITE.FAVORITE === apiCode) {
            MiniFavorite.showFavoriteGroupData(false);
            return;
        }

        if (SocketAPI.MAIN.CHAT_UPDATE === apiCode) {
            addLogChatUpdate(data);

            BrowserNoti.showChattingNotification(data)
            Chatting.drawTopNotReadCount();
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            return;
        }

        if (SocketAPI.MAIN.READ_ALL_MESSAGE_BY_ROOM === apiCode) {
            ListHelper.setCount($('#chatting-' + roomSrno).find('.not-read-count'), 0);
            Chatting.drawTopNotReadCount();
            ElectronApi.Comm.isElectron() && ElectronApi.Notification.hideNoti(roomSrno);
            return;
        }

        if (SocketAPI.MAIN.BADGE_UPDATE === apiCode) {
            handlerBadgeUpdate('mini-main', data);
            chatBadgeTracking(data);
            return;
        }

        if (SocketAPI.MAIN.ROOM_NAME_UPDATE === apiCode ||
            SocketAPI.MAIN.ALARM_N_PIN_UPDATE === apiCode ||
            SocketAPI.MAIN.DELETE_MESSAGE_MY_VIEW === apiCode ||
            SocketAPI.MAIN.DELETE_MESSAGE_ALL_VIEW === apiCode
        ) {
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            return;
        }

        if (SocketAPI.MAIN.BADGE_SELF_UPDATE === apiCode) {
            if (!Often.isFunc(Func.CLOUD.ALARM_COUNT_SOCKET)) return;
            AlarmUpdate.updateUnReadElementBySelf(data.ALARM_DATA);
            useDashboard('main', data);
            useMainPortal('main', data);
            return;
        }

        if (SocketAPI.MAIN.FOCUS_IN === apiCode) {
            $('body').attr('data-focus-room-srno', data.FOCUS_ROOM_SRNO);
            return;
        }

        if (SocketAPI.MAIN.FOCUS_OUT === apiCode) {
            $('body').attr('data-focus-room-srno', '');
            return;
        }

        //쪽지
        const isMessageFunc = Often.isFunc(Func.ENTER.MESSAGE_POPUP);
        if (isMessageFunc && SocketAPI.LETTER.UPDATE === apiCode) {
            Letter.showNotiAndDrawCount(data, false)
            return;
        }

        if (isMessageFunc && [SocketAPI.LETTER.READ, SocketAPI.LETTER.RETRIEVED_LETTER_DELETE, SocketAPI.LETTER.DELETE, SocketAPI.LETTER.DELETE_ALL].includes(apiCode)) {
            Letter.loadTopNotReadCount();
            return;
        }

        if (isMessageFunc && SocketAPI.LETTER.READ_CONFIRM === apiCode) {
            Letter.updateReadConfirm(data)
        }

        if (SocketAPI.MAIN.NOTDISTURB === apiCode) {
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_ALAM', data.SETTING_DATA.NOTDISTURB_ALAM);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_STIME', data.SETTING_DATA.NOTDISTURB_STIME);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_ETIME', data.SETTING_DATA.NOTDISTURB_ETIME);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'COMMT_ALAM', data.SETTING_DATA.COMMT_ALAM);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'FLOW_ALAM_YN', data.SETTING_DATA.FLOW_ALAM_YN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'CHAT_ALAM_YN', data.SETTING_DATA.CHAT_ALAM_YN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'LOCK_MODE_YN', data.SETTING_DATA.LOCK_MODE_YN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'LOCK_MODE_MIN', data.SETTING_DATA.LOCK_MODE_MIN);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'NOTDISTURB_DAY', data.SETTING_DATA.NOTDISTURB_DAY);
            LocalUtil.setLocalValue("ONLY_USER_SETTING", 'MESSAGE_ALAM_YN', data.SETTING_DATA.MESSAGE_ALAM_YN);
        }

        if (SocketAPI.MAIN.REMINDER_CHATBOT_RESPONSE === apiCode) {
            isShowChattingLayer && Chatting.updateChattingLayer(data);
            return;
        }

        if (SocketAPI.MAIN.UPDATE_ONLY_MANAGER_YN === apiCode) {
            isShowChattingLayer && Chatting.updateOnlyManagerYn(data);
            return;
        }
    }

    function sendStatusUpdate(data) {
        statusSocket.emit(data.TYPE, data);
    }

    function useDashboard(type, data) {
        const _dashboard = DashboardPro();
        if (!_dashboard) return;

        const {$state = {}} = _dashboard.widgetBoard;
        const modules = {
            main: [$state.myProject, $state.unread],
            mention: [$state.mention],
            chat: [$state.chat],
            gmail: [$state.gmail],
            gdrive: [$state.gdrive],
            gcalendar: [$state.gcalendar],
        };

        const selectedModules = modules[type] || [];

        selectedModules.forEach((module) => {
            if (module) {
                module.setup({
                    ...data,
                    isInit: true,
                    isDataDrivenRendering: true
                });
            }
        });
    }

    async function drawDashboard(type) {
        const _dashboard = DashboardPro();
        if (!_dashboard) return;

        const widgetNo = _dashboard.getWidgetNo(type)
        await _dashboard.drawWidget(widgetNo)
    }

    function useMainPortal(type, data) {
        const _dashboard = MainPortal();
        if (!_dashboard) return;

        const {$state = {}} = _dashboard.mainPortalBoard;
        const {myProject, unread, mention, chat} = $state;

        switch (type) {
            case 'main':
                if (myProject) myProject.setup({
                    ...data,
                    isInit: true,
                    isDataDrivenRendering: true
                });
                if (unread) unread.setup({
                    ...data,
                    isInit: true,
                    isDataDrivenRendering: true
                });
                break;
            case 'mention':
                if (mention) mention.setup({
                    ...data,
                    isInit: true,
                    isDataDrivenRendering: true
                });
                break;
            case 'chat':
                if (chat) chat.setup({...data, isDataDrivenRendering: true, isInit: true});
                break;
        }
    }

    async function loginSecurityCheck() {
        const logout = () => {
            Loading.drawLoadingPop();
            Often.logoutDirect();
        };
        const {ACCESS_YN} = await Ajax.executeApi("ACT_LOGIN_SECURITY_CHECK", {USE_INTT_ID: _USE_INTT_ID});
        if (ACCESS_YN !== "N") return;

        Mutil.confirm({
            text: {contents: i18next.t(common.security.alartSetLoginSecurityDesc),},
            display: {cancelBtn: false},
            callback: {submitFn: logout, cancelFn: logout,}
        });
    }

    function sendProjectLeaveSocket(projectSrno) {
        if (Mutil.empty(projectSrno)) {
            return;
        }

        Ajax.executeApi("COLABO_SENDIENCE_BACKUP_LIST_R001", {
            COLABO_SRNO: projectSrno,
        }, (dat) => {
            const {SENDIENCE_REC = []} = dat;
            SENDIENCE_REC.forEach((participant) => {
                const recevierId = participant.IN_RCVR_USER_ID;
                if (Mutil.empty(recevierId)) return true;
                sendMessage({
                    CHAT_CODE: SocketAPI.MAIN.DELETE_PROJECT_LEAVE,
                    ROOM_SRNO: recevierId,
                    COLABO_SRNO: projectSrno,
                });
            })
        })
    }

    function handlerBadgeUpdate(pageId = 'full-main', data) {
        const {FLOW_CNT, CHAT_CNT, LETTER_CNT, COLABO_SRNO, COLABO_COMMT_SRNO} = data || {};

        const projectAlarmCnt = +(FLOW_CNT ?? "0");
        const chatAlarmCount = +(CHAT_CNT ?? "0");
        const letterAlarmCount = +(LETTER_CNT ?? "0");
        const projectSrno = +(COLABO_SRNO ?? "-1");
        const postSrno = +(COLABO_COMMT_SRNO ?? "-1");

        const isAllRead = projectAlarmCnt === 0 && chatAlarmCount === 0 && letterAlarmCount === 0;
        const isAllAlarmReadByAllProject = projectAlarmCnt === 0 && projectSrno === 0;
        const isPostWriteAlarm = projectAlarmCnt > 0 && projectSrno > 0 && postSrno > 0;

        switch (pageId) {
            case 'mini-main' :
                if (isAllRead) {
                    ElectronApi.Notification.hideNoti();
                }

                if (+$("#allProjectTopAlarmCount").text() === projectAlarmCnt) {
                    //값이 값다면 플젝 알림 로직 스킵
                } else {
                    if (isAllAlarmReadByAllProject) {
                        MiniLeft.setProjectBadgeCount(0);
                    } else {
                        MiniLeft.setProjectBadgeCount();
                    }
                    if (!data.IS_ELECTRON_LOGIN && !Often.isFunc("BLOCK_PROJECT_ALARM_FROM_ELECTRON")) {
                        BrowserNoti.showAlarmNotification(data); //SHOW_YN === "Y"
                    }
                    if ($("#miniProject").is(":visible")) {
                        if (isPostWriteAlarm) {
                            AlarmUpdate.drawMiniNewUpdate();
                        } else {
                            //$("#alarmLayer").is(":visible") 일 경우
                            // 한개만 프로젝트의 카운트만 가져와서 갱신
                            MiniProject.initProject(true);
                        }
                    }
                }
                if (+$("#allChatAlarmCount").text() === chatAlarmCount) {
                    //값이 값다면 채팅 알림 로직 스킵
                } else {
                    Chatting.drawTopNotReadCount();
                }
                return;

            case 'full-main' :
                const chatCnt = +($("#chatTopCount").text().replace(/\D/g, ''));
                const flowCnt = +($("#alarmTopCount").text().replace(/\D/g, ''));
                if (data.CONTROL_CD === 'CHAT_DTL') {
                    // 값이 값다면 채팅 알림 로직 스킵 (999인 경우, cnt 가 1000으로 내려와서 무조건 갱신 됨)
                    if (chatCnt === chatAlarmCount) return;
                    useDashboard('chat');
                    useMainPortal('chat');
                    Chatting.drawTopNotReadCount();
                } else if (data.CONTROL_CD === 'COLABO_DTL' || data.CONTROL_CD === 'POST_DTL') {
                    // 값이 값다면 플젝 알림 로직 스킵 (999인 경우, cnt 가 1000으로 내려와서 무조건 갱신 됨)
                    if (flowCnt === projectAlarmCnt) return;
                    useDashboard('main', data);
                    useDashboard('mention', data);
                    useMainPortal('main', data);
                    useMainPortal('mention', data);
                    AlarmUpdate.updateUnReadElementBySocketReceive(data);
                }
                return;
            default:
                return;
        }
    }

    function addLogChatUpdate(data) {
        const roomChatSrno = +data.MSG_REC[0].ROOM_CHAT_SRNO;

        if (!Mutil.isFunc("CHAT_BADGE_TRACKING")) return;
        if (roomChatSrno === 0) return;
        addRoomLog(roomChatSrno, "USER0001");
    }

    function chatBadgeTracking(data) {
        const socketNotReadCnt = +data.CHAT_CNT;
        const roomChatSrno = +data.ROOM_CHAT_SRNO;
        const $chattingUl = $("#chattingUl");
        const isMini = ElectronApi.Comm.isElectron() && Mutil.isAct('miniMain');

        if (!Mutil.isFunc("CHAT_BADGE_TRACKING")) return;
        if (!Mutil.isFunc("FEATURE_LOG")) return;
        if (data.CONTROL_CD !== 'CHAT_DTL') return; // 채팅알림이 아닐경우 return
        if (socketNotReadCnt >= 999) return; // CHAT_CNT 가 999 이상일 경우 return
        if (roomChatSrno === 0) return; // room_chat_srno 안들어오면 return
        // if (!$chattingUl.is(":visible")) return; // 채팅리스트 안켜져 있으면 return

        const chatTotalBadgeCnt = +$(`#${isMini ? "allChatAlarmCount" : "chatTopCount"}`).text();
        const isTopCountEqualToSocketData = chatTotalBadgeCnt === socketNotReadCnt; // 1. top count 가 안맞는 경우

        addRoomLog(roomChatSrno, "USER0000");
        if (isTopCountEqualToSocketData) return; // 소켓으로부터 받은 CHAT_CNT 와 top count 가 같으면 return

        const props = {
            isHealthy: isTopCountEqualToSocketData,
            socketNotReadCnt,
            chatTotalBadgeCnt,
            chatListCnt: sumChattingUlCount(),
            isChatListVisible: $chattingUl.is(":visible"),
            socketData: data,
            tab_id: window?._TAB_ID,
            time_since_opened_in_seconds: OpenUtil.getLastOpenChat(),
            isMini: ElectronApi.Comm.isElectron() && Mutil.isAct('miniMain'),
            latestUser0001Time: window?.room_log,
        };

        insertFeatureLog(props);

        function sumChattingUlCount() {
            if (!$chattingUl.is(":visible")) return "";
            let totalNotReadCount = 0;
            $chattingUl.find(".not-read-count").each(function() {
                const count = +$(this).text();
                if (!isNaN(count)) {
                    totalNotReadCount += count;
                }
            });
            return totalNotReadCount;
        }

        function insertFeatureLog(props) {
            Ajax.executeApi("ACT_FEATURE_LOG_C001", {
                FEATURE_ID: 'CHAT_BADGE_TRACKING',
                DETAIL: JSON.stringify(props),
            })
        }
    }

    function addRoomLog(roomChatSrno, socketCode) {
        window.room_log = {
            ...window.room_log,
            [roomChatSrno]: [
                ...(window.room_log?.[roomChatSrno] || []),
                {[socketCode]: new Date(new Date().getTime() + 9 * 60 * 60 * 1000)}
            ]
        };

       if (Object.keys(window.room_log).length >= 10) {
           window.room_log = Object.fromEntries(
               Object.entries(window.room_log).slice(-5)
           );
       }
    }
})()
