var Time = (function () {
	return {
		add: add, // Addition
		tzApi: tzApi, // Change timeZone of API to local time
		tzrApi: tzrApi, // Change timeZone of API to Seoul time
		format: format, // 시간 타입만 변경
		isToday: isToday, // 입력된 날짜가 오늘인지 확인
		fromNow: fromNow, // 지금으로 부터 시간 확인 (알림에서 사용)
		subtract: subtract, // Subtraction
		dayjsObj: dayjsObj,
		localTime: localTime, // 현지 시간
		minToHour: minToHour, // Convert minutes to hour with internationalization
		formatAll: formatAll,
		formatDate: formatDate,
		isYesterday: isYesterday, //입력된 날짜가 내일인지 확인
		isThisWeek: isThisWeek, //입력된 날짜가 이번달인지 확인
		isThisMonth: isThisMonth, //입력된 날짜가 이번주인지 확인
		isSameDate: isSameDate,
		currentTime: currentTime, // 현재 시간 반환
		currentDate: currentDate, // 현재 시간 반환
		roundMinutes: roundMinutes, // 분 단위로 반 올림
		currentTimeZone: currentTimeZone, // 현재 타임존 지역
		commentTzApi: commentTzApi, //댓글 전용 tzApi
		shiftTzAsGMT: shiftTzAsGMT,
		tzrPreferenceTimezoneInArray: tzrPreferenceTimezoneInArray,
		getFormattedTimestamp: getFormattedTimestamp,
		generateGmtToIanaMap: generateGmtToIanaMap,
		getDefaultTimeLocale: getDefaultTimeLocale,
		tzrDtm: tzr,
		tzDtm: tz,
		diff: diff,
		startOf: startOf,
		endOf: endOf,
		toDate : toDate,
		tzByRegion: tzByRegion,
		localizeFormat: localizeFormat,
	}

	// Seoul -> Client Time
	function tz(time, format = reformatByLength(time), startTimezone = getDefaultTimeLocale(), endTimezone = currentTimeZone()) {
		if (!time || time.length < 9 || isNaN(time)) return time;
		const convert = dayjs.tz(reformatTime(time), startTimezone || getDefaultTimeLocale());
		let converted = convert.tz(endTimezone || currentTimeZone());
		return converted.format(format);
	}

	// Client Time -> Seoul
	function tzr(time, format = reformatByLength(time), endTimezone = getDefaultTimeLocale()) {
		if (!time || time.length < 9 || isNaN(time)) return time;
		return dayjs(reformatTime(time)).tz(endTimezone || getDefaultTimeLocale()).format(format)
	}

	function tzApi(json, target = '_DT', exception = "CONVT") {
		if (getDefaultTimeLocale() === currentTimeZone()) return json;
		return tzJson(json, target, exception);
	}

	function tzrApi(json, target = '_DT', exception = "CONVT") {
		if (getDefaultTimeLocale() === currentTimeZone()) return json;
		return tzJson(json, target, exception, true);
	}

	function shiftTzAsGMT(time, format = reformatByLength(time), startTimeZone, endTimeZone) {
		return dayjs.tz(dayjs(time).format("YYYYMMDDHHmmss"), startTimeZone).tz(endTimeZone).format(format);
	}

	function tzrPreferenceTimezoneInArray(array, startTimezone, endTimezone, target = '_DT', exception = "CONVT") {
		if (startTimezone === endTimezone) return array;
		return tzArray(array, target, exception, false, startTimezone, endTimezone);
	}

	function add(time = currentTime(), addition, unit = 'day', type = reformatByLength(time)) {
		return dayjs(time).add(addition, unit).format(localizeFormat(type))
	}

	function subtract(time = currentTime(), subtraction, unit = 'day', type = reformatByLength(time)) {
		return dayjs(time).subtract(subtraction, unit).format(localizeFormat(type))
	}

	function minToHour(minute) {
		if (minute < 60) return i18next.t(common.minute, {count: minute});
		else return i18next.t(common.hour, {count: Math.floor(minute / 60)});
	}

	function dayjsObj(time) {
		return dayjs(time);
	}

	function isToday(time = currentTime()) {
		return dayjs(time).isToday();
	}

	function isYesterday(time = currentTime()) {
		return dayjs(time).isYesterday();
	}

	function isThisWeek(time = currentTime()) {
		return dayjs(time) >= dayjs().add('-7', 'day') && dayjs(time) <= dayjs().add('+7', 'day')
	}

	function isThisMonth(time = currentTime()) {
		return dayjs().format(localizeFormat('type10')) === dayjs(time).format(localizeFormat('type10'))
	}

	function isSameDate(time, time2) {
		return dayjs(time).format(localizeFormat('type12')) === dayjs(time2).format(localizeFormat('type12'))
	}

	function diff(start, end, type) {
		return dayjs(end).diff(dayjs(start), type, true);
	}

	function startOf(time, unit, type = reformatByLength(time)) {
		return dayjs(time).startOf(unit).format(localizeFormat(type));
	}

	function endOf(time, unit, type = reformatByLength(time)) {
		return dayjs(time).endOf(unit).format(localizeFormat(type));
	}

	function toDate(time) {
		return dayjs(time).toDate();
	}

	function tzJson(json, target = '_DT', exception = 'CONVT', isReverse = false, startTimezone = '', endTimezone = '') {
		const result = {...json};
		for (let key in json) {
			const value = json[key];
			const isValueDttm = key.includes("VALUE") && value && value.length > 7;
			if ((isValueDttm || key.includes(target) || key.includes("_TIMESTAMP") || isValueDttm) && !key.includes(exception)) result[key] = isReverse ? tzr(value, reformatByLength(value), endTimezone) : tz(value, reformatByLength(value), startTimezone, endTimezone);
			if (Array.isArray(value)) result[key] = tzArray(value, target, exception, isReverse, startTimezone, endTimezone)
		}
		return result;
	}

	function tzArray(array, value, exception, isReverse, startTimezone = '', endTimezone = '') {
		const result = [];
		array.forEach(json => {
			result.push(tzJson(json, value, exception, isReverse, startTimezone, endTimezone));
		})
		return result;
	}

	function format(time = currentTime(), format = reformatByLength(time)) {
		if (!isValid(time)) return '';
		return dayjs(time).format(localizeFormat(format));
	}

	function formatAll(time = currentTime(), format = reformatByLength(time)) {
		return dayjs(time).format(localizeFormat(format));
	}

	function formatDate(date = currentDate(), format = reformatByLength(date)) {
		if (!isValid(date)) return '';
		return dayjs(date).format(localizeFormat(format));
	}

	function currentTime(format = 'YYYYMMDDHHmmss') {
		return dayjs().format(localizeFormat(format));
	}

	function currentDate(format = 'YYYYMMDD') {
		return dayjs().format(localizeFormat(format));
	}

	function getFormattedTimestamp(format = 'YYYYMMDD') {
		return dayjs().format(format);
	}

	function roundMinutes(minutesRoundBy) {
		const remainder = minutesRoundBy - (dayjs().minute() % minutesRoundBy);
		return dayjs()
			.add(remainder, "minutes")
			.format("HH mm ss");
	}

	function fromNow(time = currentTime()) {
		if (dayjs().diff(time, 'hours') < 21 && dayjs().diff(time, 'days') === 0)
			return dayjs(time).fromNow()

		return format(time, 'type1');
	}

	function currentTimeZone() {
		return dayjs.tz?.guess() || 'Asia/Seoul';
	}

	function localizeFormat(format) {
		const isRawType = !format.includes('type');
		return isRawType ? format : i18next.t(date.format[format]);
	}

	function reformatTime(time = '') {
		const timeLen = time.length;
		return timeLen === 14 ? time : (time + '00000000000000').substring(0, 14);
	}

	function reformatByLength(time = currentTime()) {
		if (!time) return 'YYYYMMDDHHmmss'
		switch (time.length) {
			case 8:
				return 'YYYYMMDD';
			case 12:
				return 'YYYYMMDDHHmm'
			case 16:
				return 'YYYY-MM-DD'
			default:
				return 'YYYYMMDDHHmmss'
		}
	}

	function isValid(val) {
		if (val === null || val === undefined) return false; // null 또는 undefined 체크
		if (typeof val === 'string' && val.trim() === '') return false; // 빈 문자열 체크
		if (!dayjs(val).isValid()) return false; // dayjs 날짜 유효성 검사

		return true; // 모든 조건을 통과하면 유효한 값
	}

	function localTime(zone = 'Asia/Seoul', format = 'YYYYMMDDHHmmss') {
		return dayjs().tz(zone).format(localizeFormat(format));
	}

	function commentTzApi(str) {
		const dateRegex = /'\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/g;
		const dtmRegex = /\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]),\s(0[1-9]|1[0-9]|2[0-4]):(0[1-9]|[0-5][0-9])/g;

		if (!dateRegex.test(str)) return str;
		const dtmArr = str.match(dtmRegex);
		if (dtmArr != null) {
			dtmArr.forEach(function (unformattedDtm) {
				let formattedDtm = formatDate(tz(unformattedDtm.replace(/[-:, \s]/g, '')), 'type25');
				str = str.replace(unformattedDtm, formattedDtm);
			});
		}

		const dateArr = str.match(dateRegex);
		if (dateArr != null) {
			dateArr.forEach(function (unformattedDtm) {
				unformattedDtm = unformattedDtm.replaceAll('\'', '');
				let formattedDtm = formatDate(unformattedDtm.replace(/[-:,\s]/g, ''), 'type24');
				str = str.replace(unformattedDtm, formattedDtm);
			});
		}
		return str;
	}

	function getDefaultTimeLocale() {
		return Often.isGlobal() ? "Atlantic/Reykjavik" : "Asia/Seoul";
	}

	function tzByRegion(time, region = "KR", format = reformatByLength(time)) {
		const TimeLocale = {
			"KR": "Asia/Seoul",
			"GB": "Europe/London",
			"US": "America/New_York",
			"JP": "Asia/Tokyo",
			"VN": "Asia/Ho_Chi_Minh",
			"KH": "Asia/Phnom_Penh",
			"ETC": "Asia/Seoul"
		}

		const isRegionExist = TimeLocale.hasOwnProperty(region);
		if (!time || time.length < 9 || isNaN(time) || !isRegionExist) return time;
		const convert = dayjs.tz(reformatTime(time), TimeLocale[region]);
		const converted = convert.tz(currentTimeZone())
		return converted.format(format);
	}

	function isSummerTimePeriod() {
		const lastThursDayOfApril = dayjs().month(4).startOf('month').day(4).subtract(1, 'week');
		const lastThursDayOfSeptember = dayjs().month(9).startOf('month').day(4).subtract(1, 'week');

		return dayjs().isAfter(lastThursDayOfApril) && dayjs().isBefore(lastThursDayOfSeptember);
	}

	//일렉트론 자체오류 잡기위한 로직.. 24버전 이상으로 업데이트 되면 로직 지워주세요!
	function isElectronDaylightDiff(currentTz) {
		//24버전은 윈도우 7,8 버전을 지원하지 않아서 현 플로우 일렉트론 버전 업데이트는 불가.
		//일렉트론 자체 오류고치기 위해서 강제로 한시간 뺴줌.. https://github.com/electron/electron/issues/37972
		//NOTE. 현재 깃허브 이슈로 올라와있는 것만 추가해줬는데 다른 타임존 필요하다면 추가해주세요~
		const timezonesWithDaylightSavingTime = [
			'America/Mexico_City', 'Africa/Cairo'
		];
		if (timezonesWithDaylightSavingTime.includes(currentTz) && isSummerTimePeriod()) {
			return true;
		}
		return false;
	}

	/**
	 * GMT 오프셋을 IANA 타임존으로 변환하는 매핑 생성
	 * @returns {Object} GMT 오프셋과 IANA 타임존 매핑 객체
	 */
	function generateGmtToIanaMap(offset) {
		const gmtOffsets = {
			"GMT-12:00": "Etc/GMT+12",
			"GMT-11:00": "Pacific/Midway",
			"GMT-10:00": "Pacific/Honolulu",
			"GMT-09:30": "Pacific/Marquesas",
			"GMT-09:00": "America/Anchorage",
			"GMT-08:00": "America/Los_Angeles",
			"GMT-07:00": "America/Denver",
			"GMT-06:00": "America/Chicago",
			"GMT-05:00": "America/New_York",
			"GMT-04:00": "America/Caracas",
			"GMT-03:30": "America/St_Johns",
			"GMT-03:00": "America/Argentina/Buenos_Aires",
			"GMT-02:00": "Atlantic/South_Georgia",
			"GMT-01:00": "Atlantic/Azores",
			"GMT+00:00": "UTC",
			"GMT+01:00": "Europe/London",
			"GMT+02:00": "Europe/Berlin",
			"GMT+03:00": "Europe/Moscow",
			"GMT+03:30": "Asia/Tehran",
			"GMT+04:00": "Asia/Dubai",
			"GMT+04:30": "Asia/Kabul",
			"GMT+05:00": "Asia/Karachi",
			"GMT+05:30": "Asia/Kolkata",
			"GMT+05:45": "Asia/Kathmandu",
			"GMT+06:00": "Asia/Almaty",
			"GMT+06:30": "Asia/Rangoon",
			"GMT+07:00": "Asia/Bangkok",
			"GMT+08:00": "Asia/Singapore",
			"GMT+09:00": "Asia/Seoul",
			"GMT+09:30": "Australia/Darwin",
			"GMT+10:00": "Australia/Sydney",
			"GMT+10:30": "Australia/Lord_Howe",
			"GMT+11:00": "Pacific/Noumea",
			"GMT+12:00": "Pacific/Fiji",
			"GMT+13:00": "Pacific/Tongatapu",
			"GMT+14:00": "Pacific/Kiritimati"
		};

		return gmtOffsets[offset] || getDefaultTimeLocale();
	}

})()
