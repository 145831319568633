var PasteText = (function () {

    /**
     * Todo
     * 1. 크롬 복사 => 크롬 붙여넣기 개행 이상없음
     * 2. 크롬 복사 => IE 붙여넣기 개행 이상없음
     * 3. IE 복사 => 크롬 붙여넣기 개행 2배 적용
     * 4. IE 복사 => IE 붙여넣기 개행 2배 적용
     *
     * IE에서 DIV 태그로 감싸진 태그를 복사할때 이상현상이 생기는 것으로 보임
     * IE일때만 전역에서 특수하게 처리해줘야할 것으로 보임
     *
     * Note
     * IE는 드래그앤드랍, 컨트롤씨브이 복사 모두 안 된다. 오직 텍스트만!
     * Copy 태그를 다 뗴도 좋지만, Paste는 태그를 떼서는 안 된다.
     * PPT와 EXCEL 복붙은 이미지와 텍스트 둘다 붙이게 만든다.
     * */

    return {
        pasteTextEvent: pasteTextEvent,
        getFromEvent : getFromEvent,
    }

    function getFromEvent (event) {
        const clipboardData = event.originalEvent.clipboardData || window.clipboardData;
        return clipboardData.getData('Text');
    }

    //Note.
    function pasteTextEvent(e) {
        var $eTarget = $(e.target);
        var $contentEditable = $eTarget.findUp("[contenteditable=true]");
        if ($contentEditable.length === 0) return;
        e.preventDefault();
        var clipBoardData = ClipBoard.getClipboardData(e);

        var isPostLayer = $contentEditable.closest("#postCntn").length > 0;
        var pastedText = clipBoardData.getData("text");

        if (Often.isBrowser("ie") ) {
            var tempHtml = TagConvert.html2HtmlStringByCopy(pastedText, isPostLayer);
            if(tempHtml.length === 0) return;
            pasteTextContentsByIE(tempHtml);
            return;
        }

        if (!clipBoardData.items || clipBoardData.items.length === 0) return;
        if (!document.queryCommandSupported('insertText')) return;
        document.execCommand('insertText', false, Mutil.convertOnCopy(pastedText));
        //document.execCommand('insertHTML', false, tempHtml);

        //Note. IE >= 9
        function pasteTextContentsByIE(html) {

            var sel = window.getSelection();
            const focusNode=  sel.focusNode;
            const isParentContentEditable = EditableUtil.isUpperTagContentEditable(focusNode);
            if (!(sel.getRangeAt && sel.rangeCount)) return;
            var range = sel.getRangeAt(0);
            range.deleteContents();

            var frag = document.createDocumentFragment();
            var el = document.createElement("div");
            el.innerHTML = Often.null2Void(html);

            var node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            if(isParentContentEditable) {
                range.insertNode(frag);
            } else {
                const tempNode = document.createTextNode("\u00A0");
                const pastedTxtNode = document.createTextNode($(html).text());
                const rangeWrapNode = range.commonAncestorContainer;
                range.insertNode(tempNode);
                rangeWrapNode.insertBefore(pastedTxtNode, tempNode);
            }


            if (!lastNode) return;
            if (Often.isLowIeBrowser()) {
                Often.toast("error", i18next.t(main.top.IEwarning));
                return;
            }

            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }

})();
