var MonitorUtil = (function () {

    return {
        isLimitMonitor: isLimitMonitor,
        load: load,
        loadGtm: loadGtm,
        loadGa: loadGa,
        loadSentry: loadSentry,
    }

    function getGtmKey() {
        // 구글 태그 매니저 - 운영 : GTM-PJMHRLW , 개발 : GTM-5rwln3j, 글로벌 : GTM-N2M3V36 (개발키X)
        const gtmKey = Often.isGlobal() ? "GTM-N2M3V36" : "GTM-PJMHRLW";
        const gtmDevKey = Often.isGlobal() ? "GTM-N2M3V36" : "GTM-5RWLN3J";
        return Often.isServerModeByHost("REAL") || Often.isServerModeByHost("REAL_TEST") ? gtmKey : gtmDevKey;
    }

    function getGaKey() {
        // 구글 애널리틱스
        // TODO: 글로벌 GA key 넣어야 함.
        const gaKey = Often.isGlobal() ? "GTM-N2M3V36" : "G-Y2LT9ZCH4Y";
        const gaDevKey = Often.isGlobal() ? "GTM-N2M3V36" : "G-MX97V2VT8H";
        return Often.isServerModeByHost("REAL") || Often.isServerModeByHost("REAL_TEST") ? gaKey : gaDevKey;
    }

    function isLimitMonitor() {
        if (window.ServerChecker && window.Often) {
            return ServerChecker.isEnter || ServerChecker.isInnerNetwork || (!window.isUserTracker && (!Mutil.isFunc("USER_TRACKER") || Mutil.isFunc("BLOCK_USER_TRACKER")))
        } else {
            return false;
        }
    }

    function load() {
        if (isLimitMonitor()) return;

        GoogleAnalytics.load(getGaKey());
        GoogleTagManager.initSetting(getGtmKey());

        KaKaoPixel.load();
        DaumTracking.load();
    }

    function loadGtm() {
        if (isLimitMonitor()) return;
        if (Often.isBrowser("ie")) return;

        const googleKey = getGtmKey();
        GoogleTagManager.initSetting(googleKey);
    }

    function loadGa() {
        if (isLimitMonitor()) return;
        if (Often.isBrowser("ie")) return;

        const googleKey = getGtmKey();
        GoogleAnalytics.load(googleKey);
    }

    function loadSentry() {
        SentryMonitor.load();
    }

})()
