var PasteFile = (function () {

    return {
        preventAll: preventAll,
        pasteFileEvent: pasteFileEvent,
        hideDimd: hideDimd,
        onUploadAreaPasteEvent: onUploadAreaPasteEvent,
        onUploadAreaDragEnterEvent: onUploadAreaDragEnterEvent,
        onDimdAreaDragOverEvent: onDimdAreaDragOverEvent,
        onDropEvent: onDropEvent,
    }

    /***
     * @author 임석현(sjsh1623@naver.com) , 장원종 수정
     * @description File Copy & Paste / Drag & Drop (유의 사항 dimdClass 에 -contenteditable="true"- 가 있어야 합니다
     *
     */

    function onUploadAreaPasteEvent(e) {
        var $eTarget = $(e.target);
        var isRemark = $eTarget.closest(".js-comment-area").length > 0;
        const isReply = $eTarget.closest(".js-reply-form").length > 0;
        var $editLayer = isReply ? $eTarget.parents(".js-reply-layer") : isRemark ? $eTarget.parents(".js-remark-layer") : $eTarget.closest(".js-popup-before");
        var $dimdLayer = $editLayer.find(".js-dimd-layer");
        if ($eTarget.is("input") || $eTarget.is("textarea")) return false;
        if ($dimdLayer.length === 0) {
            preventAll(e);
            return false;
        }
        pasteFileEvent(e, "paste");
        closeDimdLayer();
        return true;
    }

    function onUploadAreaDragEnterEvent(e) {
        // 인사이트탭에서 이벤트 떼버리던 부분 옮겨옴
        if (ViewChanger.getPageCode() === 'insight') {
            return
        }
        if (!isPasteFiles(e)) return;
        if (!Mutil.isBrowser('safari') && !isPasteFiles(e)) return;
        var $eTarget = $(e.target);
        const RapidClientPost = requireModule("RapidClientPost", { on : ["main"]})();
        var isTempPost = RapidClientPost?.checkTempPostArea($eTarget);
        if(isTempPost) return;
        var $popupBefore = $eTarget.closest(".js-popup-before");
        var dataCode = $popupBefore.attr("data-code");
        var isRemark = $eTarget.closest(".js-remark-layer").length > 0;
        const isReply = $eTarget.closest(".js-reply-form").length > 0;
        var $editLayer = $eTarget.closest(isReply ? ".js-reply-area" : isRemark ? ".js-remark-area" : ".js-popup-before");
        var $dimdLayer = isReply ? $editLayer.findUp('.js-reply-form').find('.js-dimd-layer') : isRemark ? $editLayer.siblings(".js-dimd-layer") : $editLayer.find(".js-dimd-layer");
        if (dataCode === "VIEW" && !isRemark && !isReply) return false;
        if ($dimdLayer.length === 0) return false;
        if (!$dimdLayer.is(":visible")) $dimdLayer.css("display", "block");
        return true;
    }

    function onDimdAreaDragOverEvent(e) {
        // 인사이트탭에서 이벤트 떼버리던 부분 옮겨옴
        if (ViewChanger.getPageCode() === 'insight') {
            return
        }

        var $eTarget = $(e.target);
        const RapidClientPost = requireModule("RapidClientPost", { on : ["main"]})();
        var isTempPost = RapidClientPost?.checkTempPostArea($eTarget);
        if(isTempPost) return;
        var $dimdLayer = $eTarget.closest(".js-dimd-layer")
        if ($dimdLayer.length > 0) return false;
        closeDimdLayer();
        return true;
    }

    function onDropEvent(e) {
        // 인사이트탭에서 이벤트 떼버리던 부분 옮겨옴
        if (ViewChanger.getPageCode() === 'insight') {
            return
        }

        const $eTarget = $(e.target);
        const RapidClientPost = requireModule("RapidClientPost", { on : ["main"]})();
        var isTempPost = RapidClientPost?.checkTempPostArea($eTarget);
        if(isTempPost) return;
        // check if remark
        const remarkLayer = $eTarget.closest('.js-remark-layer')
        const isRemark = remarkLayer.length > 0
        const isReply = $eTarget.closest(".js-reply-form").length > 0;
        // find dimdLayer
        const $editLayer = isReply ? $eTarget.parents(".js-reply-layer") : isRemark ? remarkLayer.find('.js-remark-area') : $eTarget.closest('.js-popup-before')
        const $dimdLayer = isRemark ? $editLayer.siblings('.js-dimd-layer') : $editLayer.find('.js-dimd-layer')

        // for safari browser
        const $pasteLayer = getPasteLayer($eTarget);

        if ($dimdLayer.length === 0 && (!$pasteLayer || $pasteLayer.length === 0)) {
            preventAll(e);
            return false;
        }

        pasteFileEvent(e, "drop")
        closeDimdLayer();
        return true;
    }

    function getPasteLayer($eTarget) {
        let $pasteLayer;

        const isSafari = Often.isBrowser().safari;
        if (isSafari) {
            const isRemark = $eTarget.closest(".js-remark-layer").length > 0;
            const isReply = $eTarget.closest(".js-reply-form").length > 0;
            $pasteLayer = $eTarget.closest(isReply ? ".js-reply-area" : isRemark ? ".js-remark-area" : ".js-popup-before");
        }

        return $pasteLayer;
    }

    function closeDimdLayer() {
        setTimeout(function () {
            $(".js-dimd-layer").css("display", "none");
        }, 0);
    }

    /**
     * @param e 이벤트
     * @param type paste/drag 여부 확인
     * @Description 파일,이미지 Drag & Drop/Copy & Paste
     * */

    function pasteFileEvent(e, type) {

        // 참고: clipBoardData에 텍스트와 파일 객체가 같이 넘어오는 경우도 있음 (ex. 엑셀 붙여넣기)
        var clipBoardData = ClipBoard.getClipboardData(e);
        if ("" === Often.null2Void(clipBoardData)) return;

        // 이미지 또는 파일 Paste일 경우 또는 drag & drop일 경우 prevent를 함
        preventAll(e);

        // Drag/Paste 여부 확인
        let fileData = getPasteFile(e, type);

        // browser check
        if (Often.isBrowser("ie")){
            const isText = clipBoardData.getData("Text") !== "";
            if (fileData.length > 0) {
                return limitEvent(type);
            } else if (isText) {
                return;
            }
        }

        // file check
        if (!fileData || fileData.length === 0) return;

        var blockedFiles = [];
        if (Often.isFunc(Func.CLOUD.FILE_EXTENSION_BLOCK)) {
            var useInttId = LocalUtil.getLocalValue("ONLY_USER_SETTING", "USE_INTT_ID");
            if ("DGBC_1" === useInttId || "DGBC_2" === useInttId) {
                fileData = fileExtensionFilter(fileData);
            }
        }

        var isMessenger = Often.isMessenger();
        if (isMessenger) {
            if($('#sendMessageButton').hasClass('disable')) return;
            var fileCount = fileData.length;
            var nameText = fileCount > 1 ? i18next.t(main.pasteFile.fileCount, {
                name: fileData[0].name,
                count: fileCount
            }) : fileData[0].name;
            if (FileUtil.isFolder(nameText)) return;
            if (FileUtil.isLimitOnMultiUpload(fileCount, true)) return;

            var imageCount = 0;

            //전송 팝업 이전에 사이즈 체크, 이미지 갯수 체크 선행
            for (var i = 0; i < fileData.length; i++) {
                if (FileUtil.isAvailableFileSize(fileData[i].size)) return;
                if (ImageUtil.isImageExtension(fileData[i].name) && ImageUtil.isImageSize(fileData[i].size)) imageCount++;
            }

            if (Often.isFunc(Func.CLOUD.CHAT_IMAGE_GROUP) && imageCount > 1
                && !Often.isFunc(Func.CLOUD.STORAGE_SPACE_CHECK)){
                return submitCallback();
            }

            if(Often.isFunc(Func.ENTER.DBFI_UPLOAD_AGREE)) {
                PopupDraw.drawConfirm({
                    title: "파일 업로드 관련 사전 동의",
                    contents: {
                        main: "대외비 문서 및 고객 개인/신용정보가 <br>포함된 일체의 문서는 업로드를 금합니다.",
                        submit: i18next.t('dictionary.confirm'),
                        cancel: i18next.t('dictionary.cancel'),
                    },
                    callback: {
                        submit : function() {
                            submitCallback();
                        }
                    }
                });
            } else {
                if(Often.isFunc(Func.CLOUD.STORAGE_SPACE_CHECK)){
                    let size = 0;
                    for (const file of fileData) {
                        size += file.size;
                    }

                    Ajax.executeApi('FLOW_STORAGE_LIMIT_CHECKER', {FILE_SIZE: size}, function (data) {
                        if (data.IS_STORAGE_AVAILABLE !== "true") {
                            PopupDraw.drawBlock({
                                class: "updata500",
                                seeAgain: "updata500",
                                contents: {
                                    title: i18next.t(main.alart.notAllowedStorageFull, {count : 500}),
                                    main: i18next.t(main.alart.upgradePlanForMoreStorage),
                                    submit: i18next.t(common.upgradeService),
                                },
                                linkUrl: Often.isGlobal() ? 'https://support.morningmate.com/articles/7206156' : '',
                                callback: {
                                    submit: () => {
                                        window.open(`${Often.getLocOrigin()}/main.act?stripePayment`, "_blank");
                                    }
                                }
                            })
                        } else {
                            if (Often.isFunc(Func.CLOUD.CHAT_IMAGE_GROUP) && imageCount > 1) return submitCallback();
                            messengerFileUploadPopup();
                        }
                    });
                } else {
                    messengerFileUploadPopup();
                }

            }

            function messengerFileUploadPopup () {
                MessengerImageGroup.drawFileUploadPopup({
                    fileList: fileData,
                    hideCheckBox: true,
                    successCallback: submitCallback,
                });
            }
            return;
        }

        if(Often.isFunc(Func.ENTER.DBFI_UPLOAD_AGREE)) {
            PopupDraw.drawConfirm({
                title: "파일 업로드 관련 사전 동의",
                contents: {
                    main: "대외비 문서 및 고객 개인/신용정보가 <br>포함된 일체의 문서는 업로드를 금합니다.",
                    submit: i18next.t('dictionary.confirm'),
                    cancel: i18next.t('dictionary.cancel'),
                },
                callback: {
                    submit : function() {
                        submitCallback(e);
                    }
                }
            });
        } else {
            submitCallback(e);
        }
        if (blockedFiles.length > 0) Often.toast("error", blockedFiles.toString() + " " + i18next.t(main.alert.uploadFolder));

	function fileExtensionFilter(files) {
                var availableFiles = {};
                var extensionTmp;
                var inputCount = 0;
                var regex = "\.(pdf|xlsx|xlsm|xlsb|xls|csv|xltx|xltm|xlt|docx|docm|doc|docs|dotx|dotm|dot|pptx|pptm|ppt|potx|potm|pot|ppsx|ppsm|pps|ppam|ppa|hwp|hwpx|hwt|txt|rtf|jpg|jpeg|jpe|png|tif|tiff|htx)$";


            for (var i = 0; i < files.length; i++) {
                if (!(new RegExp(regex, "i")).test(files[i].name)) {
                    blockedFiles.push(fileData[i].name);
                } else {
                    availableFiles[inputCount++] = files[i];
                }
            }

            availableFiles["length"] = inputCount;

            return availableFiles;
        }

        function submitCallback(e) {
            let type = "";
            let $remarkLayer;
            let $replyLayer;
            let $eTarget;
            let isRemark;
            let isReply;
            const RapidClientPost = requireModule("RapidClientPost", { on : ["main"]})();

            if (e && e.target) {
                $eTarget = $(e.target); // e.target을 사용해 jQuery 객체를 생성
                $remarkLayer = $eTarget?.closest(".js-remark-layer");
                $replyLayer = $eTarget?.closest(".js-reply-layer");
                isRemark = $remarkLayer.length > 0;
                isReply = $replyLayer.length > 0;

                if (isRemark || isReply) {
                    if (Often.isFunc("RAPID_CLIENT_REMARK")) {
                        type = "remark";
                        fileData.$target = isRemark ? $remarkLayer : $replyLayer;
                        fileData._is_reply = isReply;
                    }
                } else if (RapidClientPost?.isSupportPostFile()) {
                    type = "post";
                    fileData.$target = $eTarget.closest('.js-popup-before');
                }
            }

            Upload.putFilesAndSend(type, fileData, function (data, queueNumber) {

                if (isMessenger) return MessengerSend.sendFile(data, queueNumber);

                if (isReply) {
                    var isAdminAuthority = $remarkLayer.find(".js-remark-form").hasClass("admin");
                    if (Authority.isAdminAuthorityCheckAndAction(isAdminAuthority)) return;
                    ItemReply.drawUploadFileInReply(data, $replyLayer);
                    return;
                } else if (isRemark) {
                    var isAdminAuthority = $remarkLayer.find(".js-remark-form").hasClass("admin");
                    if (Authority.isAdminAuthorityCheckAndAction(isAdminAuthority)) return;
                    ItemRemark.drawUploadFile(data, $remarkLayer);
                    return;
                }

                /**
                 * Note. 에디터 iframe 영역에 그려야하기 때문에 콜백 분기 추가
                 */
                const $post = $eTarget.closest('.js-popup-before');
                const isEditor = $post.hasClass('ck-editor-wrap');
                if (isEditor && !Often.isFunc("RAPID_CLIENT_POST_WRITE")) {
                    const isImageType = ImageUtil.isImageType(data);
                    const commandName = isImageType ? "RENDER_IMAGE" : "RENDER_FILE";
                    FlowEditor.commands(commandName, {FILES: [data]});
                    return;
                }

                var offset = $eTarget.offset();
                var isRemoveTarget = $eTarget.is("br") && offset.top === 0 && offset.left === 0;

                PostAppend.appendFileOrImg((isRemoveTarget ? $("#postPopup").visible().find(".js-paste-layer") : $eTarget), data);
            }, finishCallback)
        }

        function finishCallback(queueFileNumber) {
            if (isMessenger) $("#message-" + queueFileNumber).find(".reload-wr").css("display", "none");
        }
    }

    function getPasteFile(e, type) {
        if (type === "drop") return e.originalEvent.dataTransfer.files;
        else if (type === "paste") return ClipBoard.getClipboardData(e).files;
        else return "";
    }

    function limitEvent(type) {
        const isPasteEvent = type === 'paste';
        if (isPasteEvent) {
            pasteLimitsToast();
        }
    }

    /**
     * paste 이벤트 제한 메시지
     */
    function pasteLimitsToast() {
        switch (true) {
            case Often.isBrowser("ie"):
                return Often.toast("error", i18next.t(main.alert.unPasteIE));
        }
    }

    function isPasteFiles(e) {
        var isFile = false;
        var clipboardData = ClipBoard.getClipboardData(e);
        if (!clipboardData) return false;
        if (!clipboardData.items) return false;
        if (clipboardData.items.length > 0) {
            $.each(clipboardData.items, function (i, v) {
                if (v.kind === "file") {
                    isFile = true;
                    return false;
                }
            })
        }
        return isFile;
    }

    function preventAll(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    /***
     *
     * @description 가독성을 위함
     * @param $eTarget
     */

    function hideDimd($eTarget) {
        var $dimdTarget = $eTarget.closest(".js-dimd-layer");
        var isDimdTarget = $dimdTarget.length > 0;
        var $visibleDimdLayer = $(".js-dimd-layer").visible();
        var isDimdLayer = $visibleDimdLayer.length > 0;
        if (!isDimdLayer && !isDimdTarget) return;
        closeDimdLayer();
    }
})
();
