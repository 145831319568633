var ScrapMetaData = (function () {
    var callback = null; //함수 내 콜백을 쉽게 사용하기 위함
    var $writingArea = null; //함수 내 작성하는 부분의 요소를 쉽게 사용하기 위함
    var counterID = null; //타임 아웃을 주기 위함
    var urlDuplicateCheck = 'Y';
    const MAX_PREVIEW_TITLE_LEN = 300;
    const MAX_PREVIEW_VIDEO_LEN = 500;
    const MAX_PREVIEW_IMG_LEN = 500;

    return {
        addUrlPreview: addUrlPreview,
        addMessengerUrlPreview: addMessengerUrlPreview,
        isOverWidthMetaImageByDbData: isOverWidthMetaImageByDbData,
    }

    /***
     * 글/ 댓글/ 채팅 작성 중 url 입력(keyup/paste)을 확인하여 입력한 url에 대한 메타 정보를 출력
     * @param {object} $writtingArea 글이 작성되는 부분 (REQUIRED)
     * @param {object} drawUrlPreviewCallBack MetaData를 가져온 후 실행될 함수 (REQUIRED)
     * @author 임석현 (sjsh1623@flow.team)
     */

    function addUrlPreview($writingAreaParam, drawUrlPreviewCallBack) {
        callback = drawUrlPreviewCallBack;
        $writingArea = $writingAreaParam;

        $writingArea.on('keydown', function (e) {
            // backSpace 했을때 불필요하게 작동을 방지하기 위함
            if (!KeyCheck.isKey(e, "ENTER")) return;
            if (hasFocusHyperLink()) return;
            var currentWord = getCurrentWord(e); // 현재 작성하고 있는 텍스트 가져옴
            drawUrlPreview(currentWord, e)
        });

        if (!Often.isMessenger()) {
            $writingArea.on("paste", (e) => {
                var url = $.trim(ClipBoard.getClipboardData(e).getData("text"));
                if (!isPassPasteUrl(url) || !isOnlyUrl(url)) return;
                /**
                 *
                 * CASE 1. 텍스트 처음에 링크를 넣는 경우
                 * CASE 2. 텍스트 끝에 링크를 넣는 경우
                 * CASE 3. 텍스트 중간에 링크를 넣는 경우
                 * CASE 4. 링크 처음에 링크를 넣는 경우
                 * CASE 5. 링크 끝에 링크를 넣는 경우
                 * CASE 6. 링크 중간에 링크를 넣는 경우
                 * CASE 7. 스타일 중간에 링크를 넣는 경우
                 *
                 */
                // 링크 중간에 넣는 경우 텍스트만 넣기
                if(isLinkNode()) return;

                stopEvent(e);
                insertBeforeLink(url);
                getMetaData(url);
            })
        }
    }


    function stopEvent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     *  링크 중간에 위치하는 지 확인
     */
    function isLinkNode() {
        const focusNode = window.getSelection().focusNode
        return $(focusNode).closest(".js-hyper-button").length > 0;
    }

    /**
     * 매개 변수로 받은 노드가 택스트 노드라면 그 부모 노드 반환
     * insertBefore() 함수는 테스트 노드가 사용할 수 없기때문
     */
    function getBeforeNode(node) {
        //Note. 연속적 paste 시 동일레벨에 a 태그가 생기도록 처리
        const TEXT_NODE = 3;
        return node.nodeType === TEXT_NODE ? node.parentNode : node;
    }

    /**
     * 링크 태그 넣기
     * @param url
     */
    function insertBeforeLink(url) {
        const $link = $(TagUtil.link2tag(url)).text(url); // url 내에 '&curren' 특수문자 치환 이슈
        const selection = window.getSelection();

        const range = selection.getRangeAt(0);
        const tempNode = document.createTextNode("\u00A0");
        const focusNode = selection.focusNode;

        // 드래그 영역이 텍스트 노드만일 경우 해당 텍스트 노드 , 다른 노드가 포함되어 있는 경우 부모 노드를 가리키고 있음
        const rangeWrapNode = range.commonAncestorContainer;
        const beforeNode = getBeforeNode(rangeWrapNode);

        //드래그 지우기
        range.deleteContents();

        //드래그 뒤에다가 공백 넣기 (드래그 영역이 지워지면 붙여 넣을 공간을 알기 위해)
        range.insertNode(tempNode);
        beforeNode.insertBefore($link[0], tempNode);
        if (EditableUtil.isParentsFirstDivContentEditable(focusNode)) {
            $(beforeNode).find("br").remove();
        }

        Caret.setLastFocusNode();
        Caret.focusNextCaret($link[0], 1);
    }

    function hasFocusHyperLink() {
        var $focusNode = $(window.getSelection().focusNode);
        return ($focusNode.closest(".js-hyper-button").length > 0);
    }

    function addMessengerUrlPreview(inputUrl, drawUrlPreviewCallBack, duplicateCheckYn) {
        urlDuplicateCheck = duplicateCheckYn;
        callback = drawUrlPreviewCallBack;
        drawUrlPreview(TagUtil.replaceLink(inputUrl));
    }

    /***
     * 메타데이터의 정보 출력 (NODE_URL_PREVIEW는 내부망일때 노드를 사용해 메타데이터를 가져온다)
     * @param {string} url 메타 데이터를 가져와야 하는 url (REQUIRED)
     * @param {object} callbackFunc 실행되어야 하는 콜백 함수 (OPTIONAL / 외부에서 호출시 REQUIRED)
     * @author 임석현 (sjsh1623@flow.team)
     */
    function getMetaData(url) {
        if (Often.isFunc("NODE_URL_PREVIEW")) {
            nodeUrlPrev(url)
        } else {
            regularUrlPrev(url);
        }
    }


    /**
     * ============================================================
     * ====================== 하위 Functions ======================
     * ============================================================
     * */


    /***
     * URL을 확인하여 Preview를 그려준다
     * @param element 사용자의 input
     * @param event 이벤트
     */
    function drawUrlPreview(element, event) {
        element = element.trim();
        if (!checkURL(element)) return; // 현재 작성하고 있는 텍스트가 URL인지 확인
        if (isSkipURL(element)) return;
        if (Validation.isGoogleDriveLink(element)) return;
        if (event) stopScrapEvent(event);
        if (!Often.isMessenger() && !Often.isForwardContents()) {
            Caret.changeFocusNode2Link(element);
            Caret.setLastFocusNode();
        }
        getMetaData(adjustURL(element));
    }

    function isPassPasteUrl(url) {
        if (!checkURL(url) || isSkipURL(url)) return false;
        return true;
    }

    function isOnlyUrl(url) {
        const text = url.replace(getUrlPatten(), "");
        return text.length === 0;
    }

    function stopScrapEvent(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    function isSkipURL(url) {
        return UrlCatcher.isPostUrlRegex(url) || UrlCatcher.isMessengerPrivateUrl(url);
    }

    /***
     * NODE_URL_PREVIEW 기능키가 켜져 있을때 (내부망일때 노드를 사용해 메타데이터를 가져온다)
     * @param url 메타 데이터를 가져와야 하는 url
     * @author 임석현 (sjsh1623@flow.team)
     */
    function nodeUrlPrev(url) {
        var sendObj = {};
        var roomSrno = SocketControl.getRoomConnectId();
        const flowLang = Often.getCookie('FLOW_LANG');
        const windowLang = window.navigator.language;
        sendObj["ROOM_SRNO"] = roomSrno;
        sendObj["URL"] = scrapUrlConversion(url);
        sendObj['HEADERS'] = {'accept-language': Often.null2Void(flowLang,windowLang), 'user-agent': 'Mozilla'};
        SocketControl.getSocket().emit('sendURL', sendObj);
        SocketControl.getSocket().off("receiveMetadata").on("receiveMetadata", receiveMetadataEvent);

        function receiveMetadataEvent(res) {
            //TODO : www.nate.com, www.coupang.com CHECK
            //console.log(res, res.data, Object.keys(res.data).length)
            //res로 내려올때가 있고 res.data로 감싸져서 내려올때가 있어서 체크
            var returnData = res.data === undefined ? res : res.data;
            adjustMetaData(url, returnData, true);
        }
    }


    /***
     * 메타데이터를 가져오는 일반적인 방법
     * @param url {string} 메타 데이터를 가져와야 하는 url
     * @author 임석현 (sjsh1623@naver.com)
     */
    function regularUrlPrev(url) {
        var metaDataUrl = '/url_scrap.act?URL=';
        metaDataUrl += encodeURIComponent(url);
        var metaData = "";
        var req = new XMLHttpRequest();
        req.open('GET', encodeURI(metaDataUrl), true);
        req.onreadystatechange = function (e) {
            if (req.readyState === 4 && req.status === 200) {
                metaData = JSON.parse(req.response.trim());
                adjustMetaData(url, metaData);
            }
        };
        req.send(null);
    }


    /***
     * 메타 데이터를 가져와 콜백 함수를 실행 시킴
     * @param url 메타 데이터의 제목이 없을 경우 url 경로로 대체 하기 위함
     * @param metaData 호출한 메타데이터
     * @param isNode node로 호출이 되었는지 여부
     * @return metaData가 존재 하지 않을때 리턴
     */
    function adjustMetaData(url, metaData, isNode) {
        if ($.isEmptyObject(metaData)) return;
        var title = isNode ? Often.null2Void(metaData.ogTitle, url) : Often.null2Void(metaData.title, url);
        title = title.substring(0, MAX_PREVIEW_TITLE_LEN);

        var link = url;
        var cntn = isNode ? metaData.ogDescription : metaData.description;

        //json Object 의 Depth가 2 이상이여서 null check
        var img, video;
        if (isNode) {
            img = getMetaUrl(metaData, "IMAGE");
            video = getMetaUrl(metaData, "VIDEO");

        } else {
            img = metaData.image;
            video = Often.null2Void(metaData.video_url, "");
        }
        // 내용/ 이미지/ 비디오 3가지 모두 없다면 출력하지 않음
        if (isEmpty([title, cntn, img, video])) return;

        const isStartSlashImage = img?.startsWith("/");
        if (isStartSlashImage) {
            const endSliceUrl = (url = '') => url.slice(0, -1);
            const isEndSlashUrl = url.endsWith("/");
            const imageUrl = (isEndSlashUrl ? endSliceUrl(url) : url);
            img = imageUrl + img;
        }

        const ogImageUrl = Often.convertExceptionalOpenGraphImageUrl(img)
        var data = {
            PREVIEW_TTL: title,
            PREVIEW_LINK: link,
            PREVIEW_IMG: ogImageUrl,
            PREVIEW_CNTN: cntn ?? link,
            PREVIEW_VIDEO: video,
            PREVIEW_GB: (isOverWidthMetaImage(metaData) ? "size1" : "size2"),
        };
        (typeof callback === "function") && callback(data);
    }


    function getUrlPatten() {
        return TagUtil.getUrlRegexp();
    }

    /***
     * url인지 체크
     * @param 현재 작성중인 텍스트
     * @return url인지 판단하여 Boolean 타입 리턴
     * @author 임석현 (sjsh1623@flow.team)
     */
    function checkURL(text) {
        var regexp = getUrlPatten();
        return regexp.test(text);
    }


    /***
     * http/https가 없는 url일 경우 포함된 url 을 만들어준다
     * @param url 사용자가 적은 URL
     * @return 사용가능한 URL
     * @author 임석현 (sjsh1623@flow.team)
     */
    function adjustURL(url) {
        var cleanUrl = url.trim();
        var regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/i;
        var result = !regexp.test(url) ? 'http://' + cleanUrl : cleanUrl;
        return result;
    }


    /***
     * URL 중복됨을 막기 위해 현재 preview 처리된 URL 리스트를 찾아 출력
     * @return getCurrentUrlList preview가 있는 URL 리스트
     * @author 임석현 (sjsh1623@flow.team)
     */
    function getCurrentUrlList() {
        var $popupBefore = $writingArea.parents(".js-popup-before").visible();
        var currentUrlList = [];
        var currentURL = $popupBefore.find('.link-item');
        for (var i = 0; i < currentURL.length; i++) {
            currentUrlList.push(currentURL[i].getAttribute('preview_link'));
        }
        return currentUrlList;
    }


    /***
     * 현재 사용자가 작성하고 있는 단어를 찾아 출력한다.
     * @param event 해당 이벤트
     * @return List 현재 작성하고 있는 단어룰 출력한다
     * @author 임석현 (sjsh1623@flow.team)
     */
    function getCurrentWord(event) {
        // 현재 작성하고 있는 줄을 텍스트 형식으로 가져옴
        var currentLineText = TagUtil.replaceLink(getNodeData());

        // 작성하고 있는 줄의 단어들을 Array로 만들기 위해 split (없다면 빈 array를 출력)
        var currentLineList = Often.null2Void(currentLineText, "") === "" ?
            [] : currentLineText.substring(0, window.getSelection().focusOffset).split(" ");
        // Array의 가장 마지막 요소를 출력
        return Often.null2Void(currentLineList[currentLineList.length - 1], "");

        function getNodeData() {
            try {
                return window.getSelection().focusNode.data;
            } catch (e) {
                return "";
            }
        }
    }

    /**
     * URL 중복 확인
     * @param url 중복 체크가 필요한 url
     */
    function checkDuplicate(url) {
        if (urlDuplicateCheck === 'N') return false;
        var currentUrl = getCurrentUrlList();
        var pureUrl = getPureUrl(url);
        for (var i = 0; i < currentUrl.length; i++) {
            var pureCurrentUrl = getPureUrl(currentUrl[i]);
            if (pureUrl.indexOf(pureCurrentUrl) > -1 || pureCurrentUrl.indexOf(pureUrl) > -1) return true;
        }
        return false;
    }

    /**
     * URL 주소 host 확인
     * @param url 추출해야하는 URL
     * @return url host
     * @description URL 중복 확인중 같은 URL임에도 불구하고 https 와 http가 달라 다른 URL로 인식하여 같은 URL이 출력됨
     */

    function getPureUrl(url) {
        return new URL(url).host;
    }

    /**
     * 모든 value가 Empty인지 확인
     * @param {array} values 체크해야 할 값
     **/

    function isEmpty(values) {
        for (var i = 0; i < values.length; i++) {
            if (!(values[i] === undefined || values[i] === "")) return false
        }
        return true
    }

    /**
     * @param metaData Scrap 데이터
     * @param type video or image 타입
     * @returns {string} url
     */
    function getMetaUrl(metaData = {}, type) {
        var isEmptyData;
        var isArray;
        var ogType = metaData.ogType ? metaData.ogType : metaData.ogImage ? metaData.ogImage.type : false;
        var isVideo = ogType && ogType.indexOf("video") > -1;
        if ("VIDEO" === type && isVideo) {
            var jsonData = Often.undefined2Obj(metaData.ogVideo, metaData.ogImage);
            isArray = Array.isArray(jsonData);
            isEmptyData = Often.null2Void(jsonData).length === 0;
            const video =  isEmptyData ? "" : isArray ? jsonData[0].url : jsonData.url;
            return Mutil.n2v(video, '').substring(0 , MAX_PREVIEW_VIDEO_LEN);
        } else if ("IMAGE" === type) {
            isArray = Array.isArray(metaData.ogImage);
            isEmptyData = Often.null2Void(metaData.ogImage).length === 0;
            const img =   isEmptyData ? "" : isArray ? metaData.ogImage[0].url : metaData.ogImage.url;
            return Mutil.n2v(img, '').substring(0 , MAX_PREVIEW_IMG_LEN);
        }
    }

    /**
     * @param metaData Scrap 데이터
     */
    function isOverWidthMetaImage(metaData) {
        var isEmptyData = Often.null2Void(metaData.ogImage).length === 0;
        var isArray = Array.isArray(metaData.ogImage);
        var naturalWidth = (isEmptyData ? "" : isArray ? metaData.ogImage[0].width : metaData.ogImage.width);
        return naturalWidth > 400;
    }

    function isOverWidthMetaImageByDbData(linkData) {
        return ("" !== linkData.PREVIEW_IMG && (linkData.PREVIEW_GB === "size1" || linkData.PREVIEW_GB === "video"));
    }

    /**
     *
     * @param url 주소
     * @description 특정 url 주소를 scrap 하기 위해서 conversion
     */
    function scrapUrlConversion(url) {
        var bitLyUrl = ":\/\/bit.ly";   // 단축 url 주소
        var gooGlUrl = ":\/\/goo.gl";   // 단축 url 주소

        if ((url.indexOf(bitLyUrl) > -1) || (url.indexOf(gooGlUrl) > -1)) url += "!"; // 단축 url > 원본 url에 대한 데이터

        return url;
    }

})();
