const GooglePeopleDirectory = function () {
    const EXT_SERVICE_NM_CAMEL = "GooglePeopleDirectory";
    const EXT_SERVICE_NM_SNAKE = "GOOGLE_PEOPLE_DIRECTORY";

    return {
        OAuthApiLoad: OAuthApiLoad, // 구글 연락처, 워크스페이스 연동
        disconnectIntegration: disconnectIntegration, // 구글 연락처, 워크스페이스 연동 해제
        getGooglePeopleUserList:  getGooglePeopleUserList, // 구글 연락처 데이터 가져오기
        getGoogleDirectoryUserList: getGoogleDirectoryUserList, // 구글 워크스페이스 데이터 가져오기
        isIntegrated: isIntegrated, // 구글 연락처, 워크스페이스 연동 여부 체크
    }

    function OAuthApiLoad(userId) {
        Ajax.executeApi(RestApi.POST.FLOW_GOOGLE_AUTH_C001, {
            "EXT_SERVICE_NM" : EXT_SERVICE_NM_SNAKE,
            "USER_ID": userId
        }, (data) => {
            // 회원가입 화면이면 소켓 연결
            if (Often.isAct("newSignup") || Often.isAct("inviteEmpl")) {
                SocketControl.connectSocket();
            }

            // 모바일 사파리의 경우 임의로 새로운 window 를 열어줄 수 없음
            const userAgent = window.navigator.userAgent;
            if ((userAgent.indexOf('Mobile') !== -1) && (userAgent.indexOf('Safari') !== -1)) {
                $('.js-integrated-btn').off('click').on('click', () => {
                    window.open(data.CONNECT_URL, "_blank", "width=500px,height=700px,top=100,left=100");
                })
                return;
            }

            window.open(data.CONNECT_URL, "_blank", "width=500px,height=800px,top=100,left=100");
        }, () => {
            disconnectIntegration();
        });
    }

    function disconnectIntegration() {
        Ajax.executeApi(RestApi.DELETE.FLOW_EXT_SERVICE_D001, {"EXT_SERVICE_NM": EXT_SERVICE_NM_CAMEL});
        Ajax.executeApi(RestApi.DELETE.FLOW_GOOGLE_AUTH_D001, {"EXT_SERVICE_NM": EXT_SERVICE_NM_SNAKE});
        LocalUtil.setLocalValue("ONLY_USER_INTEGRATION", 'GooglePeopleDirectory', 'N');
    }

    function getGooglePeopleUserList(props, successCallback = Mutil.noop, errorCallback = Mutil.noop) {
        const {userId = "", pageToken, query} = props;
        if (userId === '') return;
        Ajax.executeApi("GPEOPLE_LIST", {
            packetOption: Ajax.OPTION.PREVENT_EXECUTE,
            'USER_ID': userId,
            'PAGE_TOKEN': pageToken,
            'QUERY': query,
        }, successCallback, errorCallback);
    }

    function getGoogleDirectoryUserList(props, successCallback = Mutil.noop, errorCallback = Mutil.noop) {
        const {userId = "", pageToken, query} = props;
        if (userId === '') return;
        Ajax.executeApi("GDIRECTORY_USER_LIST", {
            packetOption: Ajax.OPTION.PREVENT_EXECUTE,
            'USER_ID': userId,
            'PAGE_TOKEN': pageToken,
            'QUERY': query,
        }, successCallback, errorCallback);
    }

    async function isIntegrated() {
        let errCd;
        await Ajax.executeApi(RestApi.POST.FLOW_GOOGLE_AUTH_R001, {
            "EXT_SERVICE_NM": EXT_SERVICE_NM_SNAKE,
        }, (data) => {
            errCd = data.ERR_CD;
        });

        return Mutil.n2v(errCd, '') !== 'ERR_1';
    }
}();
