var Internationalization = (() => {
    const initSetting = (callback) => {
        if (Often.isGlobal()) IntercomHelper.connectIntercom();
        const isLibraryApplied = !isEmpty(i18next.options);
        if (isLibraryApplied) return detectI18next(callback)
        languageCookieInit();
        i18nextBackEnd().then(config => {
            i18next.use({
                type: 'postProcessor',
                name: 'capitalizeFirstLetter',
                process: (value, key, options, translator) => {
                    if (translator.language?.includes('en') && (Array.isArray(key) && key.length > 0 && !key[0].includes('date:') && !key[0].includes('help:'))) {
                        return value.charAt(0).toUpperCase() + value.slice(1);
                    }
                    return value;
                }
            })
                .use(i18nextHttpBackend)
                .init(i18nextSettingOptions(config))
                .then(function () {
                    initDayjs()
                    preI18next();
                    (typeof callback !== "undefined") && callback();
                }).catch(function (error) {
                (typeof callback !== "undefined") && callback();
                if (error && Often.isServerModeByHost("DEV_TEST")) {
                    // 에러 처리 및 개발 전용 로그
                    console.error("Error has been made in flow internationalization")
                }
            });
        })

    }

    const asyncInitSetting = () => {
        return new Promise((resolve) => {
            const addDayjsPlugin = (plugin) => plugin && dayjs.extend(plugin)
            addDayjsPlugin(window.dayjs_plugin_utc);
            addDayjsPlugin(window.dayjs_plugin_timezone);
            addDayjsPlugin(window.dayjs_plugin_relativeTime);
            addDayjsPlugin(window.dayjs_plugin_duration);
            addDayjsPlugin(window.dayjs_plugin_isToday);
            addDayjsPlugin(window.dayjs_plugin_isYesterday);
            addDayjsPlugin(window.dayjs_plugin_isoWeek);
            addDayjsPlugin(window.dayjs_plugin_weekOfYear);
            initSetting(resolve);
        })
    }

    const i18nextSettingOptions = (config) => {
        let settingOptions = {
            lng: getInitSetLanguage(),
            debug: Often.isServerModeByHost("DEV_TEST"),
            fallbackLng: 'en_uk',
            fallbackNS: "temp",
            cleanCode: true,
            postProcess: ["capitalizeFirstLetter"],
            interpolation: {
                format: function (value, format, lng) {
                    if (format === 'lowerCase') return Interpolation.validateI18nextCode(value);
                    if (format === 'koreanPrefixEL') return Interpolation.koreanPrefix(value, "을", "를");
                    if (format === 'koreanPrefixIG') return Interpolation.koreanPrefix(value, "이", "가");
                    if (format === 'koreanPrefixEN') return Interpolation.koreanPrefix(value, "은", "는");
                    if (format === 'pastTense') return Interpolation.getPastTense(value);
                    if (format === 'name') return Interpolation.addName(value);
                    if (value instanceof Date) return dayjs(value).format(format);
                    return value;
                }
            },
            backend: config
        }

        // main.act 고도화 후 해당 부분 수정
        settingOptions.ns = i18nextNameSpace();
        return settingOptions;
    }

    const i18nextNameSpace = () => {
        const urlPath = window.location.pathname;
        const defaultNS = ['common', 'dictionary', 'date', 'exception', 'language'];
        if (urlPath.includes("master")) {
            defaultNS.push('master');
        } else if (urlPath.includes("adm")) {
            defaultNS.push('admin');
            defaultNS.push('help');
        } else {
            switch (urlPath) {
                case "/flow_admin.act":
                case "/flow_adm_user.act":
                    defaultNS.push('admin');
                    defaultNS.push('help');
                    break;
                case "/okr.act":
                    i18nextLoadAfter();
                    defaultNS.push('okr');
                    defaultNS.push('main');
                    break;
                case "/messenger.act":
                    defaultNS.push('main');
                    defaultNS.push('help');
                    defaultNS.push('alarm');
                    break;
                case "/signin.act":
                case "/signup.act":
                case "/entsignin.act":
                case "/entMiniSignIn.act":
                case "/newSignup.act":
                case "/newsignup.act":
                case "/joinwait.act":
                case "/paidSignup.act":
                case "/corpsignin.act":
                case "/corpsignup.act":
                case "/miniSignIn.act":
                    defaultNS.push('main');
                    defaultNS.push("login");
                    defaultNS.push('invite');
                    break;
                case "/subscreen.act":
                    defaultNS.push('main');
                    defaultNS.push('help');
                    defaultNS.push('alarm');
                    defaultNS.push('electron');
                    break;
                case "/flow_partner.act":
                    defaultNS.push('master');
                    defaultNS.push('admin');
                    break;
                case "/flow_admin_master.act":
                case "/global_weekly_payout_dashboard.act":
                    defaultNS.push('master');
                    break;
                case "/inviteEmpl.act":
                    defaultNS.push('login');
                    break;
                default:
                    defaultNS.push('main');
                    defaultNS.push('help');
                    defaultNS.push('electron');
                    defaultNS.push('alarm');
                    break;
            }
        }
        if (urlPath.indexOf('/master') > -1) { //jp/master us/master ... etc
            defaultNS.push('master');
        }
        return defaultNS;
    }

    // main.act 고도화 후 해당 부분 수정
    // 어드민 적용 및 고도화를 위한 PATH 분기
    const i18nextBackEnd = async () => {
        //basePath (CF) or orgin
        let primaryBasePath = window.basePath || location.origin;
        let primaryPath = `${primaryBasePath}/flow-renewal/language-pack/{{lng}}/{{ns}}.json`;
        //로컬에는 무조건 검증된 파일 있다고 가정
        let fallbackPath = `${location.origin}/flow-renewal/language-pack/{{lng}}/{{ns}}.json`;
        let isOk = true;
        // Crowdin CF
        if (!!window.langPack_basePath && !!window.langPack_version && Often.isFunc("CROWDIN_CDN")) {
            primaryBasePath = `${window.langPack_basePath}/${window.langPack_version}`;
            const nsArr = i18nextNameSpace();
            const lang = getCurrentLanguage();
            try {
                const fetchPromises = nsArr.map(ns => {
                    const testPath = `${primaryBasePath}/${lang}/${ns}.json`;
                    return fetch(testPath)
                        .then(response => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                throw new Error(`Failed to load ${ns}.json`);
                            }
                        });
                });
                await Promise.all(fetchPromises);
                primaryPath = `${primaryBasePath}/{{lng}}/{{ns}}.json`;
            } catch (error) {
                isOk = false;
                console.error("Error during JSON files validation:", error);
            }
        }
        try {
            if (!isOk) throw new Error('Failed to load from primary path');
            return {
                loadPath: primaryPath,
                queryStringParams: {ver: Often.null2Void(window.version, "3.1." + Time.currentTime("HHmm"))},
            };
        } catch (error) {
            return {
                loadPath: fallbackPath,
                queryStringParams: {ver: Often.null2Void(window.version, "3.1." + Time.currentTime("HHmm"))},
            };
        }
    }

    const i18nextLoadAfter = () => {
        const baseURL = window.basePath || location.origin;
        const supportLanguage = ['en', 'ko', 'vi', 'jp'];
        for (const language of supportLanguage) {
            $.getJSON(baseURL + '/flow-renewal/language-pack/message_' + language + '.json', (json) => {
                i18next.addResourceBundle(language, 'temp', json)
            })
        }
    }

    // Date Picker Plugin Localization
    // i18next 처리 불가
    const initDatePicker = () => {
        // 영어, 한국어, 일본어
        // flow-renewal/js/lib/flatpickr/ja.js <- 경로 참고
        Mutil.lib.getFlatpickr().then(() => {
            flatpickr.localize(flatpickr.l10ns[getCurrentLanguage()]);
        })
    }

    const changeLangTemporarily = (language, callback) => {
        i18next.changeLanguage(language).then(callback);
    }

    const initDayjs = () => {
        dayjs.locale(getCurrentLanguage());
    }

    const languageCodeDebug = () => {
        // 다국어 코드 출력
        if (Often.isFunc("DEBUG_LANG_CODE")) {
            $.getScript("/flow-renewal/js/lib/JSZip/jszip.min.js");
            $.getScript("/flow-renewal/module/lib/excel/FileSaver.js");
            loadScript(() => window.XLSX, 'excel/xlsx.style.min.js').then(() => {
                mutiLangDebug.activate();
            });
        } else {
            mutiLangDebug.remove();
        }
    }

    const loadScript = (getValueCallback, src) => {
        if (src.includes("https://")) {
            //skip
        } else {
            src = window.basePath + '/flow-renewal/module/lib/' + src + "?ver=" + (window.version || '');
        }

        return new Promise((resolve, reject) => {
            const val = getValueCallback();
            if (val) {
                resolve(val);
            }

            const s = document.createElement('script');
            let r = false;
            s.type = 'text/javascript';
            s.src = src;
            s.async = true;
            s.onerror = (err) => reject(err, s);
            s.onload = s.onreadystatechange = function () {
                if (!r && (!this.readyState || this.readyState === 'complete')) {
                    r = true;
                    resolve(getValueCallback());
                }
            };
            const t = document.getElementsByTagName('script')[0];
            t.parentElement.insertBefore(s, t);
        });
    }

    const getCurrentLanguage = (acceptLang = window._CF_LANG) => {
        const currentLang = unsupportedLangVerification(Often.null2Void(Often.getCookie("FLOW_LANG"), acceptLang)) //Often.null2Void(Often.getCookie("FLOW_LANG"), acceptLang);
        return !!currentLang ? currentLang : window._CF_LANG;
    }

    /**
     * @description 오픈되지 않은 언어 검수
     * @param {string} flowLang
     * @returns {string}
     */
    const unsupportedLangVerification = (flowLang) => {
        let lowerCaseFlowLang = flowLang.toLowerCase();
        const defaultLang = Often.isGlobal() ? "en_uk" : "ko";

        const checkLanguageIsFuncOpen = {
            fr: "FR",
            de: "DE",
            zh_cn: "CN_SIMPLE",
            zh_tw: "CN_TRADITION"
        };

        const langCodeFuncName = checkLanguageIsFuncOpen[lowerCaseFlowLang];

        if (langCodeFuncName && !Often.isFunc("BETA_LANG_" + langCodeFuncName)) {
            lowerCaseFlowLang = defaultLang;
        }

        return lowerCaseFlowLang;
    };

    const getInitSetLanguage = () => {
        return getCurrentLanguage();
    }

    const preI18next = () => {
        $('#priorityButton').append(i18next.t(common.add, {val: $t(common.priority)}));
        $('#videoButton').append(i18next.t(common.add, {val: $t(common.videoConference)}));
        $('#addEmail span').text(i18next.t(common.add, {val: $t(dictionary.email)}));
    }

    const languageCookieInit = () => {
        // CLOUD에서 없을 경우는 거의 없다. 그래도 없을 경우 한국어 출력
        // HOMEPAGE FILTER에서 DEFAULT 처리.
        const isElectron = window.ElectronProcessApi
        let currentLang = Often.getCookie('FLOW_LANG')

        // 일렉트론은 부팅 후 최초 일회만 i18 init 언어 설정
        if (isElectron) {
            const langCode = window.ElectronProcessApi.getConfig({key: "FLOW_LANG"})
            const needConvert = Often.electronIsSmallerThanInputVersion({mode: 'en_ukComparator'})
            Often.electronSetFlowlangToElectronConfig({langCode, needConvert});
            if (currentLang === "") {
                storeLanguage();
                currentLang = Often.getCookie('FLOW_LANG')
            }
        }

        if (!isElectron) {
            if (currentLang === 'en') {
                //@김승윤 en -> en_uk 변경됌
                Often.setCookie('FLOW_LANG', 'en_uk', 30 * 12 * 10);
            } else if (!currentLang) {
                storeLanguage();
            } else {
                Often.setCookie('FLOW_LANG', unsupportedLangVerification(currentLang), 30 * 12 * 10);
            }
        } else if (currentLang === 'en') {
            Often.setCookie('FLOW_LANG', 'en_uk', 30 * 12 * 10);
        }
    }

    const retrieveUserLanguage = (Language = getCurrentLanguage(), isBulk = true) => {
        if (Often.isFunc("RETRIEVE_USER_LANGUAGE")) {
            Ajax.executeApi("ACT_LANG_U001", {
                LANG_CODE: Language,
                TYPE: isBulk ? 'BULK' : ''
            })
        }
    }

    const setUserTimeZone = () => {
        Ajax.executeApi("SET_USER_TIMEZONE", {
            TIMEZONE: dayjs.tz.guess() // EX) Asia/Seoul
        })
    }

    const isEmpty = (data) => {
        return Object.keys(data).length === 0;
    }

    const detectI18next = (callback) => {
        (function detect(i) {
            setTimeout(function () {
                const version1 = i18next.exists(dictionary.progress);
                const version2 = i18next.exists('dictionary:progress');
                if (version1 || version2) return (typeof callback !== "undefined") && callback(); // i18next 중복 Init 방지
                if (--i) detect(i);   //  decrement i and call myLoop again if i > 0
            }, 300)
        })(50);
    }

    const storeLanguage = () => {
        const defaultLanguage = Often.isGlobal() ? "en_uk" : "ko";
        const language = !!window._CF_LANG ? window._CF_LANG : defaultLanguage;
        Often.setCookie('FLOW_LANG', unsupportedLangVerification(language), 30 * 12 * 10);
    }

    return {
        initSetting: initSetting,
        initDatePicker: initDatePicker,
        languageCodeDebug: languageCodeDebug,
        getCurrentLanguage: getCurrentLanguage,
        changeLangTemporarily: changeLangTemporarily,
        retrieveUserLanguage: retrieveUserLanguage,
        setUserTimeZone: setUserTimeZone,
        asyncInitSetting: asyncInitSetting,
        storeLanguage: storeLanguage,
    }
})();

var Interpolation = (function () {
    return {
        koreanPrefix: koreanPrefix,
        addName: addName,
        breakLine: breakLine,
        getPastTense: getPastTense,
        validateI18nextCode: validateI18nextCode,
    }

    /**
     * 한글 단어 뒤의 종성을 붙혀 출력
     * 한글 단어 뒤의 종성을 붙혀 출력
     * @param 다국어 처리 된 값
     * @param firstValue (첫번째 종성)
     * @param secondValue (두번쨰 종성)
     * @description This is for Korean Only
     * @returns {String} 단어 + 종성
     */
    function koreanPrefix(word, firstValue, secondValue) {
        word = validateI18nextCode(word)
        var lastChar = word.charCodeAt(word.length - 1);

        // 한국어가 아닐경우 반환
        if (lastChar < 0xAC00 || lastChar > 0xD7A3) return word;
        var jongSung = (lastChar - 0xAC00) % 28 > 0 ? firstValue : secondValue; // 종성 판단
        return word + jongSung;
    }

    /**
     * 다국어 단어 뒤 ... 명 처리 (ex- 관리자 + 명)
     * @description i18next interpolation으로 가능하지만 확장성이 떨어져 Function으로 변경
     *              또한 불필요한 다국어 코드를 줄이기 위함
     * @param i18nextCode 다국어 코드 (Interpolation이 없는 dictionary Code)
     * @returns 다국어 처리 된 단어와 + 명 처리 한 결과 값
     */
    function addName(i18nextCode) {
        var word = i18next.t(i18nextCode);
        return i18next.t(common.name, {val: word})
    }

    /**
     * 개행이 Paragraph (<p> <\p> Tag) 내에서 이루어 질때 변환
     * @returns \n => <br> 변경
     */
    function breakLine(word) {
        return word.replace(/\n/g, '<br/>');
    }


    /**
     * 현재형을 과거 형으로 변경 하여 처리
     * @param verb 영어 동사 (다국어 처리 된 영어 동사)
     * @returns 현재형 -> 과거형 동사
     * @description This is for English Only
     */
    function getPastTense(verb) {
        verb = validateI18nextCode(verb);

        // 영문일때만 과거형 처리
        if (!Internationalization.getCurrentLanguage().includes("en")) return verb;

        // Past tense Exception
        var exceptions = {
            "are": "were",
            "have": "had",
            "is": "was",
            "apply": "applied",
            "copy": "copied"
        }
        verb = validateI18nextCode(verb);

        if (exceptions[verb]) {
            return exceptions[verb];
        }
        if ((/e$/i).test(verb)) {
            return verb + 'd';
        }
        if ((/[aeiou]c$/i).test(verb)) {
            return verb + 'ked';
        }
        // for american english only
        if ((/el$/i).test(verb)) {
            return verb + 'ed';
        }
        if ((/[aeio][aeiou][dlmnprst]$/).test(verb)) {
            return verb + 'ed';
        }

        /* Edit After test for performance
        if ((/[aeiou][bdglmnprst]$/i).test(verb)) {
            return verb.replace(/(.+[aeiou])([bdglmprst])/, '$1$2$2ed');
        }
        */
        return verb + 'ed';
    }


    function validateI18nextCode(code) {
        code = Often.null2Void(code, "");
        if (code.indexOf('$t(') > -1) {
            var i18nextCode = code.substring(3, code.length - 1);
            return i18next.t(i18nextCode).toLowerCase();
        } else return code;
    }
})();

var LinkControl = (function () {
    return {
        controlZendesk: controlZendesk,
    }

    /**
     * Zendesk 기능 아이디로 모든 "?"제거
     * Todo: 영문일 경우 영문으로 나올 수 있게 처리 해야함
     */
    function controlZendesk() {
        if (Often.isFunc('ZENDESK_URL_CONTROL')) $(".icons-help").remove();
    }
})();

/**
 * @description 해당 Function 은 일시적인 사용을 위해 추후에 Deprecate 할 Function 들 입니다.
 */
var TemporaryTranslation = (function () {
    return {
        koreanHolidaysFilter: koreanHolidaysFilter
    }

    /**
     * 공휴일을 한글 -> 영어로 변환 (추후에 클라우드만 RSS 적용 예정)
     * @param holiday 한국의 "한글" 공휴일
     * @returns {String} 사용자의 언어에 따른 공휴일
     */
    function koreanHolidaysFilter(holiday) {
        var isKorean = Internationalization.getCurrentLanguage() === "ko";
        if (isKorean) return holiday;

        var koreanHolidays = getKoreanHolidaysInEnglish();
        var isHolidayExist = koreanHolidays.hasOwnProperty(holiday);
        if (!isHolidayExist) return holiday;
        return koreanHolidays[holiday];
    }

    /*
    한국 공유일의 영문 JSON
     */
    function getKoreanHolidaysInEnglish() {
        return {
            "신정": "New Year's Day",
            "3·1절": "Independence Day",
            "식목일": "Arbor Day",
            "제헌절": "Constitution Day",
            "추석": "Thanksgiving Day",
            "개천절": "National Foundation Day",
            "한글날": "Hangul Proclamation Day",
            "국제 연합일": "United Nations Day",
            "성탄절": "Christmas",
            "대체 휴일": "Substitute Holiday"
        }
    }
})();


const $t = (value) => {
    return "$t(" + value + ")";
}

/**
 * @description AI 관련 다국어 처리
 * 배치에서 내려오는 한국어 치환
 */
var AITranslation = (function () {
    return {
        convertWorker: convertWorker,
    }

    function convertWorker(text) {
        // ${v.gte}명 ~ ${v.lte}명 형식 검사
        const rangePattern = /^(\d+)명 ~ (\d+)명$/;
        // ${v.eq}명 형식 검사
        const exactPattern = /^(\d+)명$/;
        // ${v.gte}명 이상 형식 검사
        const gtePattern = /^(\d+)명 이상$/;
        // ${v.lte}명 이하 형식 검사
        const ltePattern = /^(\d+)명 이하$/;

        if (rangePattern.test(text)) {
            const matches = text.match(rangePattern);
            return i18next.t(common.person, {count: matches[1]}) + " ~ " + i18next.t(common.person, {count: matches[2]});
        } else if (exactPattern.test(text)) {
            const matches = text.match(exactPattern);
            return i18next.t(common.person, {count: matches[1]});
        } else if (gtePattern.test(text)) {
            const matches = text.match(gtePattern);
            return i18next.t(common.peopleOrMore, {count: matches[1]});
        } else if (ltePattern.test(text)) {
            const matches = text.match(ltePattern);
            return i18next.t(common.personOrLess, {count: matches[1]});
        } else if ("담당 없음" === text) {
            return i18next.t(common.noneManager)
        } else {
            return text;
        }
    }
})();
