var PlanFunc = (function () {
    return {
        getPlanInfo: getPlanInfo,
        isUsePlan: isUsePlan,
        isPlanFunc: isPlanFunc,
        isDisabledPlanFunc: isDisabledPlanFunc,
        isLikePlanFunc: isLikePlanFunc,
        isPro: isPro,
        isProjectMakeLimit: isProjectMakeLimit,
        isScretMsgLimit: isScretMsgLimit,
        isSearchLimit: isSearchLimit,
        isPlanFuncWithPopup: isPlanFuncWithPopup,
        isMorningFree: isMorningFree,
        isMorningStarter: isMorningStarter,
        isMorningBusinessPlus: isMorningBusinessPlus,
        isMorningProUnlimited: isMorningProUnlimited,
        isMorningmateAppSumo: isMorningmateAppSumo,
        getPlanName: getPlanName,
        drawLimitPlanPopup: drawLimitPlanPopup,
        getPlanFuncFileSize: getPlanFuncFileSize,
    }

    /**
     * 기업의 플랜 정보를 가져옵니다.
     * @returns {{PLAN_ID: string, PLAN_NAME: (string|*)}|*}
     */
    function getPlanInfo() {
        const guestPlan = {PLAN_ID: "", PLAN_NAME: dictionary.guest};
        const buySetting = LocalUtil.getLocalJson("ONLY_BUY_SETTING");
        const {PLAN_REC} = buySetting;
        if (PLAN_REC && PLAN_REC.length > 0) return PLAN_REC[0];
        else return guestPlan;
    }

    /**
     * 현재 기능키 USE_PLAN을 사용중인지 확인합니다.
     * @returns {*|boolean}
     */
    function isUsePlan() {
        // 이슈 : https://flow.team/l/0oeth 대응
        // 이미 프리랜서라는 것은 사용되고 있어서, 프리랜서 사용자는 USE_PLAN 강제 false
        if (ServerChecker.isEnter) return false;
        if (LocalUtil.getLocalValue("ONLY_BUY_SETTING", "BUY_YN") === "P") return false;
        return Often.isFunc("USE_PLAN");
    }

    /**
     * 플랜 기능키를 사용하는지 확인하는 함수입니다.<br>
     * @param funcName {String} funcName [플랜 기능키명] - PLAN_은 제외하고 입력해야 합니다. PLAN_GANTT면 GANTT만 넣으세요.
     */
    function isPlanFunc(funcName) {
        funcName = funcName.replace(/^PLAN_/i, "");
        return !isUsePlan() ? null : Often.isFunc(`PLAN_${funcName.toUpperCase()}`);
    }

    function isDisabledPlanFunc(funcName) {
        return isPlanFunc(funcName) === false;
    }

    /**
     * 플랜 기능키를 사용하는지 like 검색으로 확인하는 함수입니다.<br>
     * @param funcName [플랜 기능키명] - PLAN_은 제외하고 입력해야 합니다. PLAN_GANTT면 GANTT만 넣으세요.
     * @returns {null|boolean} USE_PLAN이 비활성화면 null / USE_PLAN 활성중이고 funcName이 없으면 false 있으면 true
     */
    function isLikePlanFunc(funcName) {
        const isPlanFunc = () => {
            const funcList = LocalUtil.getLocal("ONLY_FUNC_LIST");
            const searchPlanFunc = new RegExp(`PLAN_${funcName.toUpperCase()}`, 'i');
            return searchPlanFunc.test(funcList);
        }

        return !PlanFunc.isUsePlan() ? null : isPlanFunc();
    }

    function isPro() {
        const {PLAN_ID} = getPlanInfo();
        return /BUSINESS_PRO/.test(PLAN_ID) || isMorningBusinessPlus();
    }

    function isMorningFree() {
        const {PLAN_ID} = getPlanInfo();
        return /MORNING_BASIC/.test(PLAN_ID);
    }

    function isMorningStarter() {
        const {PLAN_ID} = getPlanInfo();
        return /MORNING_STARTER/.test(PLAN_ID);
    }

    function isMorningProUnlimited() {
        const {PLAN_ID} = getPlanInfo();
        return /MORNING_PROUNLIMITED/.test(PLAN_ID);
    }

    function isMorningBusinessPlus() {
        const {PLAN_ID} = getPlanInfo();
        return /MORNING_BUSINESS/.test(PLAN_ID);
    }

    function isMorningmateAppSumo() {
        const {PLAN_ID} = getPlanInfo();
        return /MORNING_BUSINESS_APPSUMO/.test(PLAN_ID);
    }

    function getPlanName() {
        let {PLAN_NAME} = getPlanInfo();
        if (!Often.isGlobal()) return PLAN_NAME;
        if (!!PLAN_NAME && PLAN_NAME !== "dictionary:guest" && PLAN_NAME.indexOf("(") > -1) {
            PLAN_NAME = PLAN_NAME.replaceAll("+", "");
            return PLAN_NAME.substring(0, PLAN_NAME.indexOf("("));
        }
        return "business";
    }


    /**
     * 플랜 기능키를 사용하는지 확인하고, 기능을 사용할 수 없으면 팝업과 함께 false를 반환합니다.
     * @param {String} funcName [플랜 기능키명] - PLAN_은 제외하고 입력해야 합니다. PLAN_GANTT면 GANTT만 넣으세요.
     * @param {String} limitType 제한 타입을 설정하면 어떻게 제한 할 지 표현할 수 있습니다.
     * @param {boolean} isMini 설치형 미니화면에서 플랜 구매 유도 팝업을 표시하려면 true로 입력하세요.
     * @returns {boolean} 팝업을 띄우면 false / 팝업을 안 띄우면 true
     */
    function isPlanFuncWithPopup(funcName, limitType, isMini = false) {
        const PublicProject = requireModule("PublicProject", {on: ["main"]})();
        if (PublicProject?.isInPublicProject()) return true;
        if (ServerChecker.isEnter) return true;
        const isPlanFunc = PlanFunc.isPlanFunc(funcName);
        if (isPlanFunc === null && LimitGuest.isLimitGuest(limitType, isMini)) return false;
        else if (isPlanFunc !== null && !isPlanFunc) {
            PlanFunc.drawLimitPlanPopup(limitType, isMini);
            return false;
        }
        return true;
    }

    /**
     * 기관이 사용하는 플랜에 기능 사용권한이 없는 경우 보여주는 제한 팝업입니다.
     */
    function drawLimitPlanPopup(limitType, isMini = false, setInitialTab = false) {
        const Helper = Often.isGlobal() ? MorningMateServiceHelper : FlowServiceHelper;
        const getPostLinkUrl = limitType => {
            const linkJson = {
                collect: isMini ? Helper.COLLECT : Helper.COLLECT_MINI, // 모아보기
                search: Helper.SEARCH, // 채팅 메세지 검색
                secret: Helper.SECRET, // 시크릿 메세지
                flowdrive: Helper.FLOWDRIVE, //파일함
                video: Helper.ZOOM, // 화상회의
                timeline: Helper.TIMELINE, // 간트 차트
                subtask: Helper.SUBTASK, // 하위 업무
                organization: Helper.ORGANIZATION, // 조직도
                googlecalendar: Helper.GOOGLE_CALENDAR, // 구글 캘린더
                dashboard: Helper.DASHBOARD,
                okr: Helper.OKR, //- 이거
                projecttemplate: Helper.PROJECT_TEMPLATE, // 프로젝트 템플릿 - 이거
                // secure: "", // 보안
                statistics: Helper.STATISTIC,
                publicproject: Helper.PUBLIC_PROJECT,
                routine: Helper.ROUTINE,
                insight: Helper.INSIGHT,
                //  구글번역기능 제한팝업함수 신규생성해주세요!
                //  #blockPopup > .limit-popup에 .google-translate 클래스 추가
                //  dt: 실시간 번역
                //  dd: 서비스 업그레이드를 통해 <br> 실시간으로 대화를 다른 언어로 번역하세요.
                //  a: 링크 안나옴
            };
            const defaultUrl = isUsePlan() ? Helper.PLAN_PRICE : Helper.GUEST;
            return linkJson[limitType] ? Often.isGlobal() ? i18next.t(linkJson[limitType]) :
                linkJson[limitType] : defaultUrl;
        };

        const getBlockType = (limitType) => {
            return BLOCK_TYPE[limitType.toUpperCase()];
        };

        const isIamAdminUser = LocalUtil.getBuyManger() === "Y";

        const submitCallback = () => {
            const regex = new RegExp(Often.getAdmin(), 'i');
            const isFlowAdmin = regex.test(location.href);
            const isSerp = LocalUtil.getSerpYn() === "Y";
            if (!Often.isGuest() && !isIamAdminUser) {
                Often.toast("success", i18next.t(main.alert.buyOnlyAdmin));
                return;
            }
            location.hash = limitType;
            if (isSerp) {
                Payment.openPaymentLayer("business");
            } else if (isMini || isFlowAdmin) {
                window.open(`${Often.getLocOrigin()}/main.act?stripePayment`, "_blank");
            } else {
                Payment.openPaymentLayer("business");
            }
        }

        if (Mutil.isFunc("FEATURE_INTRO_POP")) {
            const tabList = {
                'okr': 'okr',
                'projectTemplate': 'project',
                'secure-secu': 'secu',
                'secure-capture': 'capture',
                'secure-document': 'document',
                'dashboard': 'dashboard',
                'routine': 'routine',
                'insight': 'insight',
                'calendar': 'calendar'
            };
            const targetCode = tabList[limitType];
            if (Object.keys(tabList).includes(limitType)) {
                const FeaturePop = requireModule('FeaturePop', {on: ['main']});
                FeaturePop()?.render($('body'), targetCode, {
                    isUpgradeTarget: () => isIamAdminUser || Often.isGuest(),
                    submitCallback: () => submitCallback(),
                    initialTabCode: setInitialTab ? {code: limitType} : ''
                });
                return;
            }
        }

        PopupDraw.closePopup();
        PopupDraw.drawBlock({
            await: isMini && limitType === "data500",
            mini: isMini,
            class: limitType,
            contents: getBlockType(limitType),
            linkUrl: getPostLinkUrl(limitType.toLowerCase()),
            callback: {
                submit: () => submitCallback(),
                final: function ($pop) {
                    if (/timeline/.test(limitType)) {
                        $pop.addClass("z-30");
                    }

                    if (/publicproject/.test(limitType)) {
                        $pop.addClass("z-30");
                    }
                    if (/^project(?:Template)?$/i.test(limitType)) {
                        $pop.addClass("z-20");
                    }
                    const regex = new RegExp(Often.getAdmin(), 'i');
                    if (regex.test(location.href)) {
                        $pop.find("#detailLinkBtn").off("click").on("click", () => {
                            window.open(getPostLinkUrl(limitType.toLowerCase()), "_blank");
                        });
                    }
                }
            }
        })
    }

    /**
     * 검색에서 오래된 자료 검색 제한합니다.
     * @param funcName
     * @param limitType
     * @param writeDate
     * @returns {null|boolean} USE_PLAN을 안 사용하면 null / 제한없으면 true, 제한되면 false
     */
    // TDOD : 제한 기간을 다양하게 두는 방법을 생각해봐야 합니다.
    function isSearchLimit(funcName, limitType, writeDate) {
        // SEARCH_UNLIMIT
        const isPlanFunc = PlanFunc.isPlanFunc(funcName);
        const now = new Date();
        now.setMonth(now.getMonth() - 1)
        const dataAMonthAgo = Often.getDateFormat(now);

        if (isPlanFunc === null) return null;
        else if (!isPlanFunc && dataAMonthAgo > writeDate) {
            drawLimitPlanPopup(limitType);
            return false;
        }
        return true;
    }

    function isScretMsgLimit(funcName) {
        const isPlanFunc = PlanFunc.isPlanFunc(funcName);
        if (isPlanFunc === null) {
            return true;
        } else if (!isPlanFunc) {
            if (Often.isGlobal()) {
                drawLimitPlanPopup("secret", true);
                return false;
            }
        }
        return true;
    }

    function isProjectMakeLimit(funcName) {
        const isPlanFunc = PlanFunc.isPlanFunc(funcName);
        if (isPlanFunc === null) {
            return true;
        } else if (isPlanFunc) {
            if (Often.isGlobal()) {
                if (LocalUtil.getBuyPrjMkLmtYn() === "Y") {
                    drawLimitPlanPopup("project");
                    return false;
                }
            }
        }
        return true;
    }


    /**
     * 플랜관리 기능키 중, 파일 제한 용량 정보가 함께 있는 플랜기능키의 제한 용량을 byte 단위로 추출합니다.
     * @param funcName 플랜 기능키 PLAN_${funcName}
     * @returns {number} 제한 용량 (단위 byte)
     */
    function getPlanFuncFileSize(funcName) {
        const memoryUnit = ['', 'K', 'M', 'G', 'T'];
        const funcList = LocalUtil.getLocal("ONLY_FUNC_LIST");
        const regex = new RegExp(`PLAN_${funcName.toUpperCase()}_\\d+\\w\\b`);
        const getPlanFunc = funcList.match(regex)?.[0];

        if (!getPlanFunc) return;

        const size = +getPlanFunc.replace(/[A-Z_]*/ig, '');
        const unit = getPlanFunc.replace(/^\w*(\w)$/ig, '$1');

        if (memoryUnit.indexOf(unit) < 0) return;
        return size * (1024 ** memoryUnit.indexOf(unit));
    }
})();
