var PopupDraw = (function () {

    var currentPassJson;
    var workingTimer;
    var popupLayer = new Array();

    return {
        drawInputConfirm: drawInputConfirm,
        drawMarketingAgreementConfirm: drawMarketingAgreementConfirm,
        drawConsultPopup: drawConsultPopup,
        drawTemplateConfirm: drawTemplateConfirm,
        drawProjectTemplate: drawProjectTemplate,
        drawConfirm: drawConfirm,
        drawColorSetting: drawColorSetting,
        drawLabelSetting: drawLabelSetting,
        drawPushAlarmSetting: drawPushAlarmSetting,
        drawBlock: drawBlock,
        drawKrxUpdate: drawKrxUpdate,
        drawConfirmOnly: drawConfirmOnly,
        drawPrevChatFirst: drawPrevChatFirst,
        drawBigBlock: drawBigBlock,
        drawCheck: drawCheck,
        drawAuth: drawAuth,
        confirmCreateProjectChat: confirmCreateProjectChat,
        drawMultiselectTypeSelect: drawMultiselectTypeSelect,

        getPopupWrapObj: getPopupWrapObj,
        drawToast: drawToast,
        closePopup: closePopup,
        openTempPopup: openTempPopup,
        addEventOnTempPopup: addEventOnTempPopup,
        drawVideoConfSelectPopup: drawVideoConfSelectPopup,

        limitPopup: limitPopup,
        limitConsultPopup: limitConsultPopup,

        showCentrexCallPopup: showCentrexCallPopup,
    }

    function drawInputConfirm(data) {
        drawPopup('input', $.extend({
            TITLE: data.contents && data.contents.title,
            HOLDER: data.contents && data.contents.holder,
            VALUE: data.contents && data.contents.value,
            EMPTY_MSG: data.contents && data.contents.empty,
            OVER_MSG: data.contents && data.contents.over,
            CLASS: data.class,
        }, makeJsonByData(data)))
    }

    function drawMarketingAgreementConfirm(data) {
        drawPopup('marketing-agreement', makeJsonByData(data))
    }

    function drawConsultPopup(data) {
        drawPopup('consult', $.extend({
            TITLE: i18next.t(data.contents && data.contents.title),
            CONTENTS: Interpolation.breakLine(i18next.t(data.contents && data.contents.main)),
            HOLDER: i18next.t(data.contents.holder),
            VALUE: data.contents && data.contents.value,
        }, makeJsonByData(data)))
    }

    function drawTemplateConfirm(data) {
        drawPopup('template-input', $.extend({
            TITLE: data.contents.title,
            HOLDER: data.contents.holder,
            DESCRIPTION: data.contents.description,
            VALUE: data.contents && data.contents.value,
            EMPTY_MSG: data.contents && data.contents.empty,
            OVER_MSG: data.contents && data.contents.over,
            CLASS: data.class,
        }, makeJsonByData(data)))
    }

    function drawProjectTemplate(data) {
        drawPopup('regist-template', $.extend({}, makeJsonByData(data)));
    }

    function drawConfirm(data) {
        if ($("#tempPopup .confirm-popup").is(":visible")) return false;
        drawPopup('confirm', $.extend({
            MINI: data.mini,
            TITLE: data.title,
            CONTENTS: data.contents && data.contents.main,
            VALUE: data.contents && data.contents.value,
        }, makeJsonByData(data)));

        //Note. 다른 페이지에서 쓸 수 있게!
        $("#tempPopup").addClass("flow-all-background-1");

        //팝업 스타일 커스텀
        if(data.style){
            $("#tempPopup .confirm-popup .flow-content").css(data.style);
        }
    }

    function drawColorSetting(data) {
        drawPopup('color', makeJsonByData(data))
    }

    function drawVideoConfSelectPopup(data, {type, val, isMini = true}) {
        drawPopup('video-conf-select', makeJsonByData(data));
        VideoConference.getRecentlyVideoOrg((dat) => {
            let videoOrg = Often.null2Void(dat.VIDEO_ORG);
            if(ServerChecker.isKyowon && videoOrg === 'ZoomJWT') videoOrg = '';
            if(videoOrg) {
                $(".js-video-conf-selected-box").find(".on").removeClass("on").css("display","none");
                $(`.video-service-option[data=${videoOrg}]`).addClass("on").css("display", "");
            }
        })
        addEventOnVideoConfPopup(type, val, isMini);
    }

    function drawLabelSetting(data) {
        drawPopup('label', makeJsonByData(data))
    }

    function drawPushAlarmSetting(data) {
        drawPopup('push-alarm', makeJsonByData(data));
    }

    function drawBlock(data = {}) {

        // 서비스를 이용할 수 없는 경우 ( 무료 체험 만료, ?.. )
        const isTrialEnded = data.class === 'service free-trial-ended';
        if (isTrialEnded) {
            limitPopup().show();
            return;
        }

        drawPopup('limit', $.extend({
            MINI: data.mini,
            CLASS: data.class,
            TITLE: i18next.t(data.contents && data.contents.title),
            CONTENTS: Interpolation.breakLine(i18next.t(data.contents && data.contents.main)),
            UPDATE_POPUP_VER_INFO: Interpolation.breakLine(i18next.t(data.contents && data.contents.updatePopupVerInfo)),
            SEEAGAIN: data.seeAgain,
            CLOSE_BUTTON_YN: data.closeButtonYn
        }, makeJsonByData(data)))
    }

    function drawKrxUpdate(json) {
        drawPopup('krx', json)
    }

    function drawConfirmOnly(data) {
        drawPopup('prev-refresh', $.extend({
            MINI: data.mini,
            TITLE: data.title,
            CONTENTS: data.contents && data.contents.main,
            VALUE: data.contents && data.contents.value,
        }, makeJsonByData(data)));
    }

    function drawPrevChatFirst(data) {
        drawPopup('prev-first', $.extend({
            MINI: data.mini,
            TITLE: data.title,
            CONTENTS: data.contents && data.contents.main,
            VALUE: data.contents && data.contents.value,
            PREVCHAT_YN: data.contents.prevchat_yn,
        }, makeJsonByData(data)));
    }

    function drawBigBlock(data) {
        drawPopup('big-block', $.extend({
            CLASS: data.class,
            TITLE: data.contents && data.contents.title,
            CONTENTS: data.contents && data.contents.main,
        }, makeJsonByData(data)))
    }

    function drawCheck(data) {
        drawPopup('check-box', $.extend({
            MINI: data.mini,
            CLASS: data.contents && data.class,
            CHECK_ID: data.contents && data.id,
            TITLE: data.contents && data.contents.title,
            CONTENTS: data.contents && data.contents.main,
            VALUE: data.contents && data.contents.value,
            HOLDER: data.contents && data.contents.holder,
        }, makeJsonByData(data)))
    }

    function drawAuth(data) {
        drawPopup('auth', $.extend({
            CLASS: data.contents && data.class,
            TITLE: data.contents && data.contents.title,
            CONTENTS: data.contents && data.contents.main,
            COUNTER_ID: data.contents && data.contents.id,
            SUBMIT_TEXT: data.contents && data.contents.submit,
        }, makeJsonByData(data)))
    }

    function drawMultiselectTypeSelect(data) {
        if ($("#tempPopup .multiselect-popup").is(":visible")) return false;
        drawPopup('multiselect', {
            TITLE: '선택',
        });

        //Note. 다른 페이지에서 쓸 수 있게!
        $("#tempPopup").addClass("flow-all-background-1");
    }

    function makeJsonByData(data) {
        return {
            AWAIT: data.await,
            LINK_URL: data.linkUrl,
            SUBMIT_TEXT: i18next.t(data.contents && data.contents.submit),
            SECONDARY_SUBMIT_TEXT: i18next.t(data.contents && data.contents.secondarySubmit),
            CANCEL_TEXT: i18next.t(data.contents && data.contents.cancel),
            LINK_TEXT: i18next.t(data.contents && data.contents.link_text),
            BOTTOM_TEXT: i18next.t(data.contents && data.contents.bottom_text),
            SUBMIT_CALLBACK: data.callback && data.callback.submit,
            SECONDARY_SUBMIT_CALLBACK: data.callback && data.callback.secondarySubmit,
            CANCEL_CALLBACK: data.callback && data.callback.cancel,
            CLOSE_CALLBACK: data.callback && data.callback.close,
            FINAL_CALLBACK: data.callback && data.callback.final,
            KEYUP_CALLBACK: data.callback && data.callback.keyup,
            BOTTOM_BUTTON_CALLBACK: data.callback && data.callback.bottom,
            CLOSE_BUTTON_YN: data.closeButtonYn,
            UPDATE_POPUP_VER_INFO: data.updatePopupVerInfo,
            TYPE_LAYER : data.type_layer,
            ENTER_PREVENT : data.enter_prevent,
            ZINDEX : data.zindex,
        }
    }


    function drawToast(data) {
        if ("" === Often.null2Void(data)) return;
        var type = Often.null2Void(data.type, "");
        var msg = Often.null2Void(data.msg, "");
        var sec = Number(Often.null2Void(data.sec, "2500"));
        const allowHtml = Often.null2Void(data.allowHtml, false);

        if ("" === msg || "" === type) return;
        setStyle()
        var $toastPop = getToastPopupObj();
        var isExistToastPopup = $toastPop.length > 0;
        isExistToastPopup && clearToastPopup();

        msg = msg.replace(/(?:\r\n|\r|\n)/g, "<br>");
        var $toastPopup = $(`
        <div class="alert-wrap d-none">
            <div class="alert-type">
                <div class="text">${msg}</div>
            </div>
        </div>`);
        $toastPopup.attr("id", "toastPopup");
        if (window.ElectronApi && ElectronApi.Comm.isElectron()) {
            $toastPopup.addClass('mini');

            if(Often.isMessenger() && Often.isFunc("CHAT_TRANSPARENT"))
                $toastPopup.css("top", "100px");
        }

        $toastPopup.find(".alert-type").addClass(type);
        //$toastPopup.find(".text").text(msg);

        if (allowHtml) {
            $toastPopup.find(".text").get(0).innerHTML = msg;
        }

        $('body').prepend($toastPopup);
        $toastPop = getToastPopupObj();
        $toastPop.fadeIn(200);
        if (workingTimer) {
            clearTimeout(workingTimer);
        }
        workingTimer = setTimeout(function () {
            $toastPop.fadeOut(200, clearToastPopup);
        }, sec);

        function getToastPopupObj() {
            return $("#toastPopup");
        }

        function clearToastPopup() {
            getToastPopupObj().remove();
        }
    }

    function getPopupWrapObj() {
        return $($("#popupWrap").html()).attr("id", "tempPopup").css("display", "none").addClass("temp-popup");
    }

    function drawPopup(mode, passJson) {
        currentPassJson = $.extend({}, passJson);
        var $tempPopup = $(getPopupHtml());
        if(passJson && passJson.TYPE_LAYER === true) {
            if(popupLayer.length > 0) {
                const $lastPopup = popupLayer[popupLayer.length - 1];
                const nextZIndex = $lastPopup.css('z-index') + 10;
                const nextNum = Number($lastPopup.attr("id").split("tempPopup")[1]) + 1;
                $tempPopup.attr('id', "tempPopup" + nextNum);
                $tempPopup.css('z-index', nextZIndex);
            } else {
                $tempPopup.attr('id', "tempPopup1");
            }
            $tempPopup.data('currentPassJson' , passJson);
            popupLayer.push($tempPopup);
        }
        if (passJson.ZINDEX > 0) {
            $tempPopup.css('z-index',passJson.ZINDEX);
        }
        if (mode === "krx"){
            $('.mini-body').append(`<div class="flow-all-background-1 back-area temp-popup serviceStop" tabindex="0" id="tempPopup" style="z-index: 100000;">
                <div class="flow-project-make-1 back-area">
                    <div class="flow-project-make-2 back-area contents">
                        <div class="limit-popup d-none invite" style="display: block;">
                            <dl class="limit-content">
                                <dt class="popup-title">신규 업데이트</dt>
                                <dd class="popup-cont">새로운 버전의 데스크탑 앱이 출시되었습니다!</dd>
                            </dl>
                            <button id="detailLinkBtn" class="btn-detail-link d-none">설치에 어려움이 있으신가요?</button>
                            <a class="limit-link-button submit-event">PC 앱 다운로드(서울)</a>
                            <a class="limit-link-button submit-event2">PC 앱 다운로드(부산)</a>
                        </div>
                    </div>
                </div>
            </div>`);
            $('#tempPopup').on("click", function (e) {
                if($(e.target).closest('.submit-event').length){
                    passJson.submit1();
                }
                if($(e.target).closest('.submit-event2').length){
                    passJson.submit2();
                }
            });
            return;
        }
        if (mode === "input" || mode === "template-confirm") {

            var isTemplate = mode === "template-confirm";
            var sizzleName = isTemplate ? ".template-popup-input" : ".popup-input";

            var $inputObj = $tempPopup.find(sizzleName);
            $inputObj.attr({
                "data-empty-msg": passJson.EMPTY_MSG,
                "data-over-msg": passJson.OVER_MSG,
            })
            addInputKeyUp($inputObj)
        } else if (mode === 'label') {
            var $labelUl = $tempPopup.find(".js-label-ul");
            AllLabel.drawProjectLabelList($labelUl);
        } else if (mode === 'push-alarm') {
            var $pushAlarmPopup = $tempPopup.find(".push-alarm-content")
            PushAlarmSetting.drawPushAlarmSetting($pushAlarmPopup, function() {
                openTempPopup($tempPopup)
            });
        } else if (mode === 'check-box') {
            var $checkBoxInput = $tempPopup.find(".js-checkbox-input")
            var $checkBoxLabel = $tempPopup.find(".js-checkbox-label")
            $checkBoxInput.attr('id', passJson.CHECK_ID)
            $checkBoxLabel.attr('for', passJson.CHECK_ID)
        } else if (mode === 'auth') {
            var $authCounter = $tempPopup.find(".js-auth-counter")
            $authCounter.attr('id', passJson.COUNTER_ID)
        } else if (mode === 'consult') {
            $tempPopup.find(".popup-cont").text(passJson.TITLE);
            $tempPopup.find(".popup-cont").css('text-align', 'center');
        } else if (mode === 'trash-can-permanently-delete') {
            // 전용팝업을 만들까? @서준희
            $tempPopup.find(".popup-cont").text(passJson.TITLE);
            $tempPopup.find(".popup-cont").css('text-align', 'center');
        }

        $tempPopup.find(".popup-title").text(passJson.TITLE);
        $tempPopup.find(".popup-input, .template-popup-input").attr('placeholder', ' ' + passJson.HOLDER).val(Often.null2Void(passJson.VALUE));
        $tempPopup.find(".template-popup-description").attr('placeholder', ' ' + passJson.DESCRIPTION);
        $tempPopup.find(".popup-cont").html(passJson.CONTENTS);
        $tempPopup.find(".popup-cont-verInfo").html(passJson.UPDATE_POPUP_VER_INFO);
        $tempPopup.find(".submit-event").text(Often.null2Void(passJson.SUBMIT_TEXT, i18next.t(dictionary.confirm)));
        $tempPopup.find(".cancel-event").text(Often.null2Void(passJson.CANCEL_TEXT, i18next.t(dictionary.cancel)));
        $tempPopup.find(".secondary-submit-event").text(passJson.SECONDARY_SUBMIT_TEXT);


        var $modePopup;
        if (mode === 'limit') {
            $modePopup = $tempPopup.find("." + mode + "-popup");
            passJson.MINI && $modePopup.addClass('chat-limit-popup');
            !ServerChecker.isEnter && passJson.LINK_URL && $tempPopup.find("#detailLinkBtn").css("display", "inline-block");
            passJson.LINK_TEXT && $tempPopup.find("#detailLinkBtn").text(passJson.LINK_TEXT);
            !passJson.SEEAGAIN && $tempPopup.find(".popup-input-wrap").css('display', 'none'); // 다시보지 않기 탭
            !passJson.SUBMIT_TEXT && $tempPopup.find('.limit-link-button').css('display', 'none');
            passJson.CLASS === 'invite' && $modePopup.find('.limit-close-button').css('display', 'none');
            passJson.CLOSE_BUTTON_YN === "Y" && $modePopup.find('.limit-close-button').css('display', 'none');
            (passJson.CLASS === 'invite' || passJson.CLASS === 'service') && $modePopup.find('.popup-cont-verInfo').css('display', 'none');
            !!passJson.BOTTOM_TEXT && $tempPopup.find("#bottomBtn").text(passJson.BOTTOM_TEXT).css({'display':'flex', 'justify-content': 'center'});
        } else if (mode === 'video-conf-select') {
            $modePopup = $tempPopup.find(".js-video-select-pop");
        } else if (mode === 'prev-refresh') {
            $modePopup = $tempPopup.find(".js-prev-refresh-popup");
        } else if (mode === 'prev-first') {
            $modePopup = $tempPopup.find(".js-prev-first-popup");
            $tempPopup.find(".submit-event").text(passJson.SUBMIT_TEXT);
            $tempPopup.find(".cancel-event").text(passJson.CANCEL_TEXT);
        } else {
            mode = ((passJson.MINI ? "mini-" : "") + mode);
            $modePopup = $tempPopup.find("." + mode + "-popup");
        }
        passJson.CLASS && $modePopup.addClass(passJson.CLASS);
        $modePopup.css("display", "block");
        addEventOnTempPopup($tempPopup, passJson, mode);
        !isDoNotSeeAgain(passJson)? openTempPopup($tempPopup) : ''; // 다시보기 쿠키가 존재할 경우 출력하지 않음

        function addInputKeyUp($inputObj) {
            var inputTimer;
            var inputMaxLength = Number($inputObj.attr("maxlength"));
            $inputObj.off("keyup").on("keyup", function (e) {
                inputTimer && clearTimeout(inputTimer);
                inputTimer = setTimeout(function () {
                    if ($inputObj.val().length === inputMaxLength) Often.toast("error", $inputObj.attr("data-over-msg"));
                }, 10)
            })
        }
    }

    function isDoNotSeeAgain(data) {
        const name = data.SEEAGAIN;
        if (!name) return false;
        return Often.getCookie(name + "_POPUP_YN") === "N";
    }

    function addEventOnTempPopup($tempPopup, passJson, mode) {
        $tempPopup.on("mousedown", function (e) {
            e.stopPropagation();
            let $eTarget = $(e.target);
            let isBackArea = $eTarget.is(".back-area");
            let $closeEvent = $eTarget.findUp(".close-event");
            let $cancelEvent = $eTarget.findUp(".cancel-event");
            let $submitEvent = $eTarget.findUp(".submit-event");
            let $bottomEvent = $eTarget.findUp(".bottom-event");
            let $secondarySubmitEvent = $eTarget.findUp(".secondary-submit-event");
            let $colorItem = $eTarget.findUp(".color-item");
            let $detailLinkBtn = $eTarget.findUp("#detailLinkBtn");
            let $doNotSeeAgainCheckBox = $eTarget.findUp(".chk-hide");
            let $forceUpdateCheck = $eTarget.find(".no-logo-forceUpdate");
            let $prevRefresh = $('#tempPopup').find(".js-prev-refresh-popup");
            let $prevFirst = $('#tempPopup').find(".js-prev-first-popup");
            //팝업 외 영역을 눌러 로그아웃 alert 띄우는 부분 제거 요청 #242527
            let $serviceStop = $eTarget.findUp(".serviceStop");
            let $multiselectTypeSelecPopup = $eTarget.closest(".js-single-multi-select-popup-layer").length > 0;

            if($prevRefresh.css("display") === 'block' && Often.isFunc(Func.ENTER.NOLIMIT_CHAT)){
                // backarea 클릭했을때 꺼지면 안됨 -> 확인 강제로 누르게 제어
            } else {
                const closeCondition = ((isBackArea && $forceUpdateCheck.length === 0 && $serviceStop.length === 0)
                    || ($closeEvent.length > 0 && $forceUpdateCheck.length === 0))
                if (window.ElectronApi && !ElectronApi.Comm.isElectron() && window.location.href.indexOf("subscreen.act?GB=PROFILE") > -1) {
                    location.reload();
                } else if (closeCondition) {
                    return closePopup()
                }
            }
            if ($cancelEvent.length > 0) return cancelPopup();
            if ($submitEvent.length > 0) return submitPopup(mode);
            if ($secondarySubmitEvent.length > 0) return secondarySubmitPopup();
            if ($bottomEvent.length > 0) return bottomSubmitPopup();
            if ($detailLinkBtn.length > 0) return detailLinkConnect();
            if ($multiselectTypeSelecPopup) return drawMultiselectTypeSelectPopup($eTarget);
            if ($doNotSeeAgainCheckBox.length > 0) return doNotSeeAgainPopup($doNotSeeAgainCheckBox);
            if ($colorItem.length > 0) {
                var $selectColorTypes = $eTarget.findUp("#selectColorTypes");
                $selectColorTypes.find(".project-color-check-active-1").removeClass("project-color-check-active-1");
                $colorItem.addClass("project-color-check-active-1");
            } else {
                //pass
            }

        });

        $tempPopup.off("keydown").on("keydown", function (e) {
            e.stopPropagation();
            if (KeyCheck.isKey(e, "ESC")) {
                if ($(e.target).find(".no-logo-forceUpdate").length !== 0) return;
                Often.isAct("subscreen") ? subscreenClose() : closePopup()
            } else if (KeyCheck.isKey(e, "ENTER")) {
                if(passJson && !passJson.ENTER_PREVENT) {
                    submitPopup();
                }
            } else {
                //pass
            }

            function subscreenClose() {
                var tempPopupCount = $(".temp-popup").length;
                if (tempPopupCount && tempPopupCount > 1) return $(".temp-popup")[tempPopupCount - 1].remove();
                window.close();
            }
        });
    }

    function addEventOnVideoConfPopup(type,value, isMini = true) {
        const $videoSelectPop = $(".js-video-select-pop");
        const $videoConfSelectedBox = $videoSelectPop.find(".js-video-conf-selected-box");
        const $videoSelectMenuUl = $videoSelectPop.find(".js-video-org-select-menu");
        Often.showOrHideByFunc("ZOOM_OAUTH", $videoSelectMenuUl.find(".js-zoom"));
        Often.showOrHideByFunc("MS_TEAMS", $videoSelectMenuUl.find(".js-ms-teams"));
        Often.showOrHideByFunc("MS_TEAMS_JWT", $videoSelectMenuUl.find(".js-ms-teams-jwt"));
        Often.showOrHideByFunc("ZOOM_JWT", $videoSelectMenuUl.find(".js-zoom-jwt"));
        Often.showOrHideByFunc("WEBEX_OAUTH", $videoSelectMenuUl.find(".js-webex"));
        Often.showOrHideByFunc("WEBEX_JWT", $videoSelectMenuUl.find(".js-webex-jwt"));
        Often.showOrHideByFunc("GOOGLE_MEET", $videoSelectMenuUl.find(".js-google-meet"));
        $videoSelectPop.off("click").on("click", (e) => {
            const $eTarget = $(e.target);
            e.preventDefault();
            e.stopPropagation();
            let payload = {}
            switch(true) {
                case $eTarget.findUp(".js-video-conf-selected-box").length > 0:
                    $videoSelectMenuUl.css("display") === "none" ? $videoSelectMenuUl.css("display", "") : $videoSelectMenuUl.css("display", "none");
                    break;
                case $eTarget.findUp(".js-zoom").length > 0:
                    if(Often.isFunc("ZOOM_FREE")) {
                        VideoConference.getZoomFreePopup();
                        PopupDraw.closePopup();
                        return;
                    }
                    payload = {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: 'Zoom'
                    }
                    VideoConference.isSynchronizedOnSelect(
                        ()=>{
                            $videoConfSelectedBox.find(".on")
                                .removeClass("on")
                                .css("display","none");
                            $videoConfSelectedBox.find(".js-selected-zoom")
                                .addClass("on")
                                .css("display","");
                            $videoSelectMenuUl.css("display","none");
                        },
                        () => {
                            VideoConference.alertRequiredZoomSync(isMini, payload);
                            closePopup();
                        },payload
                    )
                    break;
                case $eTarget.findUp(".js-zoom-jwt").length > 0:
                    payload = {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: 'ZoomJWT'
                    }
                    VideoConference.isSynchronizedOnSelect(
                        () => {
                            $videoConfSelectedBox.find(".on")
                                .removeClass("on")
                                .css("display","none");
                            $videoConfSelectedBox.find(".js-selected-zoom-jwt")
                                .addClass("on")
                                .css("display","");
                            $videoSelectMenuUl.css("display","none");
                        },
                        () => {
                            VideoConference.alertRequiredZoomSync(isMini, payload);
                            closePopup();
                        },payload
                    )
                    break;
                case $eTarget.findUp(".js-ms-teams").length > 0:
                    payload = {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: 'MsTeams'
                    }
                    VideoConference.isSynchronizedOnSelect(
                        ()=>{
                            $videoConfSelectedBox.find(".on")
                                .removeClass("on")
                                .css("display","none");
                            $videoConfSelectedBox.find(".js-selected-ms-teams")
                                .addClass("on")
                                .css("display","");
                            $videoSelectMenuUl.css("display","none");
                        },
                        () => {
                            VideoConference.alertRequiredZoomSync(isMini, payload);
                            closePopup();
                        },payload
                    )
                    break;
                case $eTarget.findUp(".js-ms-teams-jwt").length > 0:
                    payload = {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: 'MsTeamsJWT'
                    }
                    VideoConference.isSynchronizedOnSelect(
                        ()=>{
                            $videoConfSelectedBox.find(".on")
                                .removeClass("on")
                                .css("display","none");
                            $videoConfSelectedBox.find(".js-selected-ms-teams-jwt")
                                .addClass("on")
                                .css("display","");
                            $videoSelectMenuUl.css("display","none");
                        },
                        () => {
                            VideoConference.alertRequiredZoomSync(true, payload);
                            closePopup();
                        },payload
                    )
                    break;
                case $eTarget.findUp(".js-webex").length > 0:
                    payload = {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: 'WebEx'
                    }
                    VideoConference.isSynchronizedOnSelect(
                        ()=>{
                            $videoConfSelectedBox.find(".on")
                                .removeClass("on")
                                .css("display","none");
                            $videoConfSelectedBox.find(".js-selected-webex")
                                .addClass("on")
                                .css("display","");
                            $videoSelectMenuUl.css("display","none");
                        },
                        () => {
                            VideoConference.alertRequiredZoomSync(true, payload);
                            closePopup();
                        },payload
                    )
                    break;
                case $eTarget.findUp(".js-webex-jwt").length > 0:
                        payload = {
                            isMini: Often.isAct("messenger"),
                            type: 'chat',
                            videoOrg: 'WebExJWT'
                        }
                        VideoConference.isSynchronizedOnSelect(
                            ()=>{
                                $videoConfSelectedBox.find(".on")
                                    .removeClass("on")
                                    .css("display","none");
                                $videoConfSelectedBox.find(".js-selected-webex-jwt")
                                    .addClass("on")
                                    .css("display","");
                                $videoSelectMenuUl.css("display","none");
                            },
                            () => {
                                VideoConference.alertRequiredZoomSync(true, payload);
                                closePopup();
                            },payload
                        )
                    break;
                case $eTarget.findUp(".js-header-cancel-btn").length > 0 :
                case $eTarget.findUp(".js-video-cancel-btn").length > 0 :
                    closePopup();
                    break;
                case $eTarget.findUp(".js-video-submit-btn").length > 0 :
                    if($videoConfSelectedBox.find(".on").is(".video-service-option-default")) return;
                    // 화상회의 로직
                    const selectedVideoOrg = $videoConfSelectedBox.find(".on").attr("data");
                    VideoConference.OpenMessengerAndSend(type, value, selectedVideoOrg);
                    closePopup();
                    break;
                case $eTarget.findUp(".js-google-meet").length > 0 :
                    payload = {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: 'GoogleMeet'
                    }
                    VideoConference.isSynchronizedOnSelect(
                        ()=>{
                            $videoConfSelectedBox.find(".on")
                                .removeClass("on")
                                .css("display","none");
                            $videoConfSelectedBox.find(".js-selected-google-meet")
                                .addClass("on")
                                .css("display","");
                            $videoSelectMenuUl.css("display","none");
                        },
                        () => {
                            VideoConference.alertRequiredZoomSync(isMini, payload);
                            closePopup();
                        },payload
                    )
                    break;
            }

        })
    }

    function resetVideoConfPop() {
        const $videoSelectPop = $(".js-video-select-pop");
        const $videoConfSelectedBox = $videoSelectPop.find(".js-video-conf-selected-box");
        const $videoSelectMenuUl = $videoSelectPop.find(".js-video-org-select-menu");
        $videoConfSelectedBox.find(".on").removeClass("on").css("display", "none");
        $videoConfSelectedBox.find(".js-default-selected").addClass("on").css("display","");
        $videoSelectMenuUl.css("display","none");
    }

    function openTempPopup($tempPopup) {
        $('body').append($tempPopup);
        var $currentPopup = getCurrentPopup();
        if (currentPassJson && typeof currentPassJson.FINAL_CALLBACK === "function") {
            currentPassJson.FINAL_CALLBACK($currentPopup);
        } else {
            //pass
        }

        $tempPopup.fadeIn(200, function () {
            const $input = $tempPopup.find("input").visible();
            if ($input.length !== 0) {
                $input.first().focus();
            } else {
                $tempPopup.focus();
            }
            if (ServerChecker.isKogas) setShadowProfileImageLayer($tempPopup);
        });
    }

    function getCurrentPopup() {
        let $currentPopup  = $("#tempPopup");
        if(popupLayer.length > 0) {
            $currentPopup = popupLayer[popupLayer.length - 1];
            currentPassJson = $currentPopup.data('currentPassJson');
        }
        return $currentPopup;
    }

    function cancelPopup() {
        var $currentPopup = getCurrentPopup();
        if (currentPassJson && typeof currentPassJson.CANCEL_CALLBACK === "function") {
            currentPassJson.CANCEL_CALLBACK($currentPopup);
        } else {
            closePopup();
        }
    }

    function submitPopup(mode) {
        var $currentPopup = getCurrentPopup();

        if (currentPassJson && typeof currentPassJson.SUBMIT_CALLBACK === "function") {
            currentPassJson.SUBMIT_CALLBACK($currentPopup);
            if(mode !== "push-alarm") !currentPassJson.AWAIT && $currentPopup.remove();
        } else {
            closePopup($currentPopup);
        }
    }

    function secondarySubmitPopup() {
        var $currentPopup = getCurrentPopup();

        if (currentPassJson && typeof currentPassJson.SUBMIT_CALLBACK === "function") {
            currentPassJson.SECONDARY_SUBMIT_CALLBACK($currentPopup);
            !currentPassJson.AWAIT && closePopup();
        } else {
            closePopup();
        }
    }

    function closePopup($currentPopup) {
        let $tempPopup = $currentPopup || $("#tempPopup");

        if (popupLayer.length > 0) {
            $tempPopup = popupLayer.pop();
            currentPassJson = $tempPopup.data('currentPassJson');
        }

        if ($tempPopup.length === 0) return;

        const url = new URL(location.href);
        const gbValue = url.searchParams.get('GB');
        const isElectronProfilePopup = window.ElectronProcessApi && gbValue === 'PROFILE'

        if (!isElectronProfilePopup) {
            ($currentPopup || !$tempPopup.hasClass("serviceStop")) && $tempPopup.remove();
        }
        resetVideoConfPop();
        if (!$currentPopup && currentPassJson && typeof currentPassJson.CLOSE_CALLBACK === "function") {
            currentPassJson.CLOSE_CALLBACK();
        }
    }

    function doNotSeeAgainPopup($checkbox) {
        const isChecked = $checkbox.is(':checked')
        Often.setCookie(currentPassJson.SEEAGAIN + "_POPUP_YN", isChecked ? "Y" : "N", 30);
    }

    function bottomSubmitPopup() {
        var $currentPopup = getCurrentPopup();

        if (currentPassJson && typeof currentPassJson.BOTTOM_BUTTON_CALLBACK === "function") {
            currentPassJson.BOTTOM_BUTTON_CALLBACK();
            !currentPassJson.AWAIT && closePopup();
        } else {
            closePopup();
        }
    }

    function detailLinkConnect() {
        OpenUtil.openWindow(currentPassJson.LINK_URL, "_blank");
    }

    function getPopupHtml() {
        var ColorPopupHtml = $("#colorPopup").html();
        var ConfirmPopupHtml = $("#confirmPopup").html();
        var MiniAlertPopupHtml = $("#miniAlertPopup").html();
        var AlertPopupHtml = $("#alertPopup").html();
        var consultPopupHtml = $("#consultPopup").html();
        var LabelWrapHtml = $("#labelWrap").html();
        var PushAlarmWrapHtml = $("#pushWrap").html();
        var ProfileWrapHtml = $("#profileWrap").html();
        var PopupWrapHtml = $("#popupWrap").html();
        var BlockHtml = $("#blockPopup").html();
        var BigBlockHtml = $("#bigBlockPopup").html();
        var CheckPopupHtml = $("#checkBoxPopup").html();
        var EmailAuthHtml = $("#emailAuthLayer").html();
        var TemplateConfirmPopupHtml = $("#templateConfirmPopup").html();
        var ProjectTemplatePopupHtml = $("#projectTemplatePopup").html();
        var VideoConfHtml = $("#videoConfSelectPop").html();
        var PrevRefreshHtml = $("#prevRefreshPopup").html();
        var PrevFirstHtml = $("#prevFirstPopup").html();
        const multiselectTypeHtml = $("#multiselectTypeSelectPopup").html();
        const projectEdmsLinkHtml = Often.isFunc("OPEN_PROJECT_EDMS_LINK") ? $("#projectEdmsLinkPopup").html() : "";

        var PopupHtml = ListHelper.replaceJson(PopupWrapHtml, {
            contents: BigBlockHtml
                + BlockHtml
                + ColorPopupHtml
                + ConfirmPopupHtml
                + CheckPopupHtml
                + EmailAuthHtml
                + MiniAlertPopupHtml
                + consultPopupHtml
                + AlertPopupHtml
                + LabelWrapHtml
                + PushAlarmWrapHtml
                + ProfileWrapHtml
                + VideoConfHtml
                + TemplateConfirmPopupHtml
                + ProjectTemplatePopupHtml
                + PrevRefreshHtml
                + PrevFirstHtml
                + multiselectTypeHtml
                + projectEdmsLinkHtml
            ,
        })

        return $(PopupHtml).attr("id", "tempPopup").css("display", "none").addClass("temp-popup");
    }

    function setStyle() {
        if ($("#toastStyle").length > 0) return;
        $("head").append(`
        <style id="toastStyle">
        .alert-wrap {
          z-index: 11111; /*홈피,로그인쪽은 11x 계열이고, 메인은 1x 계열이라 차이가 생기는듯*/
          /*z-index: 13;*/
          top: 140px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          position: fixed;
        }
        .alert-wrap .text {
          word-break: keep-all;
        }

        .alert-type {
          border-radius: 50px;
          padding: 9px 17px;
          text-align: center;
          font-size: 13px;
          z-index: 13;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }

        body[data-enter-id="KSFC_1"] .alert-type.success,
        body[data-enter-id="KSFC_1"] .alert-type.info{
          background: #284E96;
          border: 1px solid #284E96;
          color: #fff;
        }
        
        .alert-type.success {
          background: #6449fc;
          border: 1px solid #6449fc;
          color: #fff;
        }

        .alert-type.info {
          background: #623ad6;
          border: 1px solid #623ad6;
          color: #fff;
        }

        .alert-type.error {
          background: #ff3434;
          border: 1px solid #ff3434;
          color: #fff;
        }
        
        .alert-type.terminate-project {
          background: #FF6B6B;
          border: 1px solid #FF6B6B;
          color: #fff;
        }

        .alert-wrap.mini .alert-type {
          font-size: 13px;
        }
        </style>`);
    }

    async function confirmCreateProjectChat(submitCallback, projectChatConfirmStrategy) {

        const returnData = await getProjectChatIntoPromise()
        const hasProjectChat = returnData.ROOM_SRNO;
        const isManager = _CURRENT_PROJECT_USER_INFO.isManager === "Y";
        const isCompanyProject = _CURRENT_PROJECT_INFO.isCompanyProject === "Y";

        if (hasProjectChat) return submitCallback()
        if (isCompanyProject && !isManager) return Often.toast("error", `${i18next.t(main.alert.createChatLimit)}`)

        const performPopupDrawConfirm = new ProjectChatConfirmContext(projectChatConfirmStrategy);
        performPopupDrawConfirm.performPopupDrawConfirm(submitCallback);
    }

    function getProjectChatIntoPromise() {
        return new Promise((resolve) => {
            let colaboSrno = !Often.isMessenger() ? Detail.getProjectSrno() : roomInfo.COLABO_SRNO
            resolve(Ajax.executeApi("ACT_PROJECT_CHAT_R001", {COLABO_SRNO: Often.null2Void(colaboSrno, "")}, (data) => {
                return data;
            }))
        })
    }

    function drawMultiselectTypeSelectPopup($eTarget){
        const $singleButton = $('.js-c-multiselect-type[data-type=single]')
        const $multiButton = $('.js-c-multiselect-type[data-type=multi]')
        const isSelectArea = $eTarget.closest('.js-c-multiselect-type').length > 0
        const isSingleClicked = Mutil.attr($eTarget.closest('li.js-c-multiselect-type'))["TYPE"] === 'single'
        const isMultiSelSubmit = Mutil.attr($eTarget.closest('.js-multiselect-confirm'))["TYPE"] === 'submit'

        if(isSelectArea){
            if(isSingleClicked){
                $singleButton.addClass('on')
                $multiButton.removeClass('on')
            } else {
                $singleButton.removeClass('on')
                $multiButton.addClass('on')
            }
        }

        if(isMultiSelSubmit){
            if ($('.js-c-multiselect-type.on').length === 0) {
                Often.toast('error', '타입을 선택해주세요!');
                return;
            }

            const $targetArea = $('.js-task-header-cell-layer').last()
            const isSingle = $('.js-c-multiselect-type.on[data-type=single]').length > 0
            $('#tempPopup').remove()
            return TaskCustomCol.header.drawHeaderInput({
                $area: $targetArea,
                $taskUl: $('#allTaskLayer'),
                COL_TYPE: 'multiSelect',
                SINGLE_YN: isSingle ? 'Y' : 'N'
            })
        }
    }

    function limitPopup() {
        const id = 'limitPopup';

        return {show: show}

        function hasPopup() {
            return Boolean(document.getElementById(id));
        }

        function show() {
            if (hasPopup()) {
                return;
            }

            const popup = render();
            addEvents(popup);
            popup.style.display = 'block';
        }

        function render() {
            const element = document.createElement(`div`);
            element.innerHTML = template();

            const body = document.querySelector('body');
            const popupElement = element.firstElementChild;
            body.append(popupElement);
            return popupElement;
        }

        function addEvents(popup) {
            const closeButton = popup.querySelector('.js-close-button');
            const submitButton = popup.querySelector('.js-submit-button');
            popup.addEventListener('click', dimmedClose);
            closeButton.addEventListener('click', close);
            submitButton.addEventListener('click', submitClick);
        }

        function dimmedClose(e) {
            const {target} = e;

            const allowClassName = 'js-limit-popup';
            if (target.closest(`.${allowClassName}`)) {
                return;
            }

            const disallowClassName = 'js-limit-dimmed';
            if (target.className?.includes(disallowClassName)) {
                close();
            }
        }

        function close() {
            Often.tryLogout();
            document.getElementById("tempPopup").style.zIndex = 12;
        }


        function submitClick() {
            if (_IsMini) {
                MiniProject.openProjectPage();
                return
            }
            Payment.openPaymentLayer();
            const paymentLayer = document.getElementById('paymentLayer');
            const paymentCloseButton = paymentLayer.querySelector("#closePayment");
            paymentCloseButton.addEventListener('click', (e) => {
                e.stopPropagation();
                limitPopup().show();
                paymentLayer.style.display = 'none';

                const mainView = document.getElementById('allMainContent');
                mainView.style.display = 'block';
            })
            document.getElementById(id)?.remove();
        }

        function template() {
            return `<section id="${id}" class="js-limit-dimmed dimmed free-trial" style="display: none; z-index: 12;">
                    <div class="js-limit-popup pop-type-basic">
                        <a href="#" class="js-close-button btn-close close"><i class="icons-close-1 js-close-tour"></i></a>
                        <img src="/flow-renewal/assets/images/icons/free-trial.svg" alt="free trial image">
                        <div class="cont">
                            <b>${i18next.t(main.freeTrialExpired.title)}</b>
                            <p>${i18next.t(main.freeTrialExpired.content)}</p>
                            <div class="btn-wrap">
                                <button class="js-submit-button type-bg">${i18next.t(common.subscribe)}</button>
                            </div>
                            <a href="https://hubspot.flow.team/contact_cx" target="_blank" class="js-inquiry-button">${i18next.t(main.stripe.contactUs)}</a>
                        </div>
                    </div>
                </section>`;
        }
    }

    function limitConsultPopup() {
        const id = 'limitInquiryPopup';

        return {show: show}

        function hasPopup() {
            return Boolean(document.getElementById(id));
        }

        function show() {
            if (hasPopup()) {
                return;
            }

            const popup = render();
            addEvents(popup);
            popup.style.display = 'block';
        }

        function render() {
            const element = document.createElement(`div`);
            element.innerHTML = template();

            const body = document.querySelector('body');
            const popupElement = element.firstElementChild;
            body.append(popupElement);
            return popupElement;
        }

        function addEvents(popup) {
            const closeButton = popup.querySelector('.js-close-button');
            const submitButton = popup.querySelector('.js-consult-submit');
            popup.addEventListener('click', dimmedClose);
            closeButton.addEventListener('click', close)
            submitButton.addEventListener('click', submit);
        }

        function dimmedClose(e) {
            const {target} = e;

            const allowClassName = 'js-consult-popup';
            if (target.closest(`.${allowClassName}`)) {
                return;
            }


            const disallowClassName = 'js-limit-dimmed';
            if (target.className?.includes(disallowClassName)) {
                close();
            }
        }

        function close() {
            document.getElementById(id)?.remove();
        }

        async function submit() {
            const inquiryPopup = document.getElementById(id);
            const textArea = inquiryPopup.querySelector("#inquiryPopupTextArea");

             await Ajax.executeApi("ACT_EXPERIENCE_INQUIRY", {
                USER_ID: _USER_ID,
                USE_INTT_ID: _USE_INTT_ID,
                USE_INTT_NM: _USE_INTT_NM ?? _BSNN_NM,
                USER_NM: _USER_NM,
                CLPH_NO: _CLPH_NO,
                INQUIRY: textArea.value
            }, (e) => {
                Often.toast('info', i18next.t(common.inquiryReceived));
                close();
            })
        }

        function template() {
            return `<section id="${id}" class="dimmed" style="display: none; z-index: 12;">
                <div class="consult-popup">
                    <div class="js-limit-dimmed flow-apps-portal popup banner-notice-wrap">
                        <div class="js-consult-popup portal-popup qna" style="width: 400px;min-width: unset;">
                            <div class="portal-popup-header">
                                <b class="popup-title">${i18next.t(main.stripe.contactUs)}</b>
                                <button type="button" class="js-close-button btn-close-x limit-close-button close-event">
                                    <span class="blind"></span>
                                </button>
                            </div>
                            <div class="popup-qna">
                                <p class="popup-cont">${i18next.t(main.inquiryFreeTrial.content)}</p>
                                <textarea id="inquiryPopupTextArea" name="popupTextArea" class="popup-input" placeholder="${i18next.t(common.leaveMessage)}"></textarea>
                                <div class="flow-pop-button-type-1">
                                    <button class="js-consult-submit agree-use bg-pp submit-event"
                                            style=" max-width: 100%;width: 100%;margin: auto;">${i18next.t(dictionary.confirm)}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>`;
        }
    }

    function setShadowProfileImageLayer($layer) {
        $layer.find('.profile-header-dimmed-layer').css('box-shadow', 'inset 0px 0px 20px 20px rgba(51, 51, 51,.25)');
    }

    function showCentrexCallPopup($emplItem, $centrexCallButton, $centrexCallPopup) {
        const userId = window._IsMini ? $emplItem.attr('user-id') : $emplItem.attr('data-id')
        const name = window._IsMini ? $emplItem.find('.mini-mode-area-list-type-1 > p:nth-child(1) strong').text() : $emplItem.find('#name').text()
        const companyPhoneNumber = Often.null2Void($emplItem.attr('cmpn-tlph-no'), '')
        const cellPhoneNumber = Often.null2Void($emplItem.attr('clph-no'), '')

        $centrexCallPopup.data('userId', userId)
        $centrexCallPopup.data('flnm', name)
        $centrexCallPopup.data('cmpnTlphNo', companyPhoneNumber !== '' ? companyPhoneNumber : null)
        $centrexCallPopup.data('clphNo', cellPhoneNumber !== '' ? cellPhoneNumber : null)

        $centrexCallPopup.find('.js-call-cell-phone').attr('disabled', cellPhoneNumber === '')
        $centrexCallPopup.find('.js-call-company-phone').attr('disabled', companyPhoneNumber === '')

        drawPopupAtCalculatedPosition($centrexCallPopup)

        function drawPopupAtCalculatedPosition($popup) {
            const { innerHeight} = window
            const popupHeight = $popup.height()
            let top = event.pageY

            const topOffset = 30
            if (top + popupHeight + topOffset > innerHeight) {
                top -= popupHeight
            }

            if (top < 0) {
                top = 0
            }

            $popup.css({
                top,
                right: '50px',
                position: 'fixed',
                display: 'block',
            })
        }
    }
})();

