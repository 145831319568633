var UrlCatcher = (function () {

    var PREFIX = "/l/";

    return {
        isMessengerPrivateUrl,
        isPostUrlRegex: isPostUrlRegex,
        isPostUrlAncAction: isPostUrlAncAction,
        isProjectInviteUrlAndAction: isProjectInviteUrlAndAction,
    }

    function isMessengerPrivateUrl(url) {
        return url.includes('/messenger.act?chat_token=')
    }

    //등록 - true : url 프리뷰 x
    //조회 - true : 클릭하면 포스트링크
    function isPostUrlRegex(url) {
        const isSameOrigin = url.includes(location.origin)
        if(ServerChecker.isEnter && isSameOrigin && url.indexOf(PREFIX) > -1) return true;

        const isPassServer = Often.isServerModeByHost("REAL") || Often.isServerModeByHost("ALL_TEST");
        const isTinyUrlIncluded = Often.isTinyUrlIncluded(url)

        return isPassServer && isTinyUrlIncluded && isSameOrigin
    }

    function isPostUrlAncAction(url) {
        if (!isPostUrlRegex(url)) return false;
        Ajax.executeApi(RenewalApi.GET.ACT_POST_BY_TINY_URL, {
            LINK_URL: url.substring(url.indexOf(PREFIX) + PREFIX.length)
        }, function (dat) {
            if (dat.RETURN_MSG !== '') return Often.toast("error", dat.RETURN_MSG);
            if (Often.isAct("messenger") || Often.isAct("subscreen") || Often.isAct("okr") || Often.isAct("letterDetail")) {
                if (Often.isFunc("ONLY_VIEW_BY_POST_LINK_ON_CHAT")) {
                    OpenUtil.openSubScreen($.extend({GB: "POST_LINK"}, dat));
                } else {
                    if(Often.isFunc("OPEN_WITH_BROWSER")) {
                        OpenUtil.openWindow(url, "_blank");
                    } else if (ElectronApi.Comm.isElectron()) {
                        ElectronApi.Project.open({
                            url: Often.isFunc("OPEN_URL_IN_CHROME") ? url : Often.getLocOrigin() + `${Often.isFunc('MAIN_PORTAL') ? "/portal.act" : "/main.act"}`,
                            projectSrno: dat.COLABO_SRNO,
                            postSrno: Often.null2Void(dat.COLABO_COMMT_SRNO, '-1'),
                            remarkSrno: "-1"
                        })
                    } else {
                        OpenUtil.openWindow(url, "_blank");
                    }
                }
            } else {
                PostPopup.removePopup();
                PostPopup.togglePostView(dat.COLABO_SRNO, dat.COLABO_COMMT_SRNO, '', '', true);
            }
        });
        return true;
    }

    function getServiceURL() {
        return Often.isGlobal() ? "morningmate.com" : "flow.team";
    }
    function isProjectInviteUrlAndAction(url) {
        var myOrigin = '(' + Often.getLocOrigin() + ')';
        var flowCloudServiceOrigin = '((https:\/\/)([a-zA-Z0-9_-].)*(' + getServiceURL() + '))';
        var isInvite = url.indexOf("Invite") > -1;
        var inviteRegexp = isInvite ? '(\/Invite\/([a-zA-Z0-9])+)' : '(\/Invitation\/([a-zA-Z0-9])+)';
        var flowProjectInviteUrlRegexp;
        var cloudServiceOriginRegexp = /((https:\/\/)([a-zA-Z0-9_-].)*(flow.team))/;
        if (cloudServiceOriginRegexp.test(Often.getLocOrigin())) {
            flowProjectInviteUrlRegexp = "((" + flowCloudServiceOrigin + "|" + myOrigin + ")" + inviteRegexp + ")";
        } else {
            flowProjectInviteUrlRegexp = "(" + myOrigin + inviteRegexp + ")";
        }
        var flowInviteUrlRegexp = new RegExp(flowProjectInviteUrlRegexp, 'gi');
        if (!flowInviteUrlRegexp.test(url)) return false;
        CONNECT_INVITE_KEY = (url.split("Invitation/")[1] || url.split("Invite/")[1]);
        InvitePopup.openInvitePopup();
        return true;
    }

})()
