var DocumentEvent = (function () {

    const NetworkChecker = requireModule('NetworkChecker', {on: ['main', 'messenger']});
    const ActionTracker = requireModule('ActionTracker', {on: ['main']});
    const CompanyTip = requireModule('CompanyTip', {on: ['main', 'messenger']});
    const FlowDrivePopup = requireModule('FlowDrivePopup', {on: ['main']});
    const DashboardPro = requireModule('DashboardPro', {on: ['main']});
    const AiFeature = requireModule('AiFeature', {on: ['main']})
    const Report = requireModule('Report', {on: ['main']});
    const EmojiPopup = requireModule('EmojiPopup', {on: ['main']});
    const MiniSelectPopup = requireModule('MiniSelectPopup', {on: ['main']});
    const PopupDimmed = requireModule('PopupDimmed', {on: ['main']});
    const CalendarPopover = requireModule("CalendarPopover", {on: ['main']})
    const MainPortal = requireModule('MainPortal', {on: ['main']});

    var isMousedownActive = false;
    var mousemoveX, mousemoveY;

    function setMousemoveXY(x, y) {
        mousemoveX = x;
        mousemoveY = y;
    }

    const getMousemoveX = () => mousemoveX;
    const getMousemoveY = () => mousemoveY;

    return {
        addEvent: addEvent,
        addTooltipEvent: addTooltipEvent,
        addEventOnMessenger: addEventOnMessenger,
        addEventOnMini: addEventOnMini,
        addEventOnLetter: addEventOnLetter,
        addInputDelEvent: addInputDelEvent,
        actionToolTip : actionToolTip,
        closeAllPopup: closeAllPopup,
        closeStepByStep: closeStepByStep,

        getMousemoveX: getMousemoveX,
        getMousemoveY: getMousemoveY,
        setMousemoveXY: setMousemoveXY,

        controlInputDelIcon: controlInputDelIcon,
        pasteDoc: pasteDoc,
        mouseDownDocumentEventOnMain: mouseDownDocumentEventOnMain,
        actionToolTip: actionToolTip,
    }

    function addEvent() {
        $(document).on({
            keydown: keydownDocumentEvent,
            keyup: keyupDocumentEvent,
            mousedown: mouseDownDocumentEventOnMain,
            mouseover: actionToolTip,
            mousemove: actionToolTip,
            mouseout: actionToolTip,
            mouseup: mouseupDocumentEvent,
            paste: pasteDoc,
            scroll: Participant.adjustPosition,
            dragenter: PasteFile.onUploadAreaDragEnterEvent,
            dragover: PasteFile.onDimdAreaDragOverEvent,
            drop: PasteFile.onDropEvent,
            copy: function(e) {
                const selection = document.getSelection()
                const copiedText = [...selection.toString()].join('')

                Mutil.mlog('Copied Text:', JSON.stringify(copiedText))
            }
        });
        $(window).on({
            resize: (e) => {
                TaskFilter.closeFilterAuto(e);
                CalendarPopover()?.closePopover();
            },
        })
    }

    function addTooltipEvent(){
        $(document).on({
            mouseover: actionToolTip,
            mousemove: actionToolTip,
            mouseout: actionToolTip,
        });
    }

    function pasteDoc(e) {
        PasteFile.onUploadAreaPasteEvent(e);
        PasteText.pasteTextEvent(e);
    }

    function addEventOnMini() {
        $(document).on({
            mousedown: mouseDownDocumentEventOnMiniMain,
            keydown: keydownDocumentEventOnMini,
        });

        $(document).on({
            mouseover: actionToolTip,
            mousemove: actionToolTip,
            mouseout: actionToolTip,
        });

        $(window).on('resize', resizeDocumentEventOnMiniOrgan);
    }

    function addEventOnMessenger() {
        $(document).on({
            keydown: keydownDocumentEventOnMessenger,
            mousedown: mouseDownDocumentEventOnMessenger,
        });

        $(document).on({
            mouseover: actionToolTip,
            mousemove: actionToolTip,
            mouseout: actionToolTip,
        });
    }

    function addEventOnLetter() {
        $(document).on({
            mouseover: actionToolTip,
            mousemove: actionToolTip,
            mouseout: actionToolTip,
        });
    }

    function addInputDelEvent(targetInput, callback) {
        if(!targetInput) return;
        const $icon = targetInput.parent().find(".js-input-del");
        $icon.off('click').on('click', (e) => {
            targetInput.val('');
            controlInputDelIcon(targetInput, '');
            (typeof callback === "function") && callback(e);
        })
    }

    function controlInputDelIcon(targetInput, value) {
        if(!targetInput) return;
        const $icon = targetInput.parent().find(".js-input-del");
        const val = value?? targetInput.val()
        $icon.css('display', Often.null2Void(val, "") === "" ? 'none' : 'block');
    }

    function actionToolTip(e) {

        if (_IsMini && !Often.isFunc(Func.CLOUD.MINI_TOOLTIP)) return;
        if (Often.isMessenger() && !Often.isFunc(Func.CLOUD.MESSENGER_TOOLTIP)) return;

        var $eTarget = $(e.target);
        if (e.type === "mouseover") Tooltip.isDrawToolTipAndAction($eTarget, e);
        if (e.type === "mouseout") Tooltip.isRemoveToolTipAndAction($eTarget, e);
        if (e.type === "mousemove") {
            setMousemoveXY(e.pageX, e.pageY);
            Tooltip.isMoveToolTipAndAction($eTarget, e);
            PasteFile.hideDimd($eTarget, e);
        }
    }

    function initMousedownActive() {
        isMousedownActive = false;
    }

    async function keydownDocumentEventOnMessenger(e) {
        if ((e.ctrlKey || e.metaKey) && KeyCheck.isKey(e, "F")) {
            e.preventDefault();
            MessengerSearch.openSearchLayer(true);
            return;
        }
        if (KeyCheck.isKey(e, "ESC")) {
            var $whiteBack = $(".flow-all-background-1");
            if ($whiteBack.is(":visible")) return $whiteBack.trigger('click');

            if (MessengerEvent.isPopupActiveState()) {
                return $(document).trigger('mousedown');
            }

            $(document).trigger('mousedown');
            if (MessengerEvent.closeNonActiveLayer()) return;

            if (Mutil.isFunc("SENDING_CHAT_CLOSED") && MessengerSend.getSendingStatus()?.isSending) {
                await Mutil.delay(300);
                await MessengerSend.logSendingChatClosed({
                    successChatSendApi: MessengerSend.getSendingStatus()?.callApi,
                    networkInfo: navigator.connection?.effectiveType ?? 'unknown',
                    socketConnected: SocketControl.getSocket().connected,
                });
                window.close();
                return;
            }

            const messengerInput = $("#messengerLayer").find(".js-messenger-text-input").text().trim();
            if (messengerInput.length > 0) {
                return closeMessengerPopup();
            }
            window.close();
            return;
        }
        if (KeyCheck.isKey(e, "ENTER")) {
            if ($("#messengerSearchLayer").is(":visible")) return MessengerSearch.searchMessage();
            var $fileSend = $(".js-file-send");
            if ($fileSend.is(":visible")) return $fileSend.find(".submit-event").trigger("click");
        }

        function closeMessengerPopup() {
            PopupDraw.drawConfirm({
                await: true,
                contents: {main: i18next.t(main.alert.leaveWriting)},
                callback: {
                    submit: () => {
                        window.close();
                    },
                    cancel: ($currentPopup) => {
                        $currentPopup.remove();
                    }
                }
            })
        }
    }

    function keydownDocumentEvent(e) {
        const TaskCustomCol = requireModule('TaskCustomCol', {on: ['main']});

        initMousedownActive();
        if (KeyCheck.isKey(e, "RELOAD_WINDOW") && ElectronApi.Comm.isElectron()) {
            location.reload();
            return;
        }

        if (!Often.isBrowser("ie")) {
            const selection = window.getSelection();
            const isBlockText = !selection.isCollapsed;
            const range = Caret.getSelectAllRange();
            if (isBlockText && range) {
                if (KeyCheck.isKey(e, "KOREAN_INPUTTING")
                    && (Caret.hasRangeSizzleElement(range, '.mention-span')
                        || Caret.hasRangeSizzleElement(range, '.hashtag-span'))) {
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("delete");
                    return;
                }
            }
        }

        if (Mutil.isFunc("ELASTICSEARCH")) {
            if (window?.TotalSearch?.isTopSearchCommand(e)) {
                window?.TotalSearch?.displayControlTopSearchPopup();
                return
            }
        }

        if (e.ctrlKey) {
            if (KeyCheck.isKey(e, "SPACE_BAR") && !Often.isBrowser("mac")) {
                e.preventDefault();
                return SearchEvent.clickTopSearchBar(e);
            }

            if (ElectronApi.Comm.isElectron()) {
                KeyCheck.isKey(e, "RELOAD_WINDOW") && !Often.isBrowser("mac") && window.location.reload(true);
                KeyCheck.isKey(e, "RELOAD_MAC") && KeyCheck.isKey(e, "SHIFT") && Often.isBrowser("mac") && window.location.reload(true);
                return;
            }
        }

        if (e.altKey) {
            var $searchPopupLayer = $("#searchPopupLayer");
            var upperEKey = e.key.toUpperCase();
            if ($searchPopupLayer.is(":visible")) {
                if (["P", "Π"].includes(upperEKey)) $searchPopupLayer.find(".js-search-section[data-code=project]").trigger('click');
                if (["W", "∑"].includes(upperEKey)) $searchPopupLayer.find(".js-search-section[data-code=post]").trigger('click');
                if (["F", "Ƒ"].includes(upperEKey)) $searchPopupLayer.find(".js-search-section[data-code=file]").trigger('click');
                if (["C"].includes(upperEKey)) $searchPopupLayer.find(".js-add-section i").trigger('click');
                e.preventDefault();
                return;
            }

            if (upperEKey === "N") {
                e.preventDefault();
                return $("#projectMakeButton").trigger("click");
            }

            if (ViewChanger.isPage("detail") && isPostPopupShortcut(e.code)) {
                if ($('#mainBodyWrap').find(".main-container").hasClass("terminate-project")) {
                    Often.toast('error', '종료된 프로젝트에서는 불가한 동작 입니다.');
                    return;
                }
                e.preventDefault();
                if (Authority.isEndAuthorityCheckAndAction(Often.isFunc(Func.CLOUD.END_PROJECT), e)) return;

                const templateType = getTemplateTypeByShortcutKeyCode(e.code)
                return PostPopup.openRegistrationViewOnAnywhere(templateType);
            }
        }

        if (KeyCheck.isKey(e, "ESC")) {
            const $confirm = $('#confirm');
            const $customColTempInputArea = $('.js-temporary-c-input');
            if ($customColTempInputArea.length > 0){
                $customColTempInputArea.closest('.js-task-header-cell-layer').removeClass('edit-mode')
                TaskCustomCol()?.initInputNPop();
            }
            if ($confirm.is(':visible')) {
                $confirm.find('.cancel').trigger('click');
                return;
            }

            if (closeStepByStep()) return;
            $(document).trigger('mousedown');
            if (isMousedownActive) return;

            if (ViewChanger.isPage("search")) {
                ViewChanger.loadPage("skip");
            }
            if (ElectronApi.Comm.isElectron() && Often.isFunc("ELECTRON_MAIN_ESC")) window.close();
            return;
        }

        if (KeyCheck.isKey(e, "ENTER")) {
            const $confirm = $('#confirm');
            if ($confirm.hasClass('project-move-confirm') && $confirm.is(':visible')) {
                $confirm.find('.submit').trigger('click');
                return;
            }
        }

        var $eTarget = $(e.target);
        var isAvailableWrite = $eTarget.is("input") || $eTarget.is("textarea") || $eTarget.is("[contenteditable=true]");
        if (KeyCheck.isKey(e, "BACK") && !isAvailableWrite) {
            history.back()
        }

        /**
         * @Note. 기획요청은 채팅목록에 arrow/enter키 추가.
         * 현재는 채팅목록에서만 작동하지만 추후 확장성 고려하여 listGb넣음.
         */
        if (KeyCheck.isArrowVertical(e) || KeyCheck.isKey(e, "ENTER")) {
            var $focusNode = $(window.getSelection().focusNode);
            var isFocusPopupBefore = $focusNode && $focusNode.findUp(".js-popup-before").length > 0;
            if (isFocusPopupBefore) return;

            var listGb;
            var $chattingUl = $('#chattingUl');
            if ($chattingUl.is(":visible")) listGb = "CHAT";
            if (!listGb) return;

            if (KeyCheck.isKey(e, "KOREAN_INPUTTING")) return;
            e.preventDefault();
            var $input = _IsMini ? $('#miniSearchInput') : $('#chattingSearchInput')
            if (KeyCheck.isKey(e, "DOWN")) ListEvent.arrowDownOnList("CHAT", $input, $chattingUl);
            if (KeyCheck.isKey(e, "UP")) ListEvent.arrowUpOnList("CHAT", $input, $chattingUl);
            if (KeyCheck.isKey(e, "ENTER")) ListEvent.enterOnList("CHAT", $input, $chattingUl);
        }

    }

    function keyupDocumentEvent(e) {
        if (!Often.isBrowser("ie")) {
            if (!window.getSelection().isCollapsed) {
                Caret.checkSelectAllRange();
            } else {
                Caret.initSelectAllRange();
            }
        }
    }

    function mouseDownDocumentEventOnMessenger(e) {

        var $eTarget = $(e.target);

        //1레벨
        var idButton = {}

        //2레벨
        var classButton = {
            "emoti-section": MessengerEmoti.closeEmotiListLayer,
            "meme-section": MessengerMyMeme.closeMemeListLayer,
            "message-more": MessengerMore.closeAllMorePop,
            "chat-files": MessengerEvent.closeFilePop,
            "secret-timer": MessengerSecret.closeSecretTimePop,
            "chat-mention": MessengerEvent.closeMentionPopup,
        }

        //3레벨 - 백그라운드 흰색으로 다 덮는거
        for (var id in idButton) {
            closeLayerById($eTarget, id, idButton[id]);
        }

        for (var className in classButton) {
            closeLayerByClassName($eTarget, className, classButton[className]);
        }
    }

    function mouseDownDocumentEventOnMiniMain(e) {
        var $eTarget = $(e.target);

        console.log(e)
        if ($eTarget.findUp(".js-centrex-call-button, #miniCentrexCallPopup, #miniCentrexCallContactPopup").length < 1) {
            $('#miniCentrexCallPopup').hide()
            $('#miniCentrexCallContactPopup').hide()
        }

        //1레벨
        var idButton = {
            "organization": Organization.closeLayer,
            "chatting": Chatting.closeLayer,
            "alarm": Alarm.closeLayer,
            "alarmSearchFilter": Alarm.closeAlarmSearchFilter,
            "portal": window.PortalAppMenu?.closePortal,
        }

        //2레벨
        var classButton = {
            "setting": Detail.closeSettingUl,
        }

        //3레벨 - 백그라운드 흰색으로 다 덮는거
        for (var id in idButton) {
            closeLayerById($eTarget, id, idButton[id]);
        }

        for (var className in classButton) {
            closeLayerByClassName($eTarget, className, classButton[className]);
        }
    }

    function keydownDocumentEventOnMini(e) {
        var listGb;
        var isLockMode = $('#miniLock').is(':visible');

        if (isLockMode) return keydownEventOnMiniLock(e);

        if (ElectronApi.Comm.isElectron() && Often.isFunc("ELECTRON_MINI_ESC") && KeyCheck.isKey(e, "ESC")) window.close();

        if (!KeyCheck.isArrowVertical(e) && !KeyCheck.isKey(e, "ENTER")) return;

        var $focusNode = $(window.getSelection().focusNode);
        var isFocusPopupBefore = $focusNode && $focusNode.findUp(".js-popup-before").length > 0;
        if (isFocusPopupBefore) return;

        if ($('#miniChatting').is(":visible")) listGb = "CHAT";
        if (!listGb) return;

        if (KeyCheck.isKey(e, "KOREAN_INPUTTING")) return;
        e.preventDefault();
        if (KeyCheck.isKey(e, "DOWN")) ListEvent.arrowDownOnList(listGb, $('#miniSearchInput'), $('#chattingUl'));
        if (KeyCheck.isKey(e, "UP")) ListEvent.arrowUpOnList(listGb, $('#miniSearchInput'), $('#chattingUl'));
        if (KeyCheck.isKey(e, "ENTER")) ListEvent.enterOnList(listGb, $('#miniSearchInput'), $('#chattingUl'));
    }

    function mouseupDocumentEvent(e) {
        const isIe = Often.isBrowser("ie");
        if (!isIe) {
            Caret.checkSelectAllRange();
        }
    }

    function keydownEventOnMiniLock(e) {
        if (e.ctrlKey) {
            if (KeyCheck.isKey(e, "RELOAD_WINDOW") && !Often.isBrowser("mac")
                || KeyCheck.isKey(e, "RELOAD_MAC") && Often.isBrowser("mac")) {
                e.preventDefault();
            }
        }
        if (KeyCheck.isKey(e, "RELOAD_WINDOW")) e.preventDefault();
    }

    function resizeDocumentEventOnMiniOrgan(e) {
        var afterHeight;
        afterHeight = $('#miniWrap').innerHeight() - $("#miniWrap .mini-mode-header-wrap").innerHeight() - $("#emplArea").innerHeight()
        if (Often.isFunc("ORG_TREE_USER_DEPTH")) $('#organizationChart').parent().css("height","");
        else $('#organizationChart').parent().css({height: afterHeight});
    }

    function mouseDownDocumentEventOnMain(e) {

        const TaskCustomCol = requireModule('TaskCustomCol', {on: ['main']});
        var $eTarget = $(e.target);

        var isMouseDownRightTopMenu = $eTarget.parents("#rightTopMenu").length > 0;
        var isCenterOnPostPopup = $("#postPopup").visible().hasClass("flow-all-background-1");
        if (!isMouseDownRightTopMenu && isCenterOnPostPopup) {
            closeLayerByClassName($eTarget, "emoji-select", ItemReaction.closeAllReactionPopup);
            return;
        }

        if (isCenterOnPostPopup) {
            if (!isMouseDownRightTopMenu) return;
            else if (!($.isTarget(e, ".js-set-calendar-list-layer"))) {
                if(Often.isFunc(Func.ENTER.EMPL_CALENDAR)){
                    EwsCalendarPopup.closeSchduleListWrap();
                } else if(Often.isFunc(Func.ENTER.CALENDAR_PLUS)) {
                    CalendarPlusPopup.closeSchduleListWrap();
                }
            }
        }
        if ($eTarget.findUp("#tempPopup").length > 0) return;

        if ($eTarget.findUp("#gant-wrap").length > 0) {
            // Gantt.mouseDownBackGround(e);
        }

        if ($eTarget.findUp(".js-centrex-call-button, #centrexCallPopup, #centrexCallContactPopup").length < 1) {
            $('#centrexCallPopup').hide()
            $('#centrexCallContactPopup').hide()
        }

        const enterIdButton = _ENTER_YN === 'N' ? {} : {
            "ewsSchdUpdateSelect": EwsCalendarPopup.closeChangeRepeatSchdule,
        }

        const calendarIdButton = _ENTER_YN === 'N' ? {} : {
            "ewsSchdUpdateSelect": CalendarPlusPopup.closeChangeRepeatSchdule,
        }

        const isExceptDom = (sizzle) => $eTarget.closest(sizzle).length === 0;
        if (isExceptDom("#addCustomColumnButton") &&
            isExceptDom("#customColumnAddPopupArea") &&
            isExceptDom(".js-task-header-cell-layer") &&
            isExceptDom(".js-custom-col-cell") &&
            isExceptDom(".js-custom-col-text") &&
            isExceptDom(".js-custom-col-date") &&
            isExceptDom(".js-custom-col-input") &&
            isExceptDom(".flatpickr-calendar") &&
            isExceptDom(".js-multiSelect-popup") &&
            isExceptDom("#customColumnSideOptionPopupLayer") &&
            isExceptDom(".js-worker-layer") &&
            isExceptDom('.js-c-multisel-sidepop-del-btn') &&
            isExceptDom('.js-single-multi-select-popup-layer') &&
            isExceptDom('#multiSelectPopup')
        ) {
            const $tempInputHeader = $('.js-temporary-c-input')
            const $tempInputCellRec = $('.js-temporary-c-input-cell')
            if($tempInputHeader.length > 0 && !Mutil.empty($tempInputHeader.val())){
                TaskCustomCol()?.header?.saveCustomColHeaderInstantly({$tempInputHeader})
                // reRender()
            } else if($tempInputCellRec.length > 0 && !Mutil.empty($tempInputCellRec.val())){
                TaskCustomCol()?.header?.saveCustomColCellInstantly({$tempInputCellRec})
            } else {
                TaskCustomCol()?.initInputNPop();
            }
        }

        const enterClassButton = _ENTER_YN === 'N' ? {} : {
            "ews-calendar-menu": EwsCalendarPopup.closeSettingSubCalendar,
            "ews-calendar-share": EwsCalendarPopup.closeSharePopup,
            "add-ews-group": EwsCalendarFilter.closeAddGroupPopup,
            "ews-sep-option": EwsCalendarPopup.closeSeperateOptionBox,
            "ews-attend-list": EwsCalendarPopup.closeAttendListPopup,
            "empl-calendar-search": EwsCalendarFilter.closeEmplList,
            "add-my-calendar": EwsCalendarFilter.closeAddMyCalendarWrap,
            "write-schedule": EwsCalendarFilter.closeWriteSchedule,
            // "itsm-issue": MraModule.ItsmRender.hideAllPopup,
            // "itsm-pop": MraModule.ItsmRender.closeWritePopupAction
        }
        const calendarClassButton = (_ENTER_YN === 'N' || Often.isFunc(Func.ENTER.EMPL_CALENDAR)) ? {} : {
            "ews-calendar-menu": CalendarPlusPopup.closeSettingSubCalendar,
            "ews-calendar-share": CalendarPlusPopup.closeSharePopup,
            "add-ews-group": CalendarPlusFilter.closeAddGroupPopup,
            "ews-sep-option": CalendarPlusPopup.closeSeperateOptionBox,
            "ews-attend-list": CalendarPlusPopup.closeAttendListPopup,
            "empl-calendar-search": CalendarPlusFilter.closeEmplList,
            "add-my-calendar": CalendarPlusFilter.closeAddMyCalendarWrap,
            "write-schedule": CalendarPlusFilter.closeWriteSchedule,
        }
        //1레벨
        var idButton = {
            "organization": Organization.closeLayer,
            "chatting": Chatting.closeLayer,
            "alarm": Alarm.closeLayer,
            "account": Top.closeAccountLayer,
            "majorService": MajorService.closeLayer,
            "helpCenter": Top.closeHelpCenterLayer,
            "detailSetting": DetailSetting.closeDetailSettingLayer,
            "alarmSearchFilter": Alarm.closeAlarmSearchFilter,
            "searchPopup": Search.closeTopSearchPopup,
            "allPostsDetailSearch": AllPosts.closeAllPostsDetailSearchLayer,
            "projectDetailSearch": ProjectSearch.closeProjectDetailSearchLayer,
            "joinProject": JoinProject.closeJoinProjectBar,
            "portal": ServerChecker.isEmro ? Top.closePortalLayer : PortalAppMenu.closePortal,
            "fileTypeFilter": FileFilter.closeFileTypeFilter,
            "sideTemporary": TemporarySidePopup.closeTemporarySideLayer,
            'topSearchPopup': () => {window?.TotalSearch?.hideSearchPopup(true)},
            "displayCompanyOpenProject": OpenProject.fadeOutCompanyOpenProjectFilterUl,
            "electronReload": ElectronEvent.closeReloadLayer,
            "ewsSchdUpdateSelect": EwsCalendarPopup.closeChangeRepeatSchdule,
            ...enterIdButton,
            ...calendarIdButton,
        }

        const Gantt = Mutil.requireIIFE('Gantt', false);
        const PostProductMention = requireModule("PostProductMention" , { on : ["main"]})();
        //2레벨
        var classButton = {
            "bottom-quick": QuickGuide.closeQuickGuide,
            "label-setting": AllLabel.clearLayer,
            "project-order": ProjectSetting.clearOrderPopup,
            "emoji-select": ItemReaction.closeAllReactionPopup,
            "task-bundle": AllTask.closeBundleLayer,
            "alltask-setting": AllTask.closeSettingLayer,
            "setting": Detail.closeSettingUl,
            "file-menu": FileMenu.removeFileMenu,
            "popup-menu": FileMenu.removeFileMenu,
            "feed-filter": Detail.closeFeedFilter,
            "project-more": Left.closeProjectMorePopup,
            "invite-employee": InviteEmployee.closeInviteEmployeeLayer,
            "all-posts-filter": AllPosts.closeAllPostsFilter,
            "worker": WorkerPopup.closeWorkerPopup,
            "mention": Mention.clearMention,
            "hashtag": HashTag.clearHashTag,
            "task-detail-search": TaskSearch.closeDetailSearchLayer,
            "file-detail-search": FileSearch.closeDetailSearchLayer,
            "status-setting": ItemSubtask.closeSubtaskPopup,
            "priority-setting": ItemSubtask.closeSubtaskPopup,
            "file-selectable": AllFileEvent.unselectFiles,
            "network-checker": NetworkChecker()?.closeCheckerLayer,
            "company-tip": CompanyTip()?.closeCompanyLayer,
            "tracking": ActionTracker()?.closeTrackingLayer,
            "skin-changer": SkinChanger.closeSkinColorLayer,
            "empl-calendar-search": EwsCalendarFilter.closeEmplList,
            "add-my-calendar": EwsCalendarFilter.closeAddMyCalendarWrap,
            "write-schedule": EwsCalendarFilter.closeWriteSchedule,
            "ews-calendar-menu": EwsCalendarPopup.closeSettingSubCalendar,
            "ews-calendar-share": EwsCalendarPopup.closeSharePopup,
            "add-ews-group": EwsCalendarFilter.closeAddGroupPopup,
            "ews-sep-option": EwsCalendarPopup.closeSeperateOptionBox,
            "ews-attend-list": EwsCalendarPopup.closeAttendListPopup,
            "grid-popup": TaskGridEvent.closeEditPopup,
            "sort": TaskSort.closeSortMiniPopup,
            "new-grid": TaskGridEvent.closeNewTaskLayer,
            "flow-drive": FlowDrivePopup()?.FlowDriveRender.closeFlowDrivePopup,
            "period": Gantt?.closePeriodLayer,
            "no-period": Gantt?.closeNoPeriodLayer,
            "range": Gantt?.closeRangeLayer,
            "widget-filter": DashboardPro()?.closeFilter || MainPortal()?.closeFilter,
            "widget-more": DashboardPro()?.closeWidgetMore || MainPortal()?.closeWidgetMore,
            "add-new-custom-column-popup": TaskGridEvent.closeEditPopup,
            "temp-task-header-cell": TaskGridEvent.closeEditPopup,
            "home-tab-menu" : window.ProjectHomeTabs?.removeHomeTabMenu,
            "add-option": AllTask.closeAddOption,
            "ai-quick-popup": AiFeature()?.QuickMenu.close,
            "report-option": () => Report()?.toggleOptionLayer(true),
            "emoji-picker": EmojiPopup()?.closeEmoji,
            "mini-select": MiniSelectPopup()?.closePopup,
            "calendar-popover": CalendarPopover()?.closePopover,
            ...enterClassButton,
            ...calendarClassButton,
            "search-select": window.TotalSearch?.removePopup, // elasticsearch 필터 팝업 삭제 이벤트 (작성자, 참여자, 담당자, 프로젝트 검색 팝업)
            "product-mention" : PostProductMention?.clearPopup(),
            "shortcut-menu": () => $('.js-shortcut-menu-layer').removeClass('on'),
            "shortcut-setting": () => $('.js-shortcut-setting-layer').remove(),
            "shortcut": () => {
                if($('.js-shortcut-setting-layer').length > 0 ) return;
                $('.js-shortcut-more-btn').removeClass('active')
            },
            "side-google": () => window.GoogleKeywordList?.removeSideList(),
        }

        //3레벨 - 백그라운드 흰색으로 다 덮는거
        for (var id in idButton) {
            if (id.indexOf('electron') > -1 && !ElectronApi.Comm.isElectron()) break;
            closeLayerById($eTarget, id, idButton[id]);
        }

        for (var className in classButton) {
            closeLayerByClassName($eTarget, className, classButton[className]);
        }

    }

    function closeLayerById($eTarget, code, callback) {
        var $upLayer = $eTarget.findUp("#" + code + "Layer");
        var $upTopButton = $eTarget.findUp("#" + code + "TopButton");
        if (!($upLayer.length > 0 || $upTopButton.length > 0) && $("#" + code + "Layer").is(":visible")) {
            callback($eTarget);
            isMousedownActive = true;
        } else {
            //pass
        }
    }

    function closeLayerByClassName($eTarget, code, callback) {
        var $upLayer = $eTarget.findUp(".js-" + code + "-layer");
        var $upTopButton = $eTarget.findUp(".js-" + code + "-button");
        if (!($upLayer.length > 0 || $upTopButton.length > 0) && $(".js-" + code + "-layer").is(":visible")) {
            callback($eTarget);
            isMousedownActive = true;
        } else {
            //pass
        }
    }

    function closeStepByStep() {
        const AiFeature = requireModule('AiFeature', {on: ['main']});
        const aiFeature = AiFeature();
        if (aiFeature && aiFeature.TaskSearchInput.isCreating) {
            aiFeature.TaskSearchInput.cancelSearchByCase();
            return;
        }

        const ItemReminder = Mutil.requireIIFE('ItemReminder', false);

        var $flatpickrCalendar = $(".flatpickr-calendar").not(".calendar-staff-flat,.inline");
        if ($flatpickrCalendar.is(":visible")) {
            $flatpickrCalendar.remove();
            return true;
        }

        const isCalendarPlusFeature = Often.isFunc(Func.ENTER.CALENDAR_PLUS);
        const Gantt = Mutil.requireIIFE('Gantt', false);

        var sizzleArray = {
            "#commtEditHistory" : PostEdit.clickClose,
            "#editHistoryListView" : PostEdit.clickClose,
            "#tempPopup.flow-all-background-1": PopupDraw.closePopup,
            "#readCheckPopup.flow-all-background-1": removeObj,
            "#reactionCheckPopup.flow-all-background-1": ReactionCheck.closePopup,
            "#requestJoinPopup.flow-all-background-1": RequestJoin.closeRequestJoinPopup,
            "#ewsAttendListPopup": isCalendarPlusFeature ? CalendarPlusPopup.closeAttendListPopup : EwsCalendarPopup.closeAttendListPopup,
            "#confirmationPopup.flow-all-background-1": window.ItemSchedule?.closeAttendancePopup,
            "#mySettingPopup.flow-all-background-1": window.MySettings?.closePopup,
            "#sortSettingPopup.flow-all-background-1": window.TaskSort?.closeSortPopup,
            "#teamInviteLayer.name-type-seach-popup-type-1": window.InviteProject?.backInviteMain, // Deprecated
            "#teamInviteLayer": window.InvitationPopup?.closePopupLayout,
            "#sendInviteEmlLayer.name-type-seach-popup-type-1": window.InviteProject?.backInviteMain,
            "#inviteLayer.flow-all-background-1": window.InviteProject?.closeInvite,
            "#mainInvitePopup.flow-all-background-1": window.InvitePopup?.clearInvitePopup,
            "#allSendiencePopup.flow-all-background-1": window.AllSendience?.closeAllSendiencePopup,
            "#groupSendiencePopup.flow-all-background-1": window.UserGroup?.closeAllSendiencePopup,
            ".js-vote-result.dimmed": () => $(".js-vote-result.dimmed").remove(),
            "#projectMakeLayer.flow-all-background-1": function () {
                window.ProjectMake?.checkWritingMakePopup(window.ProjectMake?.closePopup);
            },
            "#reminderPopup": ItemReminder?.closePopup,
            "#salesDatePopup": () => $('#salesDatePopup').remove(),
            "#postPopup": function () {
                if(PresentationMode.isPresentationMode()) return window.PostPopup.checkWritingAndShowPopup(PresentationMode.close());
                else if ($('#subTaskPopup').is(':visible')) return window.SubTaskPopupEvent?.leaveSubTaskPopup();
                else window.PostPopup.checkWritingAndShowPopup(window.PostPopup.removePopup)
            },
            ".js-new-grid-layer": window.TaskGridEvent?.cancelEditTask,
            ".js-no-period-layer": Gantt?.closeNoPeriodLayer,
            ".js-dimmed-popup.flow-all-background-1": PopupDimmed()?.closePopupDimmed,
            "#routinePopupLayer .js-routine-popup-layer": () => {
                $('#routinePopupLayer').html('')
            },
        }

        var isFirst = true;

        for (var sizzleId in sizzleArray) {
            var $sizzle = $(sizzleId).visible();
            if ($sizzle.length > 0) {
                if (isVisibleCalendarPlusAuthorizeSetting()) {
                    continue;
                }
                if (isFirst) {
                    sizzleArray[sizzleId]($sizzle);
                    isFirst = false;
                }
            }
        }

        return !isFirst

        function removeObj($obj) {
            $obj.remove();
        }

        //캘린더 플러스 위임설정 팝업이 켜져있을 경우에 세팅팝업창보다 위임설정 팝업창이 먼저 닫히게 하도록 추가
        function isVisibleCalendarPlusAuthorizeSetting() {
            return isCalendarPlusFeature && $("#teamInviteLayer").visible().length > 0 && sizzleId === "#mySettingPopup.flow-all-background-1"
        }
    }

    function closeAllPopup(isAlarm, isChangePostView) {
        var sizzleArray = {
            "#reminderPopup": window.ItemReminder?.closePopup,
            "#tempPopup.flow-all-background-1": PopupDraw.closePopup,
            "#readCheckPopup.flow-all-background-1": removeObj,
            "#reactionCheckPopup.flow-all-background-1": ReactionCheck.closePopup,
            "#postPopup.flow-all-background-1": function () {
                !isChangePostView && PostPopup.removePopup()
            },
            "#projectMakeLayer.flow-all-background-1": ProjectMake.closePopup,
            "#mySettingPopup.flow-all-background-1": MySettings.closePopup,
            "#allSendiencePopup.flow-all-background-1": AllSendience.closeAllSendiencePopup,
        }

        if (isAlarm) {
            sizzleArray["organizationLayer"] = Organization.closeLayer;
            sizzleArray["alarmLayer"] = Alarm.closeLayer;
            sizzleArray["chattingLayer"] = Chatting.closeLayer;
            sizzleArray["portalLayer"] = PortalAppMenu.closePortal;
            sizzleArray["sideTemporaryLayer"] = TemporarySidePopup.closeTemporarySideLayer;
        }

        for (var sizzleId in sizzleArray) {
            var $sizzle = $(sizzleId).visible();
            if ($sizzle.length > 0) {
                sizzleArray[sizzleId]($sizzle);
            }
        }

        function removeObj($obj) {
            $obj.remove();
        }
    }

    function isPostPopupShortcut(code) {
        const keyCodes = new Set([49, 50, 51, 52, 53]) // deprecated
        const codes = new Set(['DIGIT1', 'DIGIT2', 'DIGIT3', 'DIGIT4', 'DIGIT5']) // recommended

        return typeof code === 'string' ? codes.has(code.toUpperCase()) : keyCodes.has(code)
    }

    function getTemplateTypeByShortcutKeyCode(code) {
        // check code (event.code)
        if (typeof code !== 'string') {
            // default
            return DetailCode.WRITE
        }

        const sanitizedKeyCode = code.toUpperCase()
        const isEditor3 = Often.isFunc('EDITOR3')
        const KEYCODE_TO_TEMPLATE_TYPE = {
            'DIGIT1': isEditor3 ? DetailCode.EDITOR_WRITE : DetailCode.WRITE,
            'DIGIT2': isEditor3 ? DetailCode.EDITOR_TASK : DetailCode.TASK,
            'DIGIT3': isEditor3 ? DetailCode.EDITOR_SCHEDULE : DetailCode.SCHEDULE,
            'DIGIT4': DetailCode.TODO,
            'DIGIT5': DetailCode.VOTE,
        }

        return KEYCODE_TO_TEMPLATE_TYPE[sanitizedKeyCode]
    }
})();
